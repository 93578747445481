// import ERC721 from '../../src/Abi/erc721.json'
// import ERC1155 from '../../src/Abi/erc1155.json'
// import TRADE from '../../src/Abi/market.json'
var EnvName = 'production';
var key = {};
key.KEY = 'CardBo@rD1290%6Fine3'
key.ONEDAYINSECONDS = 0
key.BLOCKS_PER_YEAR = 0
key.RPAD_ADDRESS = ''
key.ROUTER = ''
key.EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
key.MOBILE = /^\d{10}$/
key.NumOnly = /^\d+$/
key.PASSWORD = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
key.OnlyAlbhabets = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/
key.notSpecil = /^[a-zA-Z0-9]+$/
key.OnlyAlphSpecial = /^[A-Za-z_@.#&+-]*$/
key.IPFS = 'https://goldenideanft.infura-ipfs.io/ipfs/'
key.limit = 50
key.NumDigitOnly = /[^0-9\.]/g
key.NumberOnly = /[^0-9]/g
if (EnvName === "demo") {
    key.FRONT_URL = 'http://nftdemo.bimaticz.com/goldennft'
    // key.BACK_URL        =   ' https://api.goldenideanft.com/v1/front'
    key.BACK_URL = 'http://192.53.121.26:7006/v1/front'
    key.ADMIN_URL = 'http://192.53.121.26:7006/v1/admin'
    key.IMG_URL = 'http://192.53.121.26:7006'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.TradeContract = '0xB0D19ac3476942E74D674618e8EcE786C394fd0E'.toLowerCase()
    // key.ERC721          =   '0x9A9EBc3A48D9ddc54A2D6A3599642aBA4363E7e1'.toLowerCase()
    key.ERC721 = '0xd610BF9AAEf957a8727a9000D8A97dd3e3ed12A4'.toLowerCase()
    key.ERC1155 = '0xE5690dfA1B5D41eEee5F90ca595FdDe74EAc257A'.toLowerCase()
    key.Bulkmint = '0xf1B805e31d151F4f3D3f177D8C2FDB3ff3AeE0Ed'.toLowerCase()
    key.erc20Address = '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    // key.RPC_URL = "https://api.avax-test.network/ext/bc/C/rpc"
    // key.CHAIN_ID = 97
    key.RPC_URL = "https://data-seed-prebsc-1-s2.binance.org:8545"
    key.CHAIN_ID = 97
    key.COIN_NAME = "ETH"
    // key.TradeContract = '0x3bE52bd3A97ac6Ba20F1482e58d9694B3E15Fb05'.toLowerCase()
    // key.ERC721 = '0x9A9EBc3A48D9ddc54A2D6A3599642aBA4363E7e1'.toLowerCase()
    // key.ERC1155 = '0x4958A36d8d71abd35D5434EF78023B3284D93e63'.toLowerCase()
    // key.erc20Address = '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    // key.RPC_URL = "https://api.avax-test.network/ext/bc/C/rpc"
    // key.CHAIN_ID = 43113
    // key.RPC_URL         =   "https://data-seed-prebsc-1-s2.binance.org:8545"
    // key.CHAIN_ID        =   97
    // key.RPC_URL         =   "https://data-seed-prebsc-1-s2.binance.org:8545"
    // key.CHAIN_ID        =   97
    // key.COIN_NAME = "ETH"
}
else if (EnvName === "stage") {

}
else if (EnvName === "production") {
    key.FRONT_URL = 'https://goldenideanft.com'
    key.BACK_URL = 'https://api.goldenideanft.com/v1/front'
    key.ADMIN_URL = 'https://api.goldenideanft.com/v1/admin'
    key.IMG_URL = 'https://api.goldenideanft.com'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.TradeContract = '0x9a0f37c162AEAa884141A472C208A813242174b4'.toLowerCase()
    key.ERC721 = '0xA6Af093FA40ca4CEF36c9F8e9cBF95eE8b37fDFF'.toLowerCase()
    key.ERC1155 = '0xE70B458e1b9a1A7887957A5Ae1A139DC98A31B0d'.toLowerCase()
    key.erc20Address = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'.toLowerCase()
    key.Bulkmint = '0xb3a539e1bF63217EAf5791398B4740cbC7e1f1c0'.toLowerCase()
    key.RPC_URL = "https://mainnet.infura.io/v3/8838475329744370b6f322e467348014"
    key.CHAIN_ID = 1
    key.COIN_NAME = "ETH"
}
else if (EnvName === "demossl") {
    key.FRONT_URL = 'https://nftdemo.maticz.com'
    key.BACK_URL = 'https://nftdemo.maticz.com/api/v1/front'
    key.ADMIN_URL = 'https://nftdemo.maticz.com/api/v1/admin'
    key.IMG_URL = 'https://nftdemo.maticz.com'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.TradeContract = '0x71CD3e9f29bDA1475138A2D3e1cCd6916Ab9f4Bf'.toLowerCase()
    key.ERC721 = '0x2616Cb548b40e076b72Ab24C21Bfc3356eE87383'.toLowerCase()
    key.ERC1155 = '0x484496568071F25678C07B372eBd6Ac54Bc18aB2'.toLowerCase()
    key.erc20Address = '0x7CAB80ce0E55F46378E493B584eE61aD68878f11'.toLowerCase()
    key.RPC_URL = "https://data-seed-prebsc-1-s2.binance.org:8545"
    key.CHAIN_ID = 97
    // key.RPC_URL         =   "https://data-seed-prebsc-1-s2.binance.org:8545"
    // key.CHAIN_ID        =   97
    key.COIN_NAME = "ETH"
}
else {
    key.FRONT_URL = 'http://localhost:3331/Ditmax'
    key.BACK_URL = 'http://localhost:3331/v1/front'
    key.ADMIN_URL = 'http://localhost:3331/v1/admin'
    key.IMG_URL = 'http://localhost:3331'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.TradeContract = '0xB0D19ac3476942E74D674618e8EcE786C394fd0E'.toLowerCase()
    // key.ERC721          =   '0x9A9EBc3A48D9ddc54A2D6A3599642aBA4363E7e1'.toLowerCase()
    key.ERC721 = '0xd610BF9AAEf957a8727a9000D8A97dd3e3ed12A4'.toLowerCase()
    key.ERC1155 = '0xE5690dfA1B5D41eEee5F90ca595FdDe74EAc257A'.toLowerCase()
    key.Bulkmint = '0xf1B805e31d151F4f3D3f177D8C2FDB3ff3AeE0Ed'.toLowerCase()
    key.erc20Address = '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    // key.RPC_URL = "https://api.avax-test.network/ext/bc/C/rpc"
    // key.CHAIN_ID = 97
    key.RPC_URL = "https://data-seed-prebsc-1-s2.binance.org:8545"
    key.CHAIN_ID = 97
    key.COIN_NAME = "ETH"
}
export default key;
