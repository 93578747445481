import React, { useEffect, useState } from "react";
import "../assets/js/common.js";
import { Link, NavLink, useNavigate } from "react-router-dom";

import $ from "jquery";
import Copy from "../assets/images/svg/copy.svg";
import Profile from "../assets/images/profile-gold.png";
import WalletIcon from "../assets/images/svg/wallet.svg";
import LogoutIcon from "../assets/images/svg/logout.svg";
import Accordion from "react-bootstrap/Accordion";
import { toast } from "react-toastify";
import { connectWallet, getServiceFees } from "../views/hooks/useWallet";
import { isEmpty, Name_showing } from "../actions/common";
import CopyToClipboard from "react-copy-to-clipboard";

import PNG from "../assets/images/author-detail-3.png";

import {
  userRegister,
  SearchAction,
  GetUserCookieToken,
} from "../actions/axioss/user.axios";
import { GetNftCookieToken } from "../actions/axioss/nft.axios";

import {
  Category,
  Currency,
  USDPRICE,
  TOKENPRICE,
} from "../actions/axioss/cms.axios";
import { useDispatch, useSelector } from "react-redux";
import Config from "../views/config/config";
import { address_showing, copydata } from "../actions/common";
import userimg from "../assets/images/menu-gold.png";
import Web3 from "web3";

export default function Header() {
  const [menu, setMenu] = React.useState(false);
  const [arrow, setArrow] = React.useState(false);
  const [categorys, setCategory] = React.useState([]);

  const [Searchdata, SetSearch] = React.useState(null);

  const { Categorys } = useSelector((state) => state.LoginReducer);
  const [val, Setval] = useState("");
  const push = useNavigate();

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if ($(this).scrollTop()) {
      $("header").addClass("fixedTop");
    } else {
      $("header").removeClass("fixedTop");
    }
    if (scroll >= 100) {
      $(".fixedTop").addClass("scroll");
      $("#scroll-top").addClass("show");
    } else {
      $(".fixedTop").removeClass("scroll");
      $("#scroll-top").removeClass("show");
    }
  });
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // client changed default theme dark
  if (localStorage.theme) {
    localStorage.setItem("theme", localStorage.theme);
    var themeChange = localStorage.getItem("theme");
    if (localStorage.theme == "is_dark") {
      document.body.classList.remove("is_darkis_light"); //is_light
    } else {
      document.body.classList.remove("is_light"); //is_light
    }
    document.body.classList.add(localStorage.theme);
  } else {
    localStorage.setItem("theme", "is_dark"); //is_light
    var themeChange = localStorage.getItem("theme");
    document.body.classList.remove("is_dark"); //is_dark
    document.body.classList.add("is_light");
  }
  // const switchTheme = () =>{
  //     if (localStorage.theme == "is_light") {
  //         localStorage.setItem("theme","is_dark");
  //         document.body.classList.remove("is_light");
  //         document.body.classList.add("is_dark");
  //       }
  //     else {
  //         localStorage.setItem("theme","is_light");
  //         document.body.classList.remove("is_dark");
  //         document.body.classList.add("is_light");
  //       }
  // }

  // const webSocketProvider = useWebSocketPublicClient({
  //     chainId: Config.CHAIN_ID,
  //   })
  //   const publicClient = usePublicClient({
  //     chainId: 1,
  //   })

  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.LoginReducer.AccountDetails);
  const { payload, token } = useSelector((state) => state.LoginReducer.User);
  // console.log("User details",payload,wallet)
  useEffect(() => {
    console.log(
      "eeeeeererererererererererererereererererer",
      wallet.accountAddress,
      "jk",
      localStorage.getItem("walletConnectType")
    );

    if (
      localStorage.getItem("walletConnectType") &&
      wallet.accountAddress == ""
    ) {
      console.log(
        "eeeeeererererererererererererereererererer",
        wallet.accountAddress,
        "jk",
        localStorage.getItem("walletConnectType")
      );
      initialConnectWallet(localStorage.getItem("walletConnectType"));
    }
    getInitialSeviceFee();
    Categorys1();
    CurrencyList();
  }, []);
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum?.on("accountsChanged", handleAccountChange);
      return () => {
        window.ethereum?.removeListener("accountsChanged", handleAccountChange);
      };
    }
  }, []);

  const Categorys1 = async () => {
    let Resp = await Category();

    if (Resp?.data) {
      var sendda = [];
      var data = (Resp.data || []).map((item) => {
        // console.log('mapppppp',item);
        if (item.hideShow !== "hidden") {
          sendda.push({
            label: item.name,
            value: item.name,
            description: item.description,
          });
        }
      });
      //   setCategory(sendda)
      dispatch({
        type: "Register_Section",
        Register_Section: {
          Categorys: sendda,
        },
      });
    }
  };
  const CurrencyList = async () => {
    let Resp = await Currency();
    console.log("Resp@123", Resp);

    if (Resp?.msg) {
      var sen = [];
      await Promise.all(
        Resp?.msg[0]?.CurrencyDetails?.map(async (data) => {
          console.log("kjdkgjkjsdfkg", data, !data.deleted);
          if (!data.deleted) {
            if (data.label == "ETH") var USD = await USDPRICE(data.label);
            else var USD = await TOKENPRICE(data.address);
            sen.push({
              value: data.value,
              label: data.label,
              address: data.address,
              usd: USD ? USD : 0,
              decimal: data.decimal,
            });
          }
        })
      );
      console.log("aaaaaaa", sen);
      dispatch({
        type: "Register_Section",
        Register_Section: {
          currency: sen,
        },
      });
    }
  };
  const handleAccountChange = (...args) => {
    console.log("dfdgdfg", localStorage.walletConnectType);
    initialConnectWallet(localStorage.walletConnectType);
  };
  const initialConnectWallet = async (type) => {
    console.log("accdountDetails", type);
    const id = toast.loading(type + "...", {
      closeButton: true,
      closeOnClick: true,
    });
    var accountDetails = await connectWallet(type);
    if (!isEmpty(accountDetails)) {
      var resp = await setWalletAddress(
        "InitialConnect",
        accountDetails.accountAddress,
        type
      );
      if (resp?.success == "success") {
        toast.update(id, {
          render: resp.msg,
          type: resp.success,
          autoClose: 1000,
          isLoading: false,
        });
        dispatch({
          type: "Account_Section",
          Account_Section: { AccountDetails: accountDetails },
        });
      } else {
        toast.update(id, {
          render: "Connect Wallet",
          type: "error",
          autoClose: 1000,
          isLoading: false,
        });
      }
    } else
      toast.update(id, {
        render: "Try Again",
        type: "error",
        autoClose: 1000,
        isLoading: false,
      });
  };
  const setWalletAddress = async (type, walletAddress, walletType) => {
    console.log("TYPWALADD,wa", type, walletAddress, walletType);
    if (walletAddress) {
      var NewMethod = {
        Type: type,
        WalletAddress: walletAddress,
        WalletType: walletType,
      };
      console.log("Resp.data1", NewMethod);
      let Resp = await userRegister(NewMethod);
      console.log("Resp.data23", Resp);
      if (Resp?.success == "success") {
        dispatch({
          type: "Register_Section",
          Register_Section: {
            User: {
              payload: Resp.data,
              token: Resp.token ? Resp.token : token,
            },
          },
        });
        document.cookie = "token" + "=" + Resp?.token + ";" + ";path=/";
        GetNftCookieToken();
        GetUserCookieToken();
        return Resp;
      } else return Resp;
    } else
      return {
        success: "error",
        msg: "No Address Detected.. Check Your Wallet",
      };
  };
  const walletDisconnect = async () => {
    // localStorage.removeItem("accountInfo")
    // localStorage.removeItem("walletConnectType")
    // if( localStorage.getItem("walletConnectType") == "CoinbaseMobile"){
    //    await disconnect()
    // }
    localStorage.clear();
    dispatch({
      type: "Disconnect",
      Disconnect: { User: { token: "", payload: null } },
    });
    dispatch({
      type: "Account_Section",
      Account_Section: {
        AccountDetails: {
          accountAddress: "",
          tokenBalance: 0,
          coinBalance: 0,
        },
      },
    });
    // push("/")
    // window.location.reload();
    document.cookie = "token" + "=" + "" + ";" + ";path=/";
    GetNftCookieToken();
    GetUserCookieToken();
  };
  const getInitialSeviceFee = async () => {
    // debugger
    var fees = await getServiceFees();
    console.log("getServiceFees", fees);
    if (fees) {
      dispatch({
        type: "ServiceFees",
        ServiceFees_Section: {
          ServiceFees: fees,
        },
      });
    }
  };
  const click = async (table) => {
    push("/explore/" + table);
  };

  const OnChange = async (value) => {
    // console.log("vallllllllllll",value);
    if (value) {
      Setval(value);
      var Resp = await SearchAction({
        keyword: value,
        limit: 3,
        page: 1,
        from: "search",
      });
      // console.log("response", Resp);
      if (Resp.success === "success") {
        SetSearch(Resp);
      } else {
        SetSearch(null);
      }
    } else {
      SetSearch(null);
      Setval("");
    }
  };

  const Search = () => {
    if (window.location.pathname.includes("search")) {
      SetSearch(null);
      Setval("");
    }
  };

  // const keyup=(e)=>{
  //     e.preventDefault();
  //     console.log(e.key,"KJH",e.target.value)
  //     if(!isEmpty(val)){
  //         if (e.key === 'Enter') {
  //             console.log("ENTER")
  //             push(`/search/${val}`)
  //           }

  //     }

  // }

  //     var input = document.getElementById("search");
  // // Execute a function when the user presses a key on the keyboard
  // input.addEventListener("keypress", function(event) {
  //   // If the user presses the "Enter" key on the keyboard
  //   if (event.key === "Enter") {
  //     // Cancel the default action, if needed
  //     event.preventDefault();
  //     // Trigger the button element with a click
  //     push(`/search/${val}`)
  //   }
  // });

  window.addEventListener("load", function () {
    // Add a click event listener to the body
    document.body.addEventListener("click", function () {
      // Code to execute when the body is clicked
      Setval("");
      SetSearch(null);
    });
  });

  return (
    <>
      <header className="header">
        {menu == true ? (
          <nav id="main-nav-mobi" className="main-nav d-block">
            <ul id="menu-primary-menu" className="menu">
              {/* <li className="menu-item">
                                            <NavLink to="/">Home</NavLink>
                                        </li> */}
              <li className="menu-item menu-item-has-children">
                <Accordion defaultActiveKey="0" className="menu-sub">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {" "}
                      <NavLink to="/explore">Explore</NavLink>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="sub-menu d-block">
                        <li
                          className={"menu-item"}
                          onClick={() => {
                            click("All");
                          }}
                        >
                          <Link to="#">All</Link>
                        </li>
                        {Categorys.map((item) => (
                          <li
                            className={"menu-item"}
                            onClick={() => {
                              click(item.value);
                            }}
                          >
                            <Link to="#">{item.label}</Link>
                          </li>
                        ))}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </li>
              <li className="menu-item">
                <NavLink to="/create">Create</NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/airdrop">Airdrop</NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/faq">FAQ</NavLink>
              </li>
              {wallet && wallet.accountAddress ? (
                <>
                  <li className="menu-item">
                    <NavLink to={`/profile/${payload?.CustomUrl}`}>
                      Profile
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="#"
                      onClick={() => {
                        walletDisconnect();
                      }}
                    >
                      Disconnect
                    </NavLink>
                  </li>
                </>
              ) : (
                <li className="menu-item">
                  <NavLink to="/connect">Connect Wallet..</NavLink>
                </li>
              )}
              <li className="menu-item search-item">
                <form>
                  <div className="search-form d-flex justify-content-between align-items-center">
                    <input
                      type="text"
                      placeholder="Search"
                      required=""
                      onChange={(e) => OnChange(e.target.value)}
                      id="search"
                      autoComplete="off"
                    />
                    {/* <Link className="btn-search"> */}
                    {!val && <i className="icon-fl-search-filled"></i>}
                    {val && (
                      <i
                        className="fa fa-times"
                        onClick={() => {
                          Setval("");
                          SetSearch(null);
                        }}
                      ></i>
                    )}
                    {/* <i className="fa fa-times"></i> */}
                    {/* </Link> */}
                    <div className="search-model">
                      {val && (
                        <div className="contentTop">
                          {Searchdata?.token?.data?.length > 0 ||
                          Searchdata?.user?.msg?.length > 0 ||
                          Searchdata?.collection?.msg?.length > 0 ? (
                            <>
                              {Searchdata?.collection?.data?.length > 0 && (
                                <div className="content">
                                  <h6>Collections</h6>
                                  {Searchdata?.collection?.data?.map(
                                    (value) => (
                                      <>
                                        <div
                                          className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                          onClick={() => {
                                            Setval("");
                                            push(
                                              `/collection/${value.CollectionSymbol}`
                                            );
                                          }}
                                        >
                                          {console.log(
                                            "value.CollectionProfileImage",
                                            value.CollectionProfileImage
                                          )}
                                          <div className="">
                                            <img
                                              src={
                                                value.CollectionProfileImage
                                                  ? `${Config.IMG_URL}/collection/${value.CollectionSymbol}/${value.CollectionProfileImage}`
                                                  : PNG
                                              }
                                              alt="Search"
                                              className="img-fluid"
                                            />
                                          </div>
                                          <div className="ml-3">
                                            <p>{value.CollectionName}</p>
                                            {/* <span>Crypto Hunt</span> */}
                                          </div>
                                        </div>
                                      </>
                                    )
                                  )}
                                </div>
                              )}
                              {Searchdata.user?.msg?.length > 0 && (
                                <div className="content">
                                  <h6>Users</h6>
                                  {Searchdata.user?.msg.map((value) => (
                                    <>
                                      <div
                                        className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                        onClick={() => {
                                          Setval("");
                                          push(`/profile/${value.CustomUrl}`);
                                        }}
                                      >
                                        <div className="">
                                          <img
                                            src={
                                              value.Profile
                                                ? `${Config.IMG_URL}/user/${value.WalletAddress}/profile/${value.Profile}`
                                                : PNG
                                            }
                                            className="img"
                                          />
                                        </div>
                                        <div className="ml-3">
                                          <p>{value.DisplayName}</p>
                                          <span></span>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </div>
                              )}

                              {Searchdata.token?.data?.length > 0 && (
                                <div className="content">
                                  <h6>Tokens</h6>
                                  {Searchdata.token?.data.map((value) => (
                                    <>
                                      <div
                                        className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                        onClick={() => {
                                          Setval("");
                                          SetSearch();
                                          push(
                                            `/info/${value.CollectionNetwork}/${value.ContractAddress}/${value.NFTOwner}/${value.NFTId}`
                                          );
                                        }}
                                      >
                                        <div className="">
                                          <img
                                            src={
                                              value.CompressedFile.includes(
                                                "webp"
                                              )
                                                ? `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT/${value.CompressedFile}`
                                                : `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT_THUMB/${value.CompressedThumbFile}`
                                            }
                                            alt="Search"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="ml-3">
                                          <p> {value.NFTName}</p>
                                          <span></span>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </div>
                              )}
                              <div className="search-button tf-button active btn btn-primary my-5 d-flex justify-content-center">
                                <Link to={`/search/${val}`}>Search</Link>
                              </div>
                            </>
                          ) : val === "" ? (
                            <></>
                          ) : (
                            <span className="error_msg">No data Found</span>
                          )}
                        </div>
                      )}
                      {/* <ul className="sub-menu d-block">
                                                <li className="menu-item "><NavLink to="/explore">3D MODEL</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">ANIME/MANGA</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">CYBER PUNK</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">PIXEL ART</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">MUSIC</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">2D ARTS</NavLink></li>
                                                <div class="btn-slider "><NavLink class="tf-button style-2" href="/">Search<i class="far fa-long-arrow-right"></i></Link></div>
                                            </ul> */}
                    </div>
                  </div>
                </form>
              </li>
            </ul>
          </nav>
        ) : (
          <></>
        )}
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <div id="site-header-inner">
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner">
                    <NavLink to="/" rel="home" className="main-logo">
                      <p id="logo_header"></p>
                    </NavLink>
                  </div>
                </div>
                <div className="header-center">
                  <nav id="main-nav" className="main-nav">
                    <ul id="menu-primary-menu" className="menu">
                      {/* <li className="menu-item">
                                            <NavLink to="/">Home</NavLink>
                                        </li> */}

                      <li className="menu-item menu-item-has-children ">
                        <NavLink to="/explore">Explore</NavLink>
                        <ul className="sub-menu">
                          <li
                            className={"menu-item"}
                            onClick={() => {
                              click("All");
                            }}
                          >
                            <Link to="#">All</Link>
                          </li>

                          {Categorys.map((item) => (
                            <li
                              className={"menu-item"}
                              onClick={() => {
                                click(item.value);
                              }}
                            >
                              <Link to="#">{item.label}</Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li className="menu-item">
                        <NavLink to="/create">Create</NavLink>
                      </li>
                      <li className="menu-item">
                        <NavLink to="/airdrop">Airdrop</NavLink>
                      </li>
                      <li className="menu-item">
                        <NavLink to="/faq">FAQ</NavLink>
                      </li>
                      {wallet && wallet.accountAddress && (
                        <li className="menu-item">
                          <NavLink to={`/profile/${payload?.CustomUrl}`}>
                            Profile
                          </NavLink>
                        </li>
                      )}
                      <li className="menu-item search-item">
                        <form
                          onSubmit={() => {
                            push(`/search/${val}`);
                          }}
                        >
                          <div className="search-form d-flex justify-content-between align-items-center">
                            <input
                              type="text"
                              value={val}
                              placeholder="Search"
                              required=""
                              autoComplete="off"
                              onChange={(e) => OnChange(e.target.value)}
                            />
                            {/* <Link className="btn-search"> */}
                            {!val && <i className="icon-fl-search-filled"></i>}
                            {val && (
                              <i
                                className="fa fa-times"
                                onClick={() => {
                                  Setval("");
                                  SetSearch(null);
                                }}
                              ></i>
                            )}
                            {/* </Link> */}
                            {/* <ul className="sub-menu d-block">
                                                        <li className="menu-item "><NavLink to="/explore">3D MODEL</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">ANIME/MANGA</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">CYBER PUNK</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">PIXEL ART</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">MUSIC</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">2D ARTS</NavLink></li>
                                                        <div class="btn-slider "><NavLink class="tf-button style-2" href="/">Search <i class="far fa-long-arrow-right"></i></Link></div>
                                                    </ul> */}
                          </div>

                          <div className="search-model">
                            {val && (
                              <div className="contentTop">
                                {Searchdata?.token?.data?.length > 0 ||
                                Searchdata?.user?.msg?.length > 0 ||
                                Searchdata?.collection?.msg?.length > 0 ? (
                                  <>
                                    {Searchdata?.collection?.data?.length >
                                      0 && (
                                      <div className="content">
                                        <h6>Collections</h6>
                                        {Searchdata?.collection?.data?.map(
                                          (value) => (
                                            <>
                                              <div
                                                className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                                onClick={() => {
                                                  Setval("");
                                                  push(
                                                    `/collection/${value.CollectionSymbol}`
                                                  );
                                                }}
                                              >
                                                <div className="">
                                                  <img
                                                    src={
                                                      value.CollectionProfileImage
                                                        ? `${Config.IMG_URL}/collection/${value.CollectionSymbol}/${value.CollectionProfileImage}`
                                                        : PNG
                                                    }
                                                    alt="Search"
                                                    className="img-fluid"
                                                  />
                                                </div>
                                                <div className="ml-3">
                                                  <p>{value.CollectionName}</p>
                                                  {/* <span>Crypto Hunt</span> */}
                                                </div>
                                              </div>
                                            </>
                                          )
                                        )}
                                      </div>
                                    )}

                                    {Searchdata.user?.msg?.length > 0 && (
                                      <div className="content">
                                        <h6>Users</h6>
                                        {Searchdata.user?.msg.map((value) => (
                                          <>
                                            <div
                                              className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                              onClick={() => {
                                                Setval("");
                                                push(
                                                  `/profile/${value.CustomUrl}`
                                                );
                                              }}
                                            >
                                              <div className="">
                                                <img
                                                  src={
                                                    value.Profile
                                                      ? `${Config.IMG_URL}/user/${value.WalletAddress}/profile/${value.Profile}`
                                                      : PNG
                                                  }
                                                  className="img"
                                                />
                                              </div>
                                              <div className="ml-3">
                                                <p>
                                                  {Name_showing(
                                                    value.DisplayName
                                                  )}
                                                </p>
                                                <span></span>
                                              </div>
                                            </div>
                                          </>
                                        ))}
                                      </div>
                                    )}
                                    {Searchdata.token?.data?.length > 0 && (
                                      <div className="content">
                                        <h6>Tokens</h6>
                                        {Searchdata.token?.data.map((value) => (
                                          <>
                                            <div
                                              className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                              onClick={() => {
                                                Setval("");
                                                SetSearch();
                                                push(
                                                  `/info/${value.CollectionNetwork}/${value.ContractAddress}/${value.NFTOwner}/${value.NFTId}`
                                                );
                                              }}
                                            >
                                              <div className="">
                                                <img
                                                  src={
                                                    value.CompressedFile.includes(
                                                      "webp"
                                                    )
                                                      ? `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT/${value.CompressedFile}`
                                                      : `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT_THUMB/${value.CompressedThumbFile}`
                                                  }
                                                  alt="Search"
                                                  className="img-fluid"
                                                />
                                              </div>
                                              <div className="ml-3">
                                                <p> {value.NFTName}</p>
                                                <span></span>
                                              </div>
                                            </div>
                                          </>
                                        ))}
                                      </div>
                                    )}
                                    <div className=" tf-button active btn btn-primary my-5 d-flex justify-content-center">
                                      <Link
                                        to={`/search/${val}`}
                                        onClick={Search}
                                      >
                                        Search
                                      </Link>
                                    </div>
                                  </>
                                ) : val === "" ? (
                                  <></>
                                ) : (
                                  <span className="error_msg">
                                    No data Found
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                        </form>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div className="header-right">
                  {wallet && wallet.accountAddress ? (
                    <NavLink
                      to="#"
                      className="tf-button tf-connect"
                      onClick={() => {
                        walletDisconnect();
                      }}
                    >
                      <span>Disconnect</span>
                    </NavLink>
                  ) : (
                    <NavLink to="/connect" className="tf-button tf-connect">
                      <span>Connect Wallet</span>
                    </NavLink>
                  )}
                  {/* <NavLink to="/connect" className="tf-button tf-connect"><span>Connect Wallet</span></NavLink> */}
                  {wallet && wallet.accountAddress && (
                    <div className="popup-user">
                      <div className="avatar">
                        <img
                          className="img-fluid"
                          width="20"
                          height="18"
                          src={userimg}
                          alt="avatar"
                        />
                      </div>
                      <div className="avatar_popup">
                        {payload && payload.msg && payload.msg.DisplayName && (
                          <h6>{payload.msg.DisplayName}</h6>
                        )}

                        <div className="price">
                          <p className="title">Balance</p>
                          {wallet && wallet.coinBalance ? (
                            <p className="style">
                              {wallet && wallet.coinBalance / 1e18}{" "}
                              {Config.COIN_NAME}
                            </p>
                          ) : (
                            <p className="style">0 {Config.COIN_NAME}</p>
                          )}
                        </div>
                        {wallet && wallet.accountAddress && (
                          <>
                            <p className="title">
                              <Link to="/connect">Wallet</Link>
                            </p>
                            <div className="code">
                              <p>{address_showing(wallet.accountAddress)}</p>
                              <CopyToClipboard
                                text={wallet?.accountAddress}
                                onCopy={() =>
                                  toast.success("Address Copied", {
                                    autoClose: 1000,
                                  })
                                }
                              >
                                <img src={Copy} alt="Images" />

                                {/* <i className="fas fa-sticky-note notes_fa cur_pointer"></i> */}
                              </CopyToClipboard>
                            </div>
                          </>
                        )}
                        <ul className="links">
                          <li>
                            <NavLink to={`/profile/${payload?.CustomUrl}`}>
                              <img src={Profile} alt="images" />
                              &emsp;<span>My Profile</span>
                            </NavLink>
                          </li>

                          {/* <li>
                                                    <NavLink to="/connect" className="mt-10">
                                                    <img src={WalletIcon} alt="images" />
                                                    &emsp;<span>Wallet</span>
                                                    </NavLink>
                                                </li> */}
                          {/* <li>
                                                    {wallet && wallet.accountAddress ?
                                                    <NavLink to="#" className="mt-10" id="logout" onClick={()=>{walletDisconnect()}}>
                                                    <img src={LogoutIcon} alt="images" />
                                                        &emsp;<span>Log out</span>
                                                    </NavLink>
                                                    :
                                                    <NavLink to="/connect" className="mt-10" id="logout">
                                                    <img src={LogoutIcon} alt="images" />
                                                        &emsp;<span>Log in</span>
                                                    </NavLink>
                                                    }
                                                </li> */}
                        </ul>
                      </div>
                    </div>
                  )}
                  {/* <Link to="#" className="mode-switch" onClick={switchTheme}>
                                       <p className="icon-change"></p>
                                    </Link> */}
                </div>
                <div
                  className={
                    menu == true ? "mobile-button active" : "mobile-button"
                  }
                  id="mobile-button"
                  onClick={() => setMenu(!menu)}
                >
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
