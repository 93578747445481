import React,{useState,useEffect} from "react";
import { Sociallinks , Newsletter} from "../actions/axioss/user.axios";
import config from "../views/config/config"
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { isEmpty } from "../actions/common";
import { toast } from "react-toastify";
import { getcms } from '../actions/axioss/user.axios';
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaReddit } from "react-icons/fa";

export default function Footer(props){
    var [email,setEmail] = useState('');
    var [Error,SetError] = useState({});
    const { web3p, web3, accountAddress } = useSelector(
        (state) => state.LoginReducer.AccountDetails
      );
    // const navigate  = useNavigate();


    // const [click, setClick] = useState(false);
    // const gotoLive = () =>    
    // {
    //     setClick(true);
    //     setTimeout(()  =>
    //     {
    //         navigate("/");
    //         if((!isEmpty(props)))
    //         {
    //             console.log(click,"click")
    //             props?.func("liveauction",true)
          
            
    //     },1000);
      
    // }
   
    
    // if((!isEmpty(props)))
    // {
    //     console.log(click,"click")
    //     props?.func("",false)
    // }
   
  
    // props? props?.func("#liveaution") : null;
    // console.log(props,"props");


    // const gotoHome = (val) =>
    // {
    //     alert(1)
        
    //     navigate("/");
       
    // }
    const scrollTo = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
      }
    var [link,setLink] = useState([]);
    var [Error,SetError] = useState({});
    const { payload } = useSelector(state => state.LoginReducer.User)
    const[rest,setrest]=useState([])

      const push = useNavigate()
    useEffect(()=>{
         Getlink();
        getcmslistdata() 

        // console.log("hello",a)
    },[])
    
    const Getlink=async()=>{
        
        let link_res= await Sociallinks();
        console.log("links",link_res?.msg);
        setLink(link_res?.msg??[])
    }
    const getcmslistdata = async () => {
        console.log("sadsada");
        let Resp = await getcms();
        console.log("woccccccrd",Resp);
        var resp=Resp.filter(item=> item.question=="Footer")
        // var resp=Resp.map((item)=>{
        //     return item.question=="Footer" && item
        // })  
        console.log("RESTTT",resp)
        setrest(resp)
    }
    const NewsLetter = async() =>{
        if(accountAddress){
            const id = toast.loading("Subscribing...");

        let err = {};
        if(email=='') err.email="Email Id Required";
        if(email && !(config.EMAIL).test(email)) err.email = 'Invalid Email ID Format';
        SetError(err);
        // console.log('fhbdfhbdf',email)
        if(isEmpty(err)){
            let resp = await Newsletter({email : email.toLowerCase()});
            if(resp.success === 'success'){
                toast.update(id ,{render:"Successfully Subscribed for NewsLetter", type: "success", isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
                setEmail('');
            }
            else{
                toast.update(id ,{ render : "Already User", type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
                SetError({email:resp.msg});
            }
        }
        else{
            toast.update(id ,{ render : err.email, type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
        }
    }
    else{
        toast.error("Please Connect your Wallet....")
    }
    }

    return(
        <>
          <footer className="footer">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-3 col-md-3">
                            <div className="widget widget-infor">
                                {console.log("RESTRRR",rest)}
                                <div className="logo">
                                    <NavLink to="/"><p id="logo_header"></p></NavLink>
                                </div>
                                {/* <p className="content"></p> */}
                                {rest.map((item, idx) => {
                                                                            //  {   console.log("fkjkg",item.answer.length)}

                                      return(

                                    <p className="content">
                                    <div
                                    dangerouslySetInnerHTML={{__html: item.answer?.length>127?item.answer.slice(0,127)+'...':item.answer}}
                                  />
                                    
                                  </p>); })}
                               
                               
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-6">
                            <div className="widget widget-menu">
                                <div className="menu menu-1">
                                    <h6 className="widget-title">Marketplace</h6>
                                    <ul >
                                        <li><Link to="/explore">Explore</Link></li>
                                        {/* <li><Link to="/collectionlist">Collection</Link></li> */}

                                        {/* <li><Link to="#" onClick={()=>{push ('/')
document.getElementById('liveauction').scrollTo({behaviour:"smooth"})
}}>Live Auction</Link></li> */}
                                {/* <li><Link to="/"
                                >Live Auction</Link></li> */}

                                    </ul>
                                </div>
                                {/* <div className="menu menu-2">
                                    <h6 className="widget-title">Stats</h6>
                                    <ul >
                                  
                                        <li><Link to={payload?.CustomUrl ? `/profile/${payload?.CustomUrl}`: "#"}>Activity</Link></li>
                                      
                                    </ul>
                            </div> */}
                                <div className="menu menu-3">
                                    <h6 className="widget-title">Resource</h6>
                                    <ul>
                                        <li><Link to="/privacypolicy">Privacy Policy</Link></li>
                                        <li><Link to="/faq">FAQ</Link></li>
                                        <li><Link to="/aboutus">About Us</Link></li>
                                    </ul>
                                </div>
                                {/* {payload?.CustomUrl &&
                                <div className="menu menu-4">
                                <h6 className="widget-title">My account</h6>
                                <ul >
                                    <li><Link to={payload?.CustomUrl ? `/profile/${payload?.CustomUrl}`: "#"}>Profile</Link></li>  
                                    <li><Link to={"/profile/"+localStorage.getItem("accountInfo")}>My Item</Link></li>
                                </ul>
                            </div>
                                } */}
                                <div className="menu menu-4">
                                    <h6 className="widget-title">My account</h6>
                                    <ul >
                                        <li><Link to={payload?.CustomUrl ? `/profile/${payload?.CustomUrl}`: "#"}>Profile</Link></li>  
                                        <li><Link to={payload?.CustomUrl ?"/profile/"+localStorage.getItem("accountInfo") : "#"}>My Item</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3">
                            <div className="widget widget-subcribe">
                                <h6 className="widget-title">Subscribe Us</h6>
                                <p className="content">Signup for our newsletter to get the latest news in your inbox.</p>
                                <form id="subscribe-form">
                                    <input type="email" placeholder="Info@yourgmail.com" value={''||email} onChange={(event)=>{setEmail(event.target.value)}} required="" id="subscribe-email"  autoComplete="off" />
                                    <button className="tf-button" type="button" id="subscribe-button" onClick={NewsLetter}><i className="icon-fl-send"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row icon_row">
                    <ul className="social-item">
                                {link.length > 0 && link?.map((mlink)=>(  
                                     
                                    <>

                                    {
                                    (mlink?.slug)?.toLowerCase()==="twitter" &&
                                    <a href={mlink.link} target="blank"><FaXTwitter className="xtwitter" /><span>{mlink.website}</span></a>}&nbsp;
                                   { (mlink?.slug)?.toLowerCase()==="facebook" &&
                                    <a href={mlink.link} target="blank"><i className="fab fa-facebook"></i><span>Facebook</span></a>}&nbsp;
                                    {(mlink?.slug)?.toLowerCase() ==="telegram" &&
                                    <a href={mlink.link}target="blank"><i className="fab fa-telegram-plane"></i><span>Telegram</span></a>}&nbsp;
                                    {(mlink?.slug)?.toLowerCase()==="youtube" &&
                                    <a href={mlink.link} target="blank"><i className="fab fa-youtube"></i><span>Youtube</span> </a>}&nbsp;
                                    {(mlink?.slug)?.toLowerCase()==="linkedin" && 
                                    <a href= {mlink.link} target="blank"><FaLinkedin className="xtwitter" /><span>{mlink.website}</span></a>}&nbsp;
                                    {(mlink?.slug)?.toLowerCase()==="reddit" && 
                                    <a href= {mlink.link} target="blank"><FaReddit className="xtwitter" /><span>{mlink.website}</span></a>}&nbsp;
                                    {(mlink?.slug)?.toLowerCase() === "discord" &&
                                    <a href={mlink.link} target="blank"> <i className="icon-fl-vt"></i><span>{mlink.website}</span></a>}&nbsp;
                                    {/* <a href={mlink.link} target="blank"> <i className="icon-fl-vt"></i></a> */}
                                    
                                    </>
                                    ))}
                                    
                                </ul>
                    </div>
                    <hr/>
                    <div className="footer_end text-center">
                    <p className="copy-right">
                    Copyright ©  2023 Golden Idea Productions Limited. All Rights Reserved</p>
                    </div>
                </div>
                <Link to="#" id="scroll-top" className="scrolltop_zindex" onClick={scrollTo}></Link>
           </footer>
        </>
    )
}