import React from "react";
import Card from "./Card";
import { NavLink ,Link} from "react-router-dom";

import Accordion from 'react-bootstrap/Accordion';
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import Button from "react-bootstrap/esm/Button.js";
import { useState,useEffect,useRef } from 'react';
import { useSelector } from "react-redux";
import { getFaqList,getFaqcontentList} from '../actions/axioss/user.axios';
import { Token_List_Func } from "../actions/axioss/nft.axios"
import NoData from "./seperatemodals/nodata";


export default function FAQ() {
    // console.log('faqqqqqq')
    const [active,setActive] = React.useState(false);
    const [faqList,setFaqList] = useState([]);
    const[Faqcontent,setFaqcontent]=useState([]);
    const[status,setStatus]=useState(true)

    
    const { Categorys } = useSelector((state) => state.LoginReducer);
    const { payload } = useSelector((state) => state.LoginReducer.User);
    var LikeForwardRef = useRef();
    const [LikedTokenList, setLikedTokenList] = useState([]);


    const [TabName, SetTabName] = useState("All");
    const [Tokens, SetTokens] = useState({
        All: { loader: true, page: 1, list: [] },
      });

    const Tabname = (newValue) => {
        SetTabName(newValue);
      };

useEffect(()=>{
    Explore();
    getFaqDetails();
 getFaqcontentDetails();
    
}, []);
useEffect(() => {
  if (typeof Tokens[TabName] == "undefined") {
    Tokens[TabName] = { page: 1, list: [], loader: false };
    SetTokens(Tokens);
    Explore(1, TabName);
  } 
}, [TabName]);

const Explore = async (data, tab) => {
  var page = data ? data : Tokens[TabName]?.page;
  var SendDATA = {
    TabName: tab ? tab : TabName,
    limit: 4,
    ProfileUrl: payload?.ProfileUrl ? payload.ProfileUrl : "",
    page: page ?? 1,
    from: "Explore",
    filter: "recentcreated"
  };
  let Resp = await Token_List_Func(SendDATA);
//   console.log("Check datad",Resp)
  if (Resp.data) {
    SetTokens({
      ...Tokens,
      ...{
        [TabName]: {
          list: [...Tokens[TabName].list, ...Resp.data],
          loader:
            Resp.Count <= Tokens[TabName].list.length + Resp.data.length ||
            Resp.data.length === 0
              ? false
              : true,
          page: Tokens[TabName].page,
        },
      },
    });
    setStatus(false)
  } else
    SetTokens({
      ...Tokens,
      ...{
        [TabName]: {
          list: Tokens[TabName].list,
          loader: false,
          page: Tokens[TabName].page,
        },
      },
    });
    setStatus(false)
};
const LoadMore = () => {
    Tokens[TabName].page = Tokens[TabName].page + 1;
    SetTokens(Tokens);
    Explore(Tokens[TabName].page);
  };

    // const faq =([
    //     {title:"What Is MiNFT ?",description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu sapien, lacus vitae sapien nulla amet risus nunc. Faucibus nunc, egestas quis semper porttitor. Fermentum magna commodo sodales curabitur. Pharetra, gravida nunc aliquam dolor id magna eu orci. Porttitor magna nulla est amet dolor ultricies egestas gravida. Nulla pellentesque convallis ligula placerat consectetur tortor, lobortis velit risus."},
    //     {title:"What Is MiNFT ?",description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu sapien, lacus vitae sapien nulla amet risus nunc. Faucibus nunc, egestas quis semper porttitor. Fermentum magna commodo sodales curabitur. Pharetra, gravida nunc aliquam dolor id magna eu orci. Porttitor magna nulla est amet dolor ultricies egestas gravida. Nulla pellentesque convallis ligula placerat consectetur tortor, lobortis velit risus."},
    //     {title:"What Is MiNFT ?",description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu sapien, lacus vitae sapien nulla amet risus nunc. Faucibus nunc, egestas quis semper porttitor. Fermentum magna commodo sodales curabitur. Pharetra, gravida nunc aliquam dolor id magna eu orci. Porttitor magna nulla est amet dolor ultricies egestas gravida. Nulla pellentesque convallis ligula placerat consectetur tortor, lobortis velit risus."},
    //     {title:"What Is MiNFT ?",description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu sapien, lacus vitae sapien nulla amet risus nunc. Faucibus nunc, egestas quis semper porttitor. Fermentum magna commodo sodales curabitur. Pharetra, gravida nunc aliquam dolor id magna eu orci. Porttitor magna nulla est amet dolor ultricies egestas gravida. Nulla pellentesque convallis ligula placerat consectetur tortor, lobortis velit risus."},
    //     {title:"What Is MiNFT ?",description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu sapien, lacus vitae sapien nulla amet risus nunc. Faucibus nunc, egestas quis semper porttitor. Fermentum magna commodo sodales curabitur. Pharetra, gravida nunc aliquam dolor id magna eu orci. Porttitor magna nulla est amet dolor ultricies egestas gravida. Nulla pellentesque convallis ligula placerat consectetur tortor, lobortis velit risus."}
    // ])



const getFaqDetails = async()=>{
    var resp = await getFaqList();
    // console.log("resp userlist",resp)
    if(resp?.status)
       setFaqList(resp.data);
}
const getFaqcontentDetails=async()=>{
    var resp = await getFaqcontentList();
    // console.log("resp userlist",resp)
    if(resp?.status)
    setFaqcontent(resp.data);
    


}
function LikeList(data){
    setLikedTokenList(data)
}
   
    // console.log("/////////////////",Faqcontent);

    return(
        <>
        <Header />
        <div className="faq">
            <section className="tf-page-title style-2" data-aos="fade-right">    
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">

                            <ul className="breadcrumbs">
                                <li><NavLink to="/">Home</NavLink></li>
                                <li>FAQ</li>
                            </ul>
                   
                        </div>
                    </div>
                </div>                    
            </section>
                
            <section className="tf-faq">
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            {Faqcontent.length>0 && <div className="tf-heading style-5">
                                <h4 className="heading" data-aos="fade-left">Frequently Questions</h4>
                                {/* <p className="sub-heading" data-aos="fade-right"> </p> */}
                                <div className="tf-accordion"> 
                                <div dangerouslySetInnerHTML={{__html:Faqcontent[0]?.faqcontent}}></div>  
                                </div>
                            </div>}
                            <div className="tf-accordion"> 
                            <Accordion defaultActiveKey="0">
                                {faqList.length>0 && faqList.map((data,key) => {
                                    return(
                            <Accordion.Item eventKey={JSON.stringify(key)} className="tf-toggle">
                                <Accordion.Header className="tf-toggle-title">{data.question}</Accordion.Header>
                                <Accordion.Body>
                                <div dangerouslySetInnerHTML={{__html:data.answer}}></div>  
                                </Accordion.Body>
                            </Accordion.Item>
                            )})}
                            </Accordion>                                                      
                            </div> 
                        </div>
                    </div>
                </div>
            </section >
                
            <section className='tf-explore-more faq'>
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                         {  Tokens[TabName]?.list.length>0 && <div className="tf-heading style-2">
                                <h4 className="heading">Explore More</h4>
                            </div>}
                        </div>
                        {/* <Card /> */}
                    </div>
                </div>
            </section>
        </div>
        <section className="tf-live-auction explore tf-filter" data-aos="fade-left">
                <div className="tf-container">
                    <div className="row ">
                        <div className="col-md-12">
                        <div className="top-menu wow fadeInUp"  data-aos="fade-up">
                                {/* <ul className="filter-menu">
                                    
                                { Categorys.map((item) => {
                                  return(
                                     <li className={TabName == item.label ? "active" : ""} onClick={() => Tabname(item.label)}><a>{item.label}</a></li>
                                  )
                                })}
                                </ul>
                                <div id="item_category2" className="dropdown">
                                    <Link to="#" className="btn-selector nolink ">Recently created</Link>
                                    <ul className="show">
                                        <li><span>Recently Listed</span></li>
                                        <li className="active"><span>Recently Created</span></li>
                                        <li><span>Recently Sold</span></li>
                                        <li><span>Recently Received</span></li>
                                        <li><span>Recently Soon</span></li>
                                        <li><span>Recently Low to Hight</span></li>
                                        <li><span>Recently Last Sale</span></li>
                                        <li><span>Oldest</span></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="tf-filter-container row"  data-aos="fade-up">
                        {/* {category == 1 ? */}
                        
                          {status == true ? <>
    <div className="text-centre">
        <h3 className="load_datas">Loading...</h3>
        </div>
    </> : 
                        
                        Tokens[TabName]?.list.length>0 ?
                        (
                            Tokens[TabName]?.list.map((item,index) => (

                        

                                <div
                       className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                     
                     >
                        <Card 
                            product={item} 
                            index={index}
                            LikeList={LikeList}
                            LikedTokenList={LikedTokenList}
                            setLikedTokenList={setLikedTokenList}
                            LikeForwardRef={LikeForwardRef}

                        />
                        </div>
                        )))
                       :
                       ""
                    //    <NoData/>
                        }
                        {/* {category == 2 ?
                       (
                       <Card />
                       ):(<></>)
                        }
                        {category == 3 ?
                       (
                       <Card />)
                       :(<></>)
                        }
                        {category == 4 ?
                       (
                       <Card />
                       )
                       :(<></>)
                        }
                        {category == 5 ?
                       (
                       <Card />)
                       :(<></>)
                        }
                        {category == 6 ?
                       (
                       <Card />
                       )
                       :(<></>)
                        }
                        {category == 7 ?
                       (
                       <Card />
                        )
                       :(<></>)
                        } */}
                    </div>
                    
                    {Tokens[TabName]?.list.length>0 && <div className="col-md-12">
                        <div className="btn-loadmore wow fadeInUp" data-aos="fade-up">
                            <NavLink to="/explore" className="tf-button style-8 loadmore">Explore More <i className="far fa-long-arrow-right"></i></NavLink>
                        </div>
                    </div>}
                </div>
            </section>
        <Footer />    
        </>
    )
}