import React, { useState, useEffect, useMemo } from "react";
import MetaMask from "../assets/images/svg/metamask.svg";
import Coinbase from "../assets/images/svg/coinbase.svg";
import Torus from "../assets/images/svg/torus.svg";
import Fortmatic from "../assets/images/svg/fortmatic.svg";
import Upload from "../assets/images/svg/drap-upload.svg";
import ETH from "../assets/images/ethe.svg";
import { NavLink, useLocation, useParams } from "react-router-dom";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import Countdown from "react-countdown";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useSelector } from "react-redux";
import useContractProviderHook from "../actions/contractProviderHook";
import config from "./config/config";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { isEmpty } from "../actions/common";
import { ImgValidation } from "../actions/validations";
import { CollectionByCreator, GetNFTOwner, nftCreate } from "../actions/axioss/nft.axios";
import {
  nftNameValidation,
  NFTImageUpload,
  CreateNFT,
} from "../actions/axioss/nft.axios";
import { useNavigate, Link } from "react-router-dom";
import CreateCollection from "./seperatemodals/CreateCollection";
import moment from "moment";
import Select from "react-select";
import CLockModal from "./seperatemodals/CLockModal";
export default function List() {

  const { sellerFees } = useSelector(
    (state) => state.LoginReducer.ServiceFees
  );
  const { web3p, web3, accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );

  const { payload}  = useSelector(
    (state) => state.LoginReducer.User
  );
  const { address , id} = useParams()
  const { state} = useLocation()

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [collection, setCollection] = React.useState(1);
  let renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Auction Completed!</span>;
    } else {
      return (
        <span>
          {" "}
          <span className="hourds">{formatTime(hours)} h</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(minutes)} m</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(seconds)} s</span>{" "}
        </span>
      );
    }
  };
  let formatTime = (time) => {
    return String(time).padStart(2, "0");
  };
  const push = useNavigate();

  const { currency, Categorys } = useSelector((state) => state.LoginReducer);
  // console.log('currrencccyy',currency)
  console.log('sttttaaattteeee',state)
  const initialTokenValue = {
    NFTName: state?.NFTName,
    NFTId: state?.NFTId,
    NFTQuantity: state?.amount,
    NFTOrginalImage: state?.Image,
    NFTOrginalImagePreview: "",
    NFTThumpImage: "",
    NFTThumpImagePreview: "",
    NFTOrginalImageIpfs: state?.ipfshash,
    NFTThumpImageIpfs: "",
    CompressedFile: "",
    CompressedThumbFile: "",
    NFTDescription: "",
    PutOnSaleType: "UnlimitedAuction",
    PutOnSale: false,
    NFTPrice: 0,
    NFTMinimumBid: 0,
    ClockTime: "",
    EndClockTime: "",
    NFTRoyalty: 0,
    NFTProperties: "",
    NFTCreator: state?.NFTCreator ? state?.NFTCreator : state?.NFTOwner,
    NFTOwner: state?.NFTOwner,
    HashValue: "",
    MetFile: state?.metadata,
    MetaData: state?.meta,
    ContractAddress: state?.ContractAddress,
    ContractType: state?.type,
    Category: state?.Category ? state?.Category : Categorys.length > 0 && Categorys[0].label,
    CoinName: "",
    UnlockContent: "",
    CollectionName: "DITMAX",
    CollectionNetwork: config.COIN_NAME,
    CollectionSymbol: state.CollectionSymbol ? state.CollectionSymbol : "",
  };

  const [NFTFormValue, setNFTFormValue] = useState(initialTokenValue);
  const [ValidateError, SetValidateError] = useState({});
  const [FormButton, SetFormButton] = useState("start");
  const [ApproveButton, SetApproveButton] = useState("start");
  const [UploadButton, SetUploadButton] = useState("stop");
  const [MintButton, setMintButton] = useState("stop");
  const [show8, setShow8] = useState(false);
  const [OpenPopup, SetOpenPopup] = useState("");
  const [CreateCollectionState, SetCreateCollectionState] = useState([]);

  const [start, setstart] = useState("");
  const [end, setend] = useState("");
  // console.log("Start Time", start);
  // console.log("End time", end);
  const ContractCall = useContractProviderHook();

  const startingdate = [
    { value: "List Immediately", label: "List Immediately" },
    { value: "Scheduled Listing", label: "Scheduled Listing" },
  ];
  const enddate = [
    { value: "1 day", label: "1 day" },
    { value: "2 days", label: "2 days" },
    { value: "Scheduled Listing", label: "Scheduled Listing" },
  ];
  const handleClose8 = () => setShow8(false);
  
 

  const DateSelection = (e, data) => {
    // console.log('datttteeeee',e.value,data,e.value == "Scheduled Listing")
    if (data == "start") {
      if (e.value == "List Immediately")
        setNFTFormValue({
          ...NFTFormValue,
          ...{
            ["ClockTime"]: moment(new Date()).format("YYYY-MM-DD h:mm:ss a"),
          },
        });
      else if (e.value == "Scheduled Listing") SetOpenPopup("ClockTime");
    } else {
      if (e.value == "1 day")
        setNFTFormValue({
          ...NFTFormValue,
          ...{
            ["EndClockTime"]: moment(
              new Date(new Date().setDate(new Date().getDate() + 1))
            ).format("YYYY-MM-DD h:mm:ss a"),
          },
        });
      else if (e.value == "2 days")
        setNFTFormValue({
          ...NFTFormValue,
          ...{
            ["EndClockTime"]: moment(
              new Date(new Date().setDate(new Date().getDate() + 2))
            ).format("YYYY-MM-DD h:mm:ss a"),
          },
        });
      else if (e.value == "Scheduled Listing") SetOpenPopup("EndClockTime");
    }
  };

  const YouWillGet = useMemo(() => {
    return ContractCall.price_calculation(NFTFormValue.NFTPrice);
  }, [NFTFormValue.NFTPrice]);
  const setClockValue = (data, date) => {
    setNFTFormValue({
      ...NFTFormValue,
      ...{ [data]: moment(date).format("YYYY-MM-DD h:mm:ss a") },
    });
  };

  const CollectionByCreate = async (data) => {
    let Resp = await CollectionByCreator(data);
    SetCreateCollectionState(Resp?.data);
  };

  // input field onchange function
  const onChange = (e, acceptedfile, type) => {
    SetFormButton("start");
    SetValidateError({})
    if (e && e.target) {
      const { files, value, id, name } = e.target;
      if(id=="NFTPrice"||id=="NFTMinimumBid" ){
        const checkprice = /^\d*\.?\d*$/;
        if(checkprice.test(value)) setNFTFormValue({ ...NFTFormValue, ...{ [id]: value } });
      }
      else{
        setNFTFormValue({ ...NFTFormValue, ...{ [id]: value } });
      }
      // console.log("form data", id, value);
    }
   
   
  };

  //NFT mint validation function
  const Validation = async (data) => {
    let ValidateError = {};
    const {
      NFTPrice,
      EndClockTime,
      ClockTime,
      Category,
      PutOnSaleType,
      PutOnSale,
      CoinName,
    } = data;
   
    if (
      (PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction") &&
      isEmpty(NFTPrice)
    )
      ValidateError.NFTPrice = "NFTPrice Required";
    if (
      (PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction") &&
      !CoinName && PutOnSale == true
    )
      ValidateError.CoinName = "CoinName Required";
    if (PutOnSaleType == "TimedAuction" && !ClockTime)
      ValidateError.ClockTime = "ClockTime Required";
    if (PutOnSaleType == "TimedAuction" && !EndClockTime)
      ValidateError.EndClockTime = "EndClockTime Required";

    if (!Category) ValidateError.Category = "Category Required";
    if (isNaN(NFTPrice) === true  && PutOnSale == true)
      ValidateError.NFTPrice = "NFT Price Should Be a Number";
    else if (Number(NFTPrice) <= 0 && PutOnSale == true)
      ValidateError.NFTPrice = "NFTPrice should be above Zero";
    return ValidateError;
  };

  // console.log("NFTFormValue",NFTFormValue)
  //NFT Form submit function
  const FormSubmit = async () => {
    SetValidateError({});
    const id = toast.loading("Validating Form");
     var Error = await Validation(NFTFormValue);
    console.log("Form errorrrrrr", NFTFormValue);
    if (isEmpty(Error)) {
      SetFormButton("process");
        let Respc = await ContractCall.Contract_Base_Validation();
        if (!Respc) {
          let Statu = await ContractCall.GetApproveStatus(
            NFTFormValue.ContractType?.includes('721')?"Single":"Multiple",
            NFTFormValue.ContractAddress
          );
        console.log('resppppp',Statu)
          if (Statu == true) {
            SetApproveButton("stop");
            SetUploadButton("start");
            toast.update(id, {
              render: "Start Listing",
              type: "success",
              isLoading: false,
              autoClose: 1000,closeButton:true,closeOnClick:true
            });
          } else {
            SetApproveButton("start");
            toast.update(id, {
              render: "Get Approve",
              type: "success",
              isLoading: false,
              autoClose: 1000,closeButton:true,closeOnClick:true
            });
          }
        } else {
          SetFormButton("error");
          SetValidateError(Respc);
        }
      
      setShow8(true);
    } else {
      SetFormButton("error");
      SetValidateError(Error);
      toast.update(id, {
        render: "Validation failed Check Fields and Submit again",
        type: "error",
        isLoading: false,
        autoClose: 500,closeButton:true,closeOnClick:true
      });
    }
  };

  //NFT Initial Approve
  const TokenApproveCall = async () => {
    SetApproveButton("process");
    const id = toast.loading("Approve in process");
    const cont = await ContractCall.SetApproveStatus(
      NFTFormValue.ContractType.includes('721')?"Single":"Multiple",
      NFTFormValue.ContractAddress
    );
    toast.update(id, {
      render: cont ? "Approved Successfully" : "Approved Failed",
      type: cont ? "success" : "error",
      isLoading: false,
      autoClose: 1000,closeButton:true,closeOnClick:true
    });
    if (cont.status) {
      SetApproveButton("done");
      SetUploadButton("start");
    } else SetApproveButton("try");
  };

  //Upload image in IPFS
  async function UploadIPFScall() {
    const {
      NFTCreator,
      NFTOrginalImage,
      MetFile,
      NFTName
    } = NFTFormValue;
    SetUploadButton("process");
    const id = toast.loading("Uploading  File");
    let Resp = await NFTImageUpload({
      NFTOrginalImage,
      NFTCreator,
      MetFile,
      NFTName,
      type:"list"
    });
    if (Resp.success == "success") {
      setNFTFormValue({ ...NFTFormValue, ...Resp.data });
      SetUploadButton("done");
      setMintButton("start");
    } else {
      SetUploadButton("try");
    }
    toast.update(id, {
      render: Resp.msg,
      type: Resp.success,
      isLoading: false,
      autoClose: 1000,closeButton:true,closeOnClick:true
    });
  }

  //NFT Mint Function
  async function MintCall() {
    const ids = toast.loading("Listing Processing");
    // console.log("Minting Process", web3);
    setMintButton("process");
    var _data = NFTFormValue;
    _data.CollectionSymbol = state?.CollectionSymbol ? state?.CollectionSymbol : "";
    _data.activity = "List";
    _data.PutOnSale = state?.CollectionSymbol ? true : false;
    _data.from = state.from === "othercollection" && "othercollection";
    if (NFTFormValue.PutOnSaleType == "FixedPrice") {
    const cont = await ContractCall.place_order_721_1155(
      id,
      web3p.utils.toWei(NFTFormValue.NFTPrice?.toString()),
      NFTFormValue.ContractAddress,
      Number(NFTFormValue.ContractType),
      "data"
    );
    if (cont) {
      _data.HashValue = cont.HashValue;
      _data.click = `${config.FRONT_URL}/info/${
        NFTFormValue.CollectionNetwork
      }/${
        NFTFormValue.ContractAddress
      }/${accountAddress}/${id?.toString()}`;

      BackCall(ids, _data);
    }

    else {
      toast.update(ids, {
        render: "Transaction Failed",
        type: "error",
        isLoading: false,
        autoClose: 1000,closeButton:true,closeOnClick:true
      });
      setMintButton("try");
    }
    } 
    else{
      _data.HashValue = '';
      _data.click = `${config.FRONT_URL}/info/${
        NFTFormValue.CollectionNetwork
      }/${
        NFTFormValue.ContractAddress
      }/${accountAddress}/${id?.toString()}`;
      BackCall(ids, _data);
  
    }
  }
  const BackCall = async(id,_data) => {
    let Resp = await CreateNFT(_data);
      toast.update(id, {
        render: Resp.msg,
        type: Resp.success,
        isLoading: false,
        autoClose: 1000,closeButton:true,closeOnClick:true
      });
      if (Resp.success == "success") {
        setMintButton("done");
        toast.update(id, {
          render: "Token Minted successfully",
          type: "success",
          isLoading: false,
          autoClose: 1000,closeButton:true,closeOnClick:true
        });
        push(`/my-item/${payload?.CustomUrl}`);
        // push("/user/" + payload.CustomUrl ? payload.CustomUrl  : payload.ProfileUrl  );
      } else {
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,closeButton:true,closeOnClick:true
        });
        setMintButton("try");
      }
  }
  useEffect(()=>{
    // console.log('coinnnn',currency,NFTFormValue.PutOnSaleType)
    CurrecyChange();
  },[currency,NFTFormValue.PutOnSaleType])//NFTFormValue.PutOnSaleType,

  const CurrecyChange =() =>{
    if(NFTFormValue.PutOnSaleType==="FixedPrice") 
      setNFTFormValue({...NFTFormValue,...{["CoinName"]:currency[0]?.label}})
    else if (NFTFormValue.PutOnSaleType==="TimedAuction") 
      setNFTFormValue({...NFTFormValue,...{["CoinName"]:currency[1]?.label}});
    else 
      setNFTFormValue({...NFTFormValue,...{["CoinName"]:""}});
  }

  
  const PutOnSale = ()=>{
    if(NFTFormValue.PutOnSale===false){
      setNFTFormValue({
        ...NFTFormValue,
        ...{
          ["PutOnSale"]:
            !NFTFormValue.PutOnSale,
          ["PutOnSaleType"]:"FixedPrice"
        },
      })
    }
    else{
      setNFTFormValue({
        ...NFTFormValue,
        ...{
          ["PutOnSale"]:
            !NFTFormValue.PutOnSale,
          ["PutOnSaleType"]:"UnlimitedAuction",
          ["NFTPrice"]: 0,
          ["NFTMinimumBid"]: 0,
          ["ClockTime"]: "",
          ["EndClockTime"]: "",
        },
      })
  }
  }
  const CloseModal = ()=>{
    SetOpenPopup("")
  }
  var validStart = function (current) {
    var yesterday=moment().subtract(1,'day')
    //return current.isAfter(new Date())&&current.isBefore(new Date(EndClocktime));
	return current.isAfter( yesterday );
  }
  var validEnd = function (current) {
    return current.isAfter(NFTFormValue.ClockTime?new Date(NFTFormValue.ClockTime):undefined);
  }

  useEffect(()=>{
    GetOwner()
  },[state])
  var GetOwner = async() =>{
    if(state.from === "othercollection"){
      var send = {Address: state.ContractAddress,NFTId:state.NFTId}
      var owner = await GetNFTOwner(send);
      console.log('ownnnerrrr',owner)
      var nftowner = [];
      var amountowned = [];
      owner.data.result.map((val)=>{
        nftowner.push(val.owner_of);
        amountowned.push(val.amount);
      })
      setNFTFormValue({...NFTFormValue,...{NFTOwner:nftowner,ownBalance:amountowned}})
      
    }
  }

  return (
    <>
      <Header />
      <div className="create">
        <section className="tf-page-title">
          <div className="tf-container">
            <div className="row">
              <div className="col-md-12">
                <ul className="breadcrumbs" data-aos="fade-right">
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>Create</li>
                </ul>
                <h4 className="page-title-heading" data-aos="fade-right">
                  List Your NFT Here
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section className="tf-add-nft">
          <div className="tf-container">
            <div className="row ">
            <div className="col-xl-9 col-lg-8 ">
              <div className="add-nft-inner">
          
                    <div className="tf-tab">
                     
                      <div className="content-tab">
                        <div className="content-inner active d-block">
                          <div className="tab-create-collection">
                            
                          
                            <div className="">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="title_list">
                                  <h6 className="title">List for sale Now</h6>
                                  <p className="sub mb22">
                                    {isEmpty(state?.CollectionSymbol)?
                                      "Your Item will be available to purchase immediately"  
                                      : "Your Item Will be In Unlimited Auction.Only the owner can accept the bid"
                                    }
                                    
                                  </p>
                                </div>
                                {isEmpty(state?.CollectionSymbol) &&
                                <div className="toogle">
                                  <div className="button-toggle">
                                    <input
                                      type="checkbox"
                                      id="switch4"
                                      checked={NFTFormValue.PutOnSale}
                                      onChange={()=>PutOnSale()}
                                      autoComplete="off" 
                                    />
                                    <label for="switch4"></label>
                                  </div>
                                </div>}
                              </div>
                              {NFTFormValue?.PutOnSale && (
                                <>
                                  <div className="list_for_sale_now d-block d-sm-flex justify-content-sm-between">
                                    <div
                                      className={
                                        NFTFormValue?.PutOnSaleType ==
                                        "FixedPrice"
                                          ? "list_border active fixed_price d-flex justify-content-start align-items-center"
                                          : "list_border fixed_price d-flex justify-content-start align-items-center"
                                      }
                                      onClick={() =>
                                        setNFTFormValue({
                                          ...NFTFormValue,
                                          ...{
                                            ["PutOnSaleType"]: "FixedPrice",
                                          },
                                        })
                                      }
                                    >
                                      <div className="icon">
                                        <i
                                          class="fa fa-tag"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                      <span>Fixed Price</span>
                                    </div>
                                    <div
                                      className={
                                        NFTFormValue?.PutOnSaleType ==
                                        "TimedAuction"
                                          ? "list_border active timed_auction d-flex justify-content-start align-items-center"
                                          : "list_border timed_auction d-flex justify-content-start align-items-center"
                                      }
                                      onClick={() =>
                                        setNFTFormValue({
                                          ...NFTFormValue,
                                          ...{
                                            ["PutOnSaleType"]: "TimedAuction",
                                          },
                                        })
                                      }
                                    >
                                      <div className="icon">
                                        <i
                                          class="fa fa-clock"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                      <span>Timed Auction</span>
                                    </div>
                                  </div>

                                  <div className="set-item1 my-5">
                                    <fieldset data-aos="zoom-up">
                                      <label className="mb8">
                                        {NFTFormValue.PutOnSaleType ==
                                        "FixedPrice"
                                          ? "Fixed Price"
                                          : "Minimum Bid"}
                                      </label>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <input
                                          type="text"
                                          className="set-items"
                                          id="NFTPrice"
                                          onChange={onChange}
                                          value={NFTFormValue.NFTPrice}
                                          placeholder="e.g.10"
                                          autoComplete="off" 
                                        />
                                        <Select
                                          onChange={(e) =>
                                            setNFTFormValue({
                                              ...NFTFormValue,
                                              ...{ ["CoinName"]: e.label },
                                            })
                                          }
                                          value={
                                            NFTFormValue.PutOnSaleType
                                              ? NFTFormValue.PutOnSaleType == "FixedPrice"
                                                ? {
                                                    label: NFTFormValue.CoinName,
                                                    value: NFTFormValue.CoinName,
                                                  }
                                                : currency.filter(
                                                    (item) => item.value !== "ETH"
                                                  )[0]
                                              : currency[0]
                                          }
                                          defaultValue={
                                            NFTFormValue.PutOnSaleType
                                              ? NFTFormValue.PutOnSaleType == "FixedPrice"
                                                ? {
                                                    label: NFTFormValue.CoinName,
                                                    value: NFTFormValue.CoinName,
                                                  }
                                                : currency.filter(
                                                    (item) => item.value !== "ETH"
                                                  )[0]
                                              : currency[0]
                                          }
                                          options={
                                            NFTFormValue.PutOnSaleType ==
                                            "FixedPrice"
                                              ? currency
                                              : currency?.filter(
                                                  (item) => item.label != "ETH"
                                                )
                                          }
                                          classNamePrefix="react-select"
                                          isSearchable={false}
                                        />
                                      </div>
                                      {ValidateError.NFTPrice && (
                                        <span className="text-danger img-file">
                                          {ValidateError.NFTPrice}
                                        </span>
                                      )}
                                      {ValidateError.CoinName && (
                                        <span className="text-danger img-file">
                                          {ValidateError.CoinName}
                                        </span>
                                      )}
                                    </fieldset>
                                    {NFTFormValue?.PutOnSaleType ==
                                    "FixedPrice" ? (
                                      <fieldset data-aos="zoom-up">
                                        <p className="mt-1">
                                          Service Fees{" "}
                                          {web3?.utils?.fromWei(sellerFees)}%
                                        </p>
                                        {/* {console.log('dgfvdsgv',sellerFees)} */}
                                        <p className="mt-1">
                                          You will receive {YouWillGet}{" "}
                                          {NFTFormValue.CoinName}&nbsp;
                                          <i
                                            class="fa fa-usd"
                                            aria-hidden="true"
                                          ></i>
                                        </p>
                                      </fieldset>
                                    ) : (
                                      <fieldset data-aos="zoom-up">
                                        <div className="my-3 d-block d-sm-flex align-items-center justify-content-sm-between">
                                          <div className="starting_date">
                                            <Select
                                              value={{
                                                value: NFTFormValue.ClockTime,
                                                label: NFTFormValue.ClockTime,
                                              }}
                                              onChange={(e) =>
                                                DateSelection(e, "start")
                                              }
                                              options={startingdate}
                                              classNamePrefix="react-select"
                                              isSearchable={false}
                                            />
                                             {ValidateError.ClockTime && (
                                        <span className="text-danger img-file">
                                          {ValidateError.ClockTime}
                                        </span>
                                      )}
                                          </div>
                                          <div className="ending_date">
                                            <Select
                                              value={{
                                                value:
                                                  NFTFormValue.EndClockTime,
                                                label:
                                                  NFTFormValue.EndClockTime,
                                              }}
                                              onChange={(e) =>
                                                DateSelection(e, "end")
                                              }
                                              options={enddate}
                                              classNamePrefix="react-select"
                                              isSearchable={false}
                                            />
                                    {ValidateError.EndClockTime && (
                                        <span className="text-danger img-file">
                                          {ValidateError.EndClockTime}
                                        </span>
                                      )}
                                          </div>
                                        </div>
                                      </fieldset>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                           
                              <fieldset>
                                <label>Name your item</label>
                                <input
                                  type="text"
                                  id="NFTName"
                                  disabled={true}
                                  placeholder="Name your item"
                                  value={NFTFormValue.NFTName}
                                  autoComplete="off" 
                                />
                                {ValidateError.NFTName && (
                                  <span className="text-danger img-file">
                                    {ValidateError.NFTName}
                                  </span>
                                )}
                              </fieldset>
                           
                        

                            <fieldset>
                              <label>Enter short description</label>
                              <input
                                type="text"
                                onChange={onChange}
                                id="NFTDescription"
                                value={NFTFormValue.NFTDescription}
                                placeholder="Enter short description"
                                autoComplete="off" 
                              />
                            </fieldset>

                           

                            <div className="set-item">
                              <fieldset>
                                <label className="mb8">Set Category</label>
                                <Select
                                  isDisabled={
                                    NFTFormValue.CollectionSymbol ? true : false
                                  }
                                  value={{
                                    label: NFTFormValue?.Category,
                                    value: NFTFormValue?.Category,
                                  }}
                                  // defaultValue={NFTFormValue.PutOnSaleType == "FixedPrice" ?  currency[0] : currency[1]}
                                  onChange={(e) =>
                                    {
                                      SetFormButton("start");
                                      SetValidateError({});
                                      setNFTFormValue({
                                      ...NFTFormValue,
                                      ...{ ["Category"]: e.label },
                                    })}
                                  }
                                  options={Categorys}
                                  classNamePrefix="react-select"
                                  isSearchable={false}
                                />
                                {ValidateError.Category && (
                                  <span className="text-danger img-file">
                                    {ValidateError.Category}
                                  </span>
                                )}
                              </fieldset>
                              <fieldset>
                                  <label className="mb8">No of Copy</label>
                                  <input
                                    type="text"
                                    value={NFTFormValue.NFTQuantity}
                                    id="NFTQuantity"
                                    disabled={true}
                                    placeholder="No of Copy"
                                    autoComplete="off" 
                                  />
                               
                                </fieldset>
                            </div>

                            {isEmpty(state?.CollectionSymbol) && <>
                              <h6 className="title ">Choose Collection</h6>
                            <p className="sub">
                              They All Serve The Same Purpose.
                            </p>

                            <ul className="create-collection">
                              <li
                                className=""
                                onClick={() => SetOpenPopup("collection")}
                              >
                                <div className="create-item">
                                  <div className="img">
                                    <i className="fal fa-plus"></i>
                                  </div>
                                  <div className="content">
                                    <h6>Create new collection</h6>
                                    <p>Tap to create</p>
                                  </div>
                                </div>
                              </li>
                              {CreateCollectionState?.map((item, index) => {
                                return (
                                  <li
                                    onClick={() =>
                                      setNFTFormValue({
                                        ...NFTFormValue,
                                        ...{
                                          ["CollectionSymbol"]:
                                            item.CollectionSymbol,
                                          ["Category"]: item.Category,
                                        },
                                      })
                                    }
                                    key={Math.random()}
                                  >
                                    <div className="create-item">
                                      <div className="img">
                                        <img
                                          src={
                                            item.CollectionProfileImage
                                              ? `${config.IMG_URL}/collection/${item.CollectionSymbol}/${item.CollectionProfileImage}`
                                              : require("../assets/images/add-collection.jpg")
                                          }
                                          alt="Image"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>{item.CollectionName}</h6>
                                        <p>{item.CollectionCount} items</p>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                            </>}

                           

                            

                            <div className="bottom-button">
                              <Button
                                className="tf-button active"
                                disabled={
                                  FormButton == "error" ||
                                  FormButton == "done" ||
                                  FormButton == "process"
                                    ? true
                                    : false
                                }
                                onClick={
                                  FormButton == "start" ? FormSubmit : null
                                }
                              >
                                {FormButton == "start" && "List"}
                                {FormButton == "error" && "Error"}
                                {FormButton == "process" && "processing"}
                                {FormButton == "done" && "Done"}
                              </Button>
                              
                              {/* <Link to="#" className="tf-button active">Publish</Link>
                                                        <Link to="#" className="tf-button">Discard all</Link> */}
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            collection == 3
                              ? "content-inner active d-block"
                              : "content-inner d-none"
                          }
                        >
                          <div className="tab-create-item">
                            <h6 className="title">Upload An Item</h6>
                            <p className="sub">
                              But Each One Takes A Different Approach And Makes
                              Different Tradeoffs.
                            </p>
                            <div className="drag-upload">
                              <input type="file" />
                              <img src={Upload} alt="Image" />
                              <h6 className="title">
                                Drag your item to upload
                              </h6>
                              <p className="sub-title">
                                PNG, GIF, WebP, MP4 Or MP3. Maximum File Size
                                100 Mb.
                              </p>
                            </div>

                            <h6 className="title">Upload An Item</h6>
                            <p className="sub mb22">
                              But Each One Takes A Different Approach And Makes
                              Different Tradeoffs.
                            </p>

                            <fieldset>
                              <label>Name your item</label>
                              <input type="text" placeholder="Name your item" value={NFTFormValue.NFTName}  autoComplete="off" />
                            </fieldset>

                            <fieldset>
                              <label>Enter short description</label>
                              <input
                                type="text"
                                placeholder="Enter short description"
                                value={NFTFormValue.NFTDescription}
                                autoComplete="off" 
                              />
                            </fieldset>

                            {/* <fieldset className="propertise">
                                                    <label className="mb8">Add properties</label>
                                                    <ul className="propertise-list">
                                                        <li><Link to="#">Art<i className="fal fa-times"></i></Link></li>
                                                        <li><Link to="#">Body type<i className="fal fa-times"></i></Link></li>
                                                        <li><Link to="#">Face color<i className="fal fa-times"></i></Link></li>
                                                    </ul>
                                                </fieldset> */}

                            <div className="set-item">
                              <fieldset>
                                <label className="mb8">
                                  Set item price or starting bid
                                </label>
                                <input type="text" placeholder="eg. 0,01 Eth"  autoComplete="off" />
                              </fieldset>
                              <fieldset>
                                <label className="mb8">
                                  Select royalties amount, %
                                </label>
                                <input type="text" placeholder="eg. 0,01 Eth"  autoComplete="off" />
                              </fieldset>
                            </div>

                            <h6 className="title ">Choose Collection</h6>
                            <p className="sub">
                              They All Serve The Same Purpose.
                            </p>

                            <ul className="create-collection">
                              <li className="">
                                <div className="create-item">
                                  <div className="img">
                                    <i className="fal fa-plus"></i>
                                  </div>
                                  <div className="content">
                                    <h6>Create new collection</h6>
                                    <p>Tap to create</p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="create-item">
                                  <div className="img">
                                    <img
                                      src={require("../assets/images/add-collection.jpg")}
                                      alt="Image"
                                    />
                                  </div>
                                  <div className="content">
                                    <h6>Battle for Digital</h6>
                                    <p>56 items</p>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <h6 className="title mb0">Choose collection</h6>
                            <p className="sub mb20">
                              They all serve the same purpose.
                            </p>

                            <ul className="collection-list">
                              <li>
                                <div className="list">
                                  <div className="infor">
                                    <p>Product updates</p>
                                    <h6>Receive messages from our platform</h6>
                                  </div>

                                  <div className="button-toggle">
                                    <input type="checkbox" id="switch" />
                                    <label for="switch"></label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="list">
                                  <div className="infor">
                                    <p>Reminders</p>
                                    <h6>
                                      Receive booking reminders, pricing notices
                                    </h6>
                                  </div>
                                  <div className="button-toggle mt0">
                                    <input type="checkbox" id="switch1" />
                                    <label for="switch1"></label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="list">
                                  <div className="infor">
                                    <p>Promotions and tips</p>
                                    <h6>
                                      Receive coupons, promotions, surveys
                                    </h6>
                                  </div>
                                  <div className="button-toggle">
                                    <input type="checkbox" id="switch2" />
                                    <label for="switch2"></label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="list">
                                  <div className="infor">
                                    <p>Account support</p>
                                    <h6>
                                      Receive messages about your account, your
                                      trips, legal alerts
                                    </h6>
                                  </div>
                                  <div className="button-toggle">
                                    <input type="checkbox" id="switch3" />
                                    <label for="switch3"></label>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <div className="bottom-button">
                              {/* <Button className="tf-button active"
                                                            disabled={(FormButton == 'error' || FormButton == 'done' || FormButton == 'process') ? true : false}
                                                            onClick={FormButton == "start" ? FormSubmit : null}>
                                                            {FormButton == "start" && "Create Items"}
                                                            {FormButton == "error" && "Error"}
                                                            {FormButton == "process" && "processing"}
                                                            {FormButton == "done" && "Done"}
                                                            {FormButton == "open" && "Approve"}
                                                        </Button>
                                                        <Button className="tf-button active" >Discard all</Button> */}
                              {/* <Link to="#" className="tf-button">Discard all</Link> */}
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            collection == 4
                              ? "content-inner active d-block"
                              : "content-inner d-none"
                          }
                        >
                          <div className="tab-create-collection">
                            <h6 className="title">Upload </h6>
                            <p className="sub">
                              But Each One Takes A Different Approach And Makes
                              Different Tradeoffs.
                            </p>

                            <div className="drag-upload">
                              <input type="file" />
                              <img src={Upload} alt="Image" />
                              <h6 className="title">
                                Drag your item to upload
                              </h6>
                              <p className="sub-title">
                                PNG, GIF, WebP, MP4 Or MP3. Maximum File Size
                                100 Mb.
                              </p>
                            </div>

                            <h6 className="title">Upload An Item</h6>
                            <p className="sub mb22">
                              But Each One Takes A Different Approach And Makes
                              Different Tradeoffs.
                            </p>

                            <fieldset>
                              <label>Name your item</label>
                              <input type="text" placeholder="Name your item"  autoComplete="off" />
                            </fieldset>

                            <fieldset>
                              <label>Enter short description</label>
                              <input
                                type="text"
                                placeholder="Enter short description"
                                autoComplete="off" 
                              />
                            </fieldset>

                            {/* <fieldset className="propertise">
                                                    <label className="mb8">Add properties</label>
                                                    <ul className="propertise-list">
                                                        <li><Link to="#">Art<i className="fal fa-times"></i></Link></li>
                                                        <li><Link to="#">Body type<i className="fal fa-times"></i></Link></li>
                                                        <li><Link to="#">Face color<i className="fal fa-times"></i></Link></li>
                                                    </ul>
                                                </fieldset> */}

                            <div className="set-item">
                              <fieldset>
                                <label className="mb8">
                                  Set item price or starting bid
                                </label>
                                <input
                                  type="text"
                                  placeholder="E.G. 0,01 Eth"
                                  autoComplete="off" 
                                />
                              </fieldset>
                              <fieldset>
                                <label className="mb8">
                                  Select royalties amount, %
                                </label>
                                <input
                                  type="text"
                                  placeholder="E.G. 0,01 Eth"
                                  autoComplete="off" 
                                />
                              </fieldset>
                            </div>

                            <h6 className="title ">Choose Collection</h6>
                            <p className="sub">
                              They All Serve The Same Purpose.
                            </p>

                            <ul className="create-collection">
                              <li className="">
                                <div className="create-item">
                                  <div className="img">
                                    <i className="fal fa-plus"></i>
                                  </div>
                                  <div className="content">
                                    <h6>Create new collection</h6>
                                    <p>Tap to create</p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="create-item">
                                  <div className="img">
                                    <img
                                      src={require("../assets/images/add-collection.jpg")}
                                      alt="Image"
                                    />
                                  </div>
                                  <div className="content">
                                    <h6>Battle for Digital</h6>
                                    <p>56 items</p>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <h6 className="title mb0">Choose collection</h6>
                            <p className="sub mb20">
                              They all serve the same purpose.
                            </p>

                            <ul className="collection-list">
                              <li>
                                <div className="list">
                                  <div className="infor">
                                    <p>Product updates</p>
                                    <h6>Receive messages from our platform</h6>
                                  </div>

                                  <div className="button-toggle">
                                    <input type="checkbox" id="switch4" />
                                    <label for="switch4"></label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="list">
                                  <div className="infor">
                                    <p>Reminders</p>
                                    <h6>
                                      Receive booking reminders, pricing notices
                                    </h6>
                                  </div>
                                  <div className="button-toggle mt0">
                                    <input type="checkbox" id="switch5"/>
                                    <label for="switch5"></label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="list">
                                  <div className="infor">
                                    <p>Promotions and tips</p>
                                    <h6>
                                      Receive coupons, promotions, surveys
                                    </h6>
                                  </div>
                                  <div className="button-toggle">
                                    <input type="checkbox" id="switch6"/>
                                    <label for="switch6"></label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="list">
                                  <div className="infor">
                                    <p>Account support</p>
                                    <h6>
                                      Receive messages about your account, your
                                      trips, legal alerts
                                    </h6>
                                  </div>
                                  <div className="button-toggle">
                                    <input type="checkbox" id="switch7"/>
                                    <label for="switch7"></label>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <div className="bottom-button">
                              <Link to="#" className="tf-button active">
                                Publish
                              </Link>
                              <Link to="#" className="tf-button">
                                Discard all
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                
              </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="item-preview">
                  <h5 className="title-preview" data-aos="fade-left">
                    Item Preview
                  </h5>
                  <div className="sc-product style1" data-aos="zoom-in">
                    <div className="top">
                      {NFTFormValue.NFTName != "" ? (
                        <Link to="#" className="tag">
                          {NFTFormValue.NFTName}
                        </Link>
                      ) : (
                        <Link to="#" className="tag">
                          Sweet Baby #1
                        </Link>
                      )}

                      <div className="wish-list">
                        <Link to="#" className="heart-icon"></Link>
                      </div>
                    </div>
                    <div className="features">
                      <div className="product-media">
                      {/* <object data={state?.Image} width="288" height="288"></object> */}
                      <iframe style={{ background: 'url(' + state?.Image + ')',backgroundRepeat: "no-repeat",
backgroundSize: "100% 100%",maxHeight: 288,minHeight: 288,minWidth: 288,maxWidth: 288,borderRadius:15}} title="Iframe Example" id="myiFrame"></iframe>
                      </div>
                      {/* <div className="featured-countdown">
                                                <span className="js-countdown">
                                                    <Countdown
                                                        date={Date.now() + 8000000}
                                                        autoStart={true}
                                                        renderer={renderer} />
                                                </span>
                                            </div> */}
                    </div>
                    <div className="bottom">
                      <div className="details-product">
                        <div className="author">
                          <div className="avatar">
                            <img
                              src={
                                payload?.Profile 
                                ? `${config.IMG_URL}/user/${payload?.WalletAddress}/profile/${payload?.Profile}`
                                : require("../assets/images/banner.png")}
                              alt="images"
                            />
                          </div>
                          <div className="content">
                            <div className="position">Creator</div>
                            <div className="name">
                              {" "}
                              {payload ?<Link to="#">{payload?.DisplayName?payload.DisplayName:accountAddress.slice(0,4)+"..."}</Link> //Carly Webster
                                  :<Link to="#">Carly Webster</Link>}
                            </div>
                          </div>
                        </div>
                        {NFTFormValue.PutOnSale === false ? <></>
                        // <div className="current-bid">
                        //    <div className="subtitle">Current bid</div>
                        //   <div className="price"> 
                        //     <span className="cash">Not For Sale</span>
                        //     <span className="icon">
                        //       <img src={ETH} alt="images" />
                        //     </span>
                        //   </div>
                        // </div>
                        :
                        <div className="current-bid">
                        
                        
                        <div className="subtitle">{NFTFormValue.PutOnSaleType==="FixedPrice" ? "Current price" : "Minimum bid"}</div>
                          <div className="price">
                          {NFTFormValue.PutOnSaleType==="FixedPrice" 
                          ?   <span className="cash">{NFTFormValue?.NFTPrice ? NFTFormValue?.NFTPrice : "5"} {NFTFormValue?.CoinName ? NFTFormValue.CoinName : "ETH"}</span>
                          :   <span className="cash">{NFTFormValue?.NFTPrice ? NFTFormValue?.NFTPrice : "5"} {NFTFormValue?.CoinName ? NFTFormValue.CoinName : "ETH"}</span>}
                            <span className="icon">
                              <img src={ETH} alt="images" />
                            </span>
                          </div>
                          
                        </div>}
                      </div>
                      <div className="product-button">
                        <Link
                          to="#"
                          data-toggle="modal"
                          data-target="#popup_bid"
                          className="tf-button"
                        >
                          {" "}
                          <span className="icon-btn-product"></span> Purchase
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {NFTFormValue.NFTOrginalImage &&
                  (NFTFormValue?.NFTOrginalImage?.type?.includes("video") ||
                    NFTFormValue?.NFTOrginalImage?.type?.includes("audio")) && (
                    <div className="thumbnail-preview">
                      <h5 className="title-preview" data-aos="fade-right">
                        Thumbnail Preview
                      </h5>
                      <div className="sc-product style1" data-aos="zoom-in">
                        <div className="top">
                          <Link to="#" className="tag">
                            Sweet Baby #1
                          </Link>
                          <div className="wish-list">
                            <Link to="#" className="heart-icon"></Link>
                          </div>
                        </div>
                        <div className="features">
                          <div className="product-media">
                            <img
                              src={
                                NFTFormValue.NFTThumpImage
                                  ? URL.createObjectURL(
                                      NFTFormValue.NFTThumpImage
                                    )
                                  : require("../assets/images/product52.jpg")
                              }
                              alt="images"
                            />
                          </div>
                          {/* <div className="featured-countdown">
                                        <span className="js-countdown" data-timer="55555" data-labels=" ,  h , m , s "></span>
                                    </div> */}
                        </div>
                        <div className="bottom">
                          <div className="details-product">
                            <div className="author">
                              <div className="avatar">
                                <img
                                  src={require("../assets/images/banner.png")}
                                  alt="images"
                                />
                              </div>
                              <div className="content">
                                <div className="position">Creator</div>
                                <div className="name">
                                  {" "}
                                  {payload ?<Link to="#">{payload?.DisplayName?payload.DisplayName:accountAddress.slice(0,4)+"..."}</Link> //Carly Webster
                                  :<Link to="#">Carly Webster</Link>}
                                </div>
                              </div>
                            </div>
                            {NFTFormValue.PutOnSale === false ? <></>
                              :
                              <div className="current-bid">
                        
                        
                              <div className="subtitle">{NFTFormValue.PutOnSaleType==="FixedPrice" ? "Current price" : "Minimum bid"}</div>
                              <div className="price">
                              {NFTFormValue.PutOnSaleType==="FixedPrice" 
                                ?   <span className="cash">{NFTFormValue?.NFTPrice ? NFTFormValue?.NFTPrice : "5"} {NFTFormValue?.CoinName ? NFTFormValue.CoinName : "ETH"}</span>
                                :   <span className="cash">{NFTFormValue?.NFTPrice ? NFTFormValue?.NFTPrice : "5"} {NFTFormValue?.CoinName ? NFTFormValue.CoinName : "ETH"}</span>}
                                <span className="icon">
                                <img src={ETH} alt="images" />
                                </span>
                                </div>
                          
                            </div>}
                          </div>
                          <div className="product-button">
                            <Link
                              to="#"
                              data-toggle="modal"
                              data-target="#popup_bid"
                              className="tf-button"
                            >
                              {" "}
                              <span className="icon-btn-product"></span>{" "}
                              Purchase
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show8}
      >
        <Modal.Header>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose8}
          ></button>
          <Modal.Title id="contained-modal-title-vcenter">
            Follow Steps
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {ApproveButton != "stop" && (
            <div className="ifpsmetadata mt-4">
              <div className="ifpsmetadatasec d-flex justify-content-start align-items-center">
                <i className="fa fa-check"></i>
                <div className="text-left ml-5">
                  <h6>Approve Call</h6>
                  <p>One Time Process</p>
                </div>
              </div>
              <Button
                className="connectwallet tf-button active btn btn-primary primary my-3"
                disabled={
                  ApproveButton == "process" || ApproveButton == "done"
                    ? true
                    : false
                }
                onClick={
                  ApproveButton == "start" || ApproveButton == "try"
                    ? TokenApproveCall
                    : null
                }
                disableRipple
              >
                {ApproveButton == "start" && "Start"}
                {ApproveButton == "process" && "In-Progress"}
                {ApproveButton == "try" && "Try-Again"}
                {ApproveButton == "done" && "Done"}
              </Button>
            </div>
          )}

          <div className="ifpsmetadata">
            <div className="ifpsmetadatasec  d-flex justify-content-start align-items-center">
              <i className="fa fa-check"></i>
              <div className="text-left ml-5">
                <h6>Upload File</h6>
                <p>Upload File To Our Marketplace</p>
              </div>
            </div>
            <Button
              className="connectwallet tf-button active btn btn-primary primary my-3"
              disabled={
                UploadButton == "stop" || UploadButton == "process" || UploadButton == "done"
                  ? true
                  : false
              }
              onClick={
                UploadButton == "start" || UploadButton == "try"
                  ? UploadIPFScall
                  : null
              }
              disableRipple
            >
              {UploadButton == "stop" && "Start"}
              {UploadButton == "start" && "Start"}
              {UploadButton == "process" && "In-Progress"}
              {UploadButton == "try" && "Try-Again"}
              {UploadButton == "done" && "Done"}
            </Button>
          </div>

          <div className="ifpsmetadata mt-4">
            <div className="ifpsmetadatasec d-flex justify-content-start align-items-center">
              <i className="fa fa-check"></i>
              <div className="text-left ml-5">
                <h6>List NFT</h6>
                <p>Call contract method</p>
              </div>
            </div>
            {console.log("fsgfshgf",MintButton)}
            <Button
              className="connectwallet tf-button active btn btn-primary primary my-3"
              disabled = {(MintButton==="stop" || MintButton == "process" || MintButton == "done" )?true:false}
              onClick={MintButton == "start" || MintButton == "try"
              ? MintCall
              : null}
              disableRipple
            >
              {MintButton == "stop" && "Start"}
              {MintButton == "start" && "Start"}
              {MintButton == "process" && "In-Progress"}
              {MintButton == "try" && "Try-Again"}
              {MintButton == "done" && "Minted"}
            </Button>
            {/* <Button className="connectwallet primary my-3" 
                        
                        disabled={
                          MintButton == "process" ||
                          MintButton == "done" 
                            ? true
                            : false
                        }
                        onClick={
                            MintButton == "start" || MintButton == "try"
                            ? MintCall
                            : null
                        }
                        disableRipple >
                            {MintButton == "start" && "Start"}
                            {MintButton == "process" && "In-Progress"}
                            {MintButton == "try" && "Try-Again"}
                            {MintButton == "done" && "Done"}
                        </Button> */}
          </div>
          {/* <div className="ifpsmetadata mt-4">
                        <div className="ifpsmetadatasec d-flex justify-content-start align-items-center">
                            <i className="fa fa-check"></i>
                            <div className="text-left ml-5">
                                <h6>Sign Sell Order</h6>
                                <p>Sign sell order using tour wallet</p>
                            </div>
                        </div>
                        <button className="connectwallet primary my-3" onClick={() => setTheme(!theme)}>Done</button>
                    </div>
                    <div className='ifpsmetadata mt-4'>
                        <div className='ifpsmetadatasec d-flex justify-content-start align-items-center'>
                            <i className="fa fa-check"></i>
                            <div className="text-left ml-5">
                                <h6>Sign lock order</h6>
                                <p>Sign lock order using tour wallet</p>
                            </div>
                        </div>
                        <button className="connectwallet primary my-3" onClick={() => setTheme(!theme)}>Done</button>
                    </div> */}
        </Modal.Body>
      </Modal>
      {OpenPopup == "collection" && (
        <CreateCollection
          SetOpenPopup={SetOpenPopup}
          Creator={accountAddress}
          Network={NFTFormValue.CollectionNetwork}
          Type={NFTFormValue.ContractType}
          Address={NFTFormValue.ContractAddress}
        />
      )}
      {/* {console.log('opennnpopppupppp',OpenPopup)} */}
      {((OpenPopup == "ClockTime" ||
        OpenPopup == "EndClockTime" )&& (
          <CLockModal
            SetOpenPopup={SetOpenPopup}
            modal={OpenPopup}
            validDate = {OpenPopup == "ClockTime"?validStart:validEnd}
            setClockValue={setClockValue}
            closeModal={CloseModal}
          />
        ))}
    </>
  );
}
