import React, { useEffect, useState } from "react";
import Wallet1 from "../assets/images/svg/icon-wallet-1.svg";
import Wallet2 from "../assets/images/svg/icon-wallet-2.svg";
import Wallet3 from "../assets/images/svg/icon-wallet-3.svg";
import Wallet4 from "../assets/images/svg/icon-wallet-4.svg";
import TW from '../assets/images/TWT.png';

import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { NavLink } from "react-router-dom";
import { mainnet,avalancheFuji } from 'wagmi/chains'
import { connectWallet } from './hooks/useWallet'; 
//redux
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isEmpty } from '../actions/common';
import { useNavigate } from "react-router-dom";

import { userRegister,GetUserCookieToken} from '../actions/axioss/user.axios'
import { GetNftCookieToken } from "../actions/axioss/nft.axios";
import { useWeb3React } from '@web3-react/core';
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import Config from "./config/config"
import { Web3Provider } from "@ethersproject/providers";
import Web3 from "web3";
import { createPublicClient, http } from 'viem'

// import { mainnet,avalancheFuji} from '@wagmi/core/chains'



import {useSwitchNetwork, useWebSocketPublicClient} from 'wagmi'
import {
    useAccount,
    useConnect,
  } from 'wagmi'

import {getNetwork,getWalletClient,switchNetwork, watchNetwork} from '@wagmi/core'


export default function ConnectWallet(){
    const { chains, pendingChainId, switchNetwork } = useSwitchNetwork()
 console.log("chains",chains)
    const [wallet,setWallet] = React.useState([
        {icon:Wallet1,beta:"Alpha",title:"Meta Mask",description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",id: "MetaMask"},
        {icon:Wallet3,beta:"Alpha",title:"Wallet Connect",description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",id: "WalletConnect"},
        {icon:TW,beta:"Alpha",title:"Trust Wallet",description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",id: "TrustWallet"},
        {icon:Wallet4,beta:"Alpha",title:"Coin Base Web",description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",id: "Coinbase"},
        {icon:Wallet4,beta:"Alpha",title:"Coin Base Wallet",description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",id: "CoinbaseMobile"},
        
    ])
    const [mobileWallet,setMobileWallet] = React.useState([
        {icon:Wallet3,beta:"Alpha",title:"Wallet Connect",description:"It is a long established fact that a reader will be disptracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",id: "WalletConnect"},
        {icon:Wallet4,beta:"Alpha",title:"Coin Base",description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",id: "CoinbaseMobile"},
    ])
    

    const { connect,connectors, error, isLoading, pendingConnector ,activeConnector} = useConnect({
        chainId: Config.CHAIN_ID== 1 ? mainnet?.id : avalancheFuji?.id
    })
    // console.log("chainIdchainIdchainId",chainId)
    const { address, connector, isConnected } = useAccount()
    const dispatch = useDispatch();
    const [status,setStatus]=useState(true)
    var navigate = useNavigate();
	const { token } = useSelector(state => state.LoginReducer.User)
   

        useEffect(()=>{
            console.log("isConnected",isConnected);
            isConnected && initialConnectWallet("CoinbaseMobile")
        },[isConnected])

    const initialConnectWallet = async (type)=>{
        console.log('hfjdhfgjkde',type)
        const id=toast.loading(type+" Connecting...")
            var accountDetails = await connectWallet(type)
        console.log("accountDetails",accountDetails)
        if(!isEmpty(accountDetails)){
            var resp = await setWalletAddress('InitialConnect',accountDetails.accountAddress,type)
            // console.log("resp",resp)
            if(resp?.success == 'success'){
                toast.update(id,{render : resp.msg , type:resp.success,autoClose:1000, isLoading: false,closeButton:true,closeOnClick:true})
                dispatch({
                    type: "Account_Section",
                    Account_Section: {AccountDetails:accountDetails}
                })
                navigate("/")
            }
            else{
                toast.update(id,{render : "Connect Wallet" , type:'error',autoClose:1000, isLoading: false,})
            }
                      
        }
        else toast.update(id,{render : "Try Again" , type:'error',autoClose:1000, isLoading: false,closeButton:true,closeOnClick:true})
    }
    const setWalletAddress    =   async   (type,walletAddress,walletType)  =>  {
    if(walletAddress){
      var NewMethod = {
            Type: type,
            WalletAddress: walletAddress,
            WalletType: walletType,
      }; 
      let Resp = await userRegister(NewMethod);
      console.log('inittttt',Resp)
      if(Resp?.success == 'success'){
          dispatch({
              type:'Register_Section',
              Register_Section : {
                  User: {
                      payload	: 	Resp.data,
                      token     :   Resp.token ? Resp.token : token
                  }
              }
          })
          document.cookie = 'token' + "=" + Resp?.token + ";" + ";path=/";

          GetNftCookieToken();
          GetUserCookieToken();
          return Resp
      }
      else return Resp
  }  
  else return {success:'error',msg:'No Address Detected.. Check Your Wallet'}

  }

  function getLibrary(provider) {
    console.log("provider",provider);
    return new Web3Provider(provider);
  }
  


    
    return(
       

            
            <>
            <Header />
              <section className="tf-page-title" data-aos="fade-left">    
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="breadcrumbs">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li>Connect Wallet</li>
                                </ul>
                            </div>
                        </div>
                    </div>                    
                </section>
            
                <section className="tf-connect-wallet" >
                    <div className="tf-container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="tf-heading style-5">
                                    <h4 className="heading" data-aos="fade-up">Connect Your Wallet</h4>
                                    <p className="sub-heading" data-aos="fade-down">Choose how you want to connect. There are several wallet providers.</p>
                                </div>
                            </div>
    
                   
                           { wallet.map((data,index) => 
                                (
                                    <div className="col-lg-4 col-md-6" data-aos="zoom-in">
                                <div className="tf-wallet" onClick={() => data.id !== "CoinbaseMobile" ? initialConnectWallet(data.id) : connect({ connector: connectors[0] })}>
                                    <div className="icon">
                                        <img src={data.icon} alt="Image" width="100" height="100" classNameName="img-fluid" />
                                        <span className="label">{data.beta}
                                        </span>
                                    </div>
                                    <h6 className="title">{data.title}</h6>
                                    <p className="content">{data.description}</p>
                                </div>
                            </div>
                                )
                            
    
                            )
                                }
                       
                      
                           
                        </div>
                    </div>
                </section>
               
            <Footer />    
            </>

        

       
    )
}