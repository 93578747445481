import React ,{useState , useEffect , useRef} from "react";
import Card from "./Card.js";
import { NavLink , useParams ,useNavigate,useLocation} from "react-router-dom";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { useSelector } from "react-redux";
import {userRegister , Token_MyList_Func ,FollowUnFollowFunc} from '../actions/axioss/user.axios'
import action_config from './config/config';
import {toast} from 'react-toastify'
import { Link } from "react-router-dom";
import { address_showing, Name_showing } from "../../src/actions/common";
import NoData from "./seperatemodals/nodata";
import Button from "react-bootstrap/esm/Button";

export default function MyItem(){
    const location=useLocation();
    console.log("LOCATIONINMTAB",location,location.state)
    const navigate=useNavigate()
    const[loading,setLoading]=useState(true)
    const [category,setCategory] = React.useState('owned');
    const [show,setShow] = React.useState(false);
    const [cursor,setcursor] = React.useState('');
    var { customurl } = useParams();
    const [OpenPopup, SetOpenPopup] = useState('')
    var LikeForwardRef = useRef();
    const [LikedTokenList, setLikedTokenList] = useState([]);
    const [value, SetTabName] = React.useState('owned');
    // const { push    }   =   useHistory()
    const { payload,isAdmin } = useSelector(state => state.LoginReducer.User)
    // const AxiosFile = useAxiosFile()
    const [userProfile, setUserProfile] = useState({})
    const [Tokens, SetTokens] = useState({ 'owned': { 'loader': true, page: 1, list: [] } })
    const [Follow, SetFollow] = useState('unfollow');
    var [filter,setFilter] = useState("LatestDrops");
    var [filtershow,setFiltershow] = useState("Recently Created")
    const { web3p, accountAddress } = useSelector(
        (state) => state.LoginReducer.AccountDetails
      );
      const { liked } = useSelector(
        (state) => state.LoginReducer.Liked  
      );
        // console.log('paramss',customurl)

        useEffect(() => {
            console.log("VANTHUTTA",typeof Tokens[value] == 'undefined' || Tokens[value].filter !== filter)
            if (typeof Tokens[value] == 'undefined' || Tokens[value].filter !== filter) {
                Tokens[value] = { page: 1, list: [], loader: false };
                setLoading(true)
                SetTokens(Tokens);
                Explore(1, value);
            }
           
        }, [value, customurl,filter,liked])


        useEffect(()=>{
            if(value=="liked"){
                console.log("ULLAVA");
                Tokens[value] = { page: 1, list: [], loader: false };
                setLoading(true)
                SetTokens(Tokens);
                Explore(1, value);
            }
        },[liked])
        
        useEffect(() => {
            Explore();
            getProfileDetails()
        }, [customurl,userProfile?.WalletAddress])
    
        const getProfileDetails = async () => {

            var SendDATA = {
                CustomUrl: customurl,
                Type: 'getProfile',
                User : payload?.WalletAddress
            }
            var profileInfo = await userRegister(SendDATA)
            // console.log('senndddd',profileInfo)
            if (profileInfo?.success == 'success' && profileInfo?.data?.WalletAddress) {
                setUserProfile(profileInfo.data)
        SetFollow(profileInfo.follow == "follow" ? "unfollow" : "follow")
            }
            else{
                const id = toast.loading("Searching User");
                toast.update(id,{render:'User Not found',type:'error',isLoading:false,autoClose:700,closeButton:true,closeOnClick:true})
                setTimeout(function(){ 
                    navigate('/')
                  }, 2000);
            }
            // var SendDATA = {
            //     CustomUrl: customurl,
            //     Type: 'getProfile'
            // }
            // var profileInfo = await userRegister(SendDATA)
            // if (profileInfo?.success == 'success' && profileInfo?.data?.WalletAddress) {
            //     setUserProfile(profileInfo.data)
            // }
        }
    
        const Explore = async (data, tab) => {
            console.log("VAAAAA")
            var page = data ? data : (Tokens[value]?.page)
            var SendDATA = {
                TabName: tab ? tab : value,
                limit: (tab === "usercollection" || value === "usercollection") ? 100 : 12,
                CustomUrl: customurl,
                NFTOwner: (userProfile?.WalletAddress),
                page: page ?? 1,
                filter: filter,
                from: 'myItem',
                cursor:cursor
            }
            let Resp = await Token_MyList_Func(SendDATA)
            // console.log('sfgfhgfs',value,tab,Resp)
            if (Resp?.success == 'success' && Resp.data.length > 0) {
                setcursor(Resp?.cursor)
                SetTokens({
                    ...Tokens, ...{
                        [value]: {
                            list: [...Tokens[value].list, ...Resp.data],
                            loader: ( Resp.data.length ==0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
                            page: Tokens[value].page,
                            filter: filter,
                        }
                    }
                })
                setLoading(false)
            } else 
            SetTokens({
                ...Tokens,
                ...{
                  [value]: {
                    list: Tokens[value].list,
                    loader: false,
                    page: Tokens[value].page,
                    filter: filter
                  },
                },
              });
              setLoading(false)
        }

        const LoadMore = () => {
            Tokens[value].page = Tokens[value].page + 1;
            SetTokens(Tokens);
            Explore(Tokens[value].page);
    
        }

    function LikeList(data){
        setLikedTokenList(data)
    }

    const FollowUnFollow = async()  =>  {
        const id = toast.loading(Follow == "follow" ? "You're Following "+ userProfile?.DisplayName : "Your UnFollowing "+ userProfile?.DisplayName)
        let SendData = {
            MyItemAddr  :   userProfile?.WalletAddress,
            ClickAddr   :   accountAddress,
            From        :   'myitem',
            MyItemCustomUrl    :    userProfile?.CustomUrl,
            ClickCustomUrl    :    payload?.CustomUrl,
        }
        // console.log('senddataaa',SendData,userProfile,payload)
        let Resp    =   await   FollowUnFollowFunc(SendData)
        if(Resp?.success){
            SetFollow(Resp.msg == 'follow' ? 'unfollow' : 'follow')
            toast.update(id,{render:Resp.msg == 'follow' ? "You're Following "+userProfile?.DisplayName + ' Successfully' :'Your UnFollowing '+userProfile?.DisplayName + ' Successfully',type:'success',isLoading:false,autoClose:1000 })
        }
        else{
            toast.update(id,{render:'Try Again',type:'error',isLoading:false,autoClose:1000,closeButton:true,closeOnClick:true})

        }
    }

    // useEffect(() => {
    //   if(filter !== Tokens[value].filter){
    //     Explore();
    //   }
    //   },[filter]);
      
    return(
        <>
        {/* <Header /> */}
        <div className="collection">
            {/* <section className="tf-page-title style-2" data-aos="fade-right">    
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="breadcrumbs">
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="/explore">Explore</NavLink></li>
                                <li>My-item</li>
                            </ul>
                        </div>
                    </div>
                </div>                    
            </section> */}
            {/* {userProfile?.WalletAddress != payload?.WalletAddress &&
                                   <div className="text-center follow_btn">
                                   <Button className="tf-button active btn btn-primary" onClick={FollowUnFollow}>{Follow}</Button>
                               </div>} */}
            <section className="tf-banner-collection">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            {/* {console.log("resssssss",userProfile)} */}
                            { 
                            location?.state?.from ? 
                            <div className="banner-collection-inner">
                                <img src={userProfile?.Cover ? action_config.IMG_URL+"/user/"+userProfile.WalletAddress+'/cover/'+userProfile.Cover  : require("../assets/images/banner-collection.jpg")} alt="Image" className="img-bg" />
                                <img src={userProfile?.Profile ? action_config.IMG_URL+"/user/"+userProfile.WalletAddress+'/profile/'+userProfile.Profile  : require("../assets/images/banner.png")} alt="Image" className="img-banner" width="92" height="92" />
                            </div>
                            :""
}
                            <div className="tf-heading style-5">
                                <h2 className="heading" data-aos="fade-right" >{userProfile?.DisplayName?Name_showing(userProfile?.DisplayName):address_showing(userProfile?.WalletAddress)}</h2>
                                <p className="sub-heading"  data-aos="fade-down">@{userProfile?.CustomUrl}</p>
                            </div>
                            {userProfile?.WalletAddress != payload?.WalletAddress &&
                                   <div className="text-center follow_btn">
                                   <Button className="tf-button active btn btn-primary" onClick={FollowUnFollow}>{Follow}</Button>
                               </div>}
                        </div>
                    </div>
                </div>
            </section>
                
            <section className="tf-collection-page tf-filter">
                <div className="tf-container">
                    <div className="row ">
                        <div className="col-md-12">
                        <div className="top-menu row"  data-aos="fade-up">
                                <ul className="filter-menu">
                                    <li className={value == "owned" ? "active" : ""} onClick={() => SetTabName("owned")}><a>Owned</a></li>
                                    <li className={value == "onsale" ? "active" : ""} onClick={() => SetTabName("onsale")}><a>On sale</a></li>
                                    <li className={value == "liked" ? "active" : ""} onClick={() => SetTabName("liked")}><a>Liked</a></li>
                                    <li className={value == "created" ? "active" : ""} onClick={() => SetTabName("created")}><a>Created</a></li>
                                 {/* {accountAddress == userProfile.WalletAddress &&   <li className={value == "usercollection" ? "active" : ""} onClick={() => SetTabName("usercollection")}><a>User Collections</a></li>} */}
                                </ul>
                                <div id="item_category2" className="dropdown">
                                    <Link to="#" className="btn-selector nolink ">{filtershow}</Link>
                                    <ul className="show">
                                        {/* <li><span>Recently Listed</span></li>
                                        <li className="active"><span>Recently Created</span></li>
                                        <li><span>Recently Sold</span></li>
                                        <li><span>Recently Received</span></li>
                                        <li><span>Recently Soon</span></li>
                                        <li><span>Recently Low to Hight</span></li>
                                        <li><span>Recently Last Sale</span></li>
                                        <li><span>Oldest</span></li> */}
                                        <li onClick={() => {setFilter("BLTH");setFiltershow("Price Low to High")}}><span>Price Low to High</span></li>
                                        <li onClick={() => {setFilter("BHTL");setFiltershow("Price High to Low")}}><span>Price High to Low</span></li>
                                        <li onClick={() => {setFilter("OLD");setFiltershow("Oldest")}}><span>Oldest</span></li>
                                        <li onClick={() => {setFilter("LatestDrops");setFiltershow("Recently created")}}><span>Recently created</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tf-filter-container row"  data-aos="fade-up">
                        {  loading == true ? 
                         <>
                         <div className="text-centre">
                             <h3 className="load_datas">Loading...</h3>
                             {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
                             {/* <p>Nothing for ReSale at this time</p> */}
                                     <div className="load-more">
                                     {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                                     </div>
                             </div>
                         </> : 
                        Tokens[value] &&
                        Tokens[value]?.list?.length>0 ? 
                        Tokens[value].list.map((val,index) => 
                        <div
        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber explore_anim"
        data-aos="zoom-in-up"
      >
                        <Card 
                        from    = { value == 'usercollection' ?  'usercollection' :'marketplace'}
                        product={ value == 'usercollection' ? 
                        {
                            NFTName : val.name,
                            CollectionNetwork   :  action_config.COIN_NAME ,
                            ContractAddress :   val.colladdress,
                            NFTOwner    :   userProfile.WalletAddress,
                            NFTId   :   val.tokenCounts,
                            Image   :   val.image,
                            OwnerName : userProfile.DisplayName ,
                            OwnerProfile : userProfile.Profile ,
                            type : val.type,
                            amount : val.amount,
                            ipfshash:val.ipfshash,
                            meta:val.meta,
                            metadata:val.metadata,
                            Creator_CustomUrl:payload.CustomUrl,
                            
                        }
                        :val}
                        type="marketplace"
                        index={index}
                        LikeList={LikeList}
                        LikedTokenList={LikedTokenList}
                        setLikedTokenList={setLikedTokenList}
                        LikeForwardRef={LikeForwardRef}/>
                        </div>
                        ):<NoData/>}
                       
                    </div>
                   {Tokens[value]?.loader&& <div className="col-md-12">
                        <div className="btn-loadmore mt6">
                            <Link to="#" className="tf-button loadmore" onClick={LoadMore}>Load More</Link>
                        </div>
                    </div>
                    }
                </div>
            </section>
        </div>
        {/* <Footer />     */}
        </>
    )
}