import React, { useEffect, useState, useRef } from "react";
import Author from "../assets/images/product52.jpg";
import Card from "../views/Card.js";
import Tick from "../assets/images/svg/tick.svg";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import Modals from "./Modals";
import bannerimg from '../assets/images/banner_right.png'
import '../assets/css/home.css'
import content_sec from '../assets/images/content_sec.png';
import { Token_List_Func, TopCreatorApi, getnfts, gettopnfts } from "../actions/axioss/nft.axios";
import { FollowUnFollowFunc, userRegister } from "../actions/axioss/user.axios";
import NoData from "./seperatemodals/nodata";
import nft_pf from '../assets/images/nfttab.png';
import nft_pf1 from '../assets/images/nfttab1.png';
import nfttopcard from '../assets/images/nfttop_card.png';
import creators_img from '../assets/images/creator.png';
import creators_img1 from '../assets/images/creator1.jpg';
import creators_img2 from '../assets/images/creator2.png';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import rightarrow from '../assets/images/right-arrow.png'
import config from './config/config';
import { getCmsContent } from "../actions/axioss/cms.axios";
import { address_showing, isEmpty, Name_showing } from "../actions/common";
import { Sociallinks } from "../actions/axioss/user.axios";
import authordetail from "../assets/images/creator.png";
import { toast } from 'react-toastify'
import { useSelector } from "react-redux";
import Button from "react-bootstrap/esm/Button";
import $ from 'jquery';
import { FaXTwitter } from "react-icons/fa6";
export default function Home() {
    const [nfts, setNfts] = useState([])
    const [topnfts, setTopnfts] = useState([])
    const[hotactionstate,SetHotAuctionstate]=useState(true)
    const[hotsalestate,SetHotsalestate]=useState(true)
    const [topnftstatus,setTopnftstatus]=useState(true)
    // const [bannerstate,setBannerstatus]=useState(true)
    const [TopCreator, SetTopCreator] = useState([])
    const [topcreaterstatus1,setTopcreatorstatus1]=useState(true)
    const [topcreaterstatus2,setTopcreatorstatus2]=useState(true)
    const [userProfile, setUserProfile] = useState({})
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [currentSlide1, setCurrentSlide1] = React.useState(1);
    const [following, setFollowing] = useState([])
    const [Follow, SetFollow] = useState('follow');
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(8)
    const [cms,setCms]=useState({})


    const [tabsnft, setTabsnft] = useState([

        {
            user:{tab :require('../assets/images/nfttab.png'),DisplayName : "GOLDEN NFT" ,Bio : "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.simply dummy text of the printing and typesetting industry." },
            
        },
        {
            user:{tab :require('../assets/images/nfttab1.png'),DisplayName : "GOLDEN NFT" ,Bio : "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.simply dummy text of the printing and typesetting industry." }
            
        },
        {
            user:{tab :require('../assets/images/pf_tab.png'),DisplayName : "GOLDEN NFT" ,Bio : "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.simply dummy text of the printing and typesetting industry." }

            
        },
        {
            user:{tab :require('../assets/images/nfttab.png'),DisplayName : "GOLDEN NFT" ,Bio : "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.simply dummy text of the printing and typesetting industry." }

        },
        {
            user:{tab :require('../assets/images/pf_tab1.png'),DisplayName : "GOLDEN NFT" ,Bio : "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.simply dummy text of the printing and typesetting industry." }
        }
    ])
      const [link,setLink]=useState({})
    useEffect(()=>
    {
        cmsget()
        Getlink()
    },[])
     const cmsget =async()=>{
    
         var resp = await getCmsContent();
         console.log("cmslist",resp.data)
         if(resp?.status){

            var hometop=resp?.data?.filter((item=>item.slug=="homepage_top"))[0]
            var homemiddle=resp?.data?.filter((item=>item.slug=="homepage_middle"))[0]
            setCms({
                "hometop":hometop,
                "homemiddle":homemiddle
             });
         }

        
     }
     const Getlink=async()=>{
        
        let link_res= await Sociallinks();
        console.log("SOCIALLL",link_res)
        setLink({
            facebook:link_res?.msg?.filter((item=>item?.slug?.toLowerCase()=="facebook"))[0],
            twitter:link_res?.msg?.filter((item=>item?.slug?.toLowerCase()=="twitter"))[0],
            telegram:link_res?.msg?.filter((item=>item?.slug?.toLowerCase()=="telegram"))[0]
        })
    }
    const handleNextSlide = () => {
        setCurrentSlide(currentSlide + 1);
        setCurrentSlide1(currentSlide1 + 1);
        setFadeIn(true);
    };

    const { web3p, accountAddress } = useSelector(
        (state) => state.LoginReducer.AccountDetails
    );
    const { payload, isAdmin } = useSelector(state => state.LoginReducer.User)
    const [fadeIn, setFadeIn] = useState(false);
    const options = {
        dots: false,
        navigation: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }

    };
    const optionstop = {
        dots: false,
        navigation: false,
        mouseDrag: false,
        responsive: {
            0: {
                items: 1,
                mouseDrag: false,
            },
            600: {
                items: 1,
                mouseDrag: false,
            },
            1000: {
                items: 1
            }
        }

    };
    const optionstop1 = {
        dots: false,
        navigation: false,
        mouseDrag: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }

    };
    const options1 = {
        dots: false,
        loop: true,
        mouseDrag: false,
        navigation: true,
        animateOut: 'slideOutUp',
        animateIn: 'slideInUp',
       
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }

    };
    const [hotcard, setHotcard] = useState([
        {
            id: "1"
        },
        {
            id: "2"
        },
        {
            id: "3"
        }
    ]);
    const [HotAuctionData, SetHotAuction] = useState({
        All: [],
        "tab": "All"
    });
    const [filter, setFilter] = useState({ auction: "Recent", collection: "new", sale: "LatestDrops" });
    const [LikedTokenList, setLikedTokenList] = useState([]);
    var LikeForwardRef = useRef();


    function tabChangeCircle2(selectedTab, selectedImagenew) {

        console.log(selectedTab, selectedImagenew);
        console.log($(selectedTab).parent().parent(), "fsa");
        $("#slider_list_2 li").each(function () {
            $(this).removeClass("active");
        });
        $("#tab_content_2 .tab-pane").each(function () {
            $(this).removeClass("active");
        });
        $(".pos_circle_tabs_2 .circle_tabs").each(function () {
            $(this).removeClass("active");
        })
        $(selectedTab).parent().parent().addClass("active");

        var elementOffset = $("#" + selectedImagenew).offset().top;
        console.log("elementOffset", elementOffset);
        var image_split_id1 = selectedImagenew.split("_")[2];
        $("#new_tab_" + image_split_id1).addClass("active");
        console.log("image_split_id1", image_split_id1);
        $(".new_tab_circle_" + image_split_id1).addClass("active");
        var isi1 = (image_split_id1 - 1) * 133;
        console.log(image_split_id1, isi1, "image_split_id");
        const El1 = document.getElementById('slider_list_2');


        El1.scrollTo({ top: isi1, behavior: 'smooth' });


    }

    useEffect(() => {
        HotAuction(1, 'All');
        TopCreatorFunc()
        getnft();
        topnft();
        // followcheck()
        // getProfileDetails()
    }, [])

    //   useEffect(()=>{
    //     followcheck()
    //   },[])

    //   const followcheck=()=>{
    //     var following=payload?.Following?.map((item)=>{
    //       return [item?.Address]
    //     })
    //     setFollowing(following)
    //     console.log("follwwww",following)
    //   }
    //   const getProfileDetails = async () => {

    //     var SendDATA = {
    //         CustomUrl: customurl,
    //         Type: 'getProfile',
    //         User : payload?.WalletAddress
    //     }
    //     var profileInfo = await userRegister(SendDATA)
    //     // console.log('senndddd',profileInfo)
    //     if (profileInfo?.success == 'success' && profileInfo?.data?.WalletAddress) {
    //         setUserProfile(profileInfo.data)
    // SetFollow(profileInfo.follow == "follow" ? "unfollow" : "follow")
    //     }
    //     else{
    //         const id = toast.loading("Searching User");
    //         toast.update(id,{render:'User Not found',type:'error',isLoading:false,autoClose:700,closeButton:true,closeOnClick:true})
    //         // setTimeout(function(){ 
    //         //     navigate('/')
    //         //   }, 2000);
    //     }
    //     // var SendDATA = {
    //     //     CustomUrl: customurl,
    //     //     Type: 'getProfile'
    //     // }
    //     // var profileInfo = await userRegister(SendDATA)
    //     // if (profileInfo?.success == 'success' && profileInfo?.data?.WalletAddress) {
    //     //     setUserProfile(profileInfo.data)
    //     // }
    // }

    const topnft = async () => {
        console.log("vanthutta")
var arr=[];
        var resp = await gettopnfts();

        // console.log("top nft ", resp,resp.data.length);
        arr.push(...resp.data)
        if(resp.data.length < 6 ){
          console.log("dddd length",5-resp.data.length);
          var join =tabsnft.slice(0,5-resp.data.length)
          console.log("join ",join,5-resp.data.length)
         arr.push(...join)

        }
        console.log("arr",arr);
        var dd= arr?.map((item,index)=> {
              if(index == 0){
                  item.indexkey= 'three'
                 return item
              }
              else  if(index == 1){
                  item.indexkey= 'second'
                return item
              }
              else  if(index == 2){
                 item.indexkey= 'first'
                return item
              }
              else  if(index == 3){
                 item.indexkey= 'four'
                return item
              }
              else {
                  item.indexkey= 'five'
                return item
              }
              

         } )
         console.log("DDDarr",dd)

        setTopnfts(dd)
        setTopnftstatus(false)
    }


    //   const FollowUnFollow = async(data)  =>  {
    //     const id = toast.loading(Follow == "follow" ? "Your Following "+ data?.DisplayName : "Your UnFollowing "+ data?.DisplayName)
    //     let SendData = {
    //         MyItemAddr  :   data?.WalletAddress,
    //         ClickAddr   :   accountAddress,
    //         From        :   'myitem',
    //         MyItemCustomUrl    :    data?.CustomUrl,
    //         ClickCustomUrl    :    payload?.CustomUrl,
    //     }
    //     console.log('senddataaa',SendData,data.WalletAddress,accountAddress,payload)
    //     let Resp    =   await   FollowUnFollowFunc(SendData)
    //     if(Resp?.success){

    //         SetFollow(Resp.msg == 'follow' ? 'unfollow' : 'follow')
    //         toast.update(id,{render:Resp.msg == 'follow' ? 'Your Following '+data?.DisplayName + ' Successfully' :'Your UnFollowing '+data?.DisplayName + ' Successfully',type:'success',isLoading:false,autoClose:1000 })
    //     }
    //     else{
    //         toast.update(id,{render:'Try Again',type:'error',isLoading:false,autoClose:1000,closeButton:true,closeOnClick:true})

    //     }
    // }

    const getnft = async () => {
        console.log("vanthuttaaaa")
        var datas = { "skip": skip, "limit": 24 }
        // console.log("datassss",datas)
        var resp = await getnfts(datas);
        // setSkip(limit+skip);
        console.log("nfts get resp", resp);
        setNfts(resp.data)
        SetHotsalestate(false)
    }
    useEffect(() => {
        // if(filter?.auction)
        if (HotAuctionData.filter !== undefined && filter.auction !== HotAuctionData.filter) {
            HotAuction('hi', HotAuctionData.tab)
        }
    }, [filter])
    const TopCreatorFunc = async () => {
        var resp = await TopCreatorApi()
        console.log('ressssppppppsfasdfasdfasdfasdf', resp.data)

        var arrr=resp?.data?.map((item) => {
            item.Follower=item.Follower?.map(item=>{return item.Address})
            return item
          })
        console.log("FOLOOW",arrr)
        SetTopCreator(arrr)
        setTopcreatorstatus1(false)
        setTopcreatorstatus2(false)
    }

    const HotAuction = async (data, tabs) => {
        var SendDATA = {
            TabName: tabs,
            limit: 24,
            ProfileUrl: "",
            page: 1,
            from: "Auction",
            filter: filter.auction,
        };
        let Resp = await Token_List_Func(SendDATA);
        if (Resp?.success == "success") {
            SetHotAuction({
                ...HotAuctionData,
                ...{
                    [tabs]: Resp?.data,
                    "tab": tabs,
                    "filter": filter.auction
                },
            })
            SetHotAuctionstate(false)
        }
        else {
            SetHotAuction({
                ...HotAuctionData,
                ...{
                    [tabs]: Resp?.data,
                    "tab": tabs,
                    "filter": filter.auction

                },
            })
            SetHotAuctionstate(false)
        }


    }

    function LikeList(data) {
        setLikedTokenList(data)
    }
    const ref_banner = useRef(null);
    const scrollbanner = () => {
        ref_banner.current?.scrollIntoView((0, 0), { behavior: 'smooth' });
    };
    // const [showTopBtn, setShowTopBtn] = useState(false);
    // const mytop = document.getElementById("topnftscroll");
    // useEffect(() => {
    //     window.addEventListener("scroll", () => {
    //         if (window.scrollY > 400) {
    //             setShowTopBtn(true);
    //         } else {
    //             setShowTopBtn(false);
    //         }
    //     });
    // }, []);
    // const goToTop = () => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: "smooth",
    //     });
    // };

    const changeindex = (index) => {
        var arr = [...topnfts]

        var selectedindex = arr[index]
        var thirdpos = arr[2]

        arr[2] = selectedindex;
        arr[index] = thirdpos;

setTopnfts(arr)
        // setTabsnft(arr)
        // selectedindex.classList.add('swap-animation');
        // thirdpos.classList.add('swap-animation');
    }

    const FollowUnFollow = async(userProfile)  =>  {
        const id = toast.loading(Follow == "follow" ? "Your Following "+ userProfile?.DisplayName : "Your UnFollowing "+ userProfile?.DisplayName)
        let SendData = {
            MyItemAddr  :   userProfile?.WalletAddress,
            ClickAddr   :   accountAddress,
            From        :   'myitem',
            MyItemCustomUrl    :    userProfile?.CustomUrl,
            ClickCustomUrl    :    payload?.CustomUrl,
        }
        // console.log('senddataaa',SendData,userProfile,payload)
        let Resp    =   await   FollowUnFollowFunc(SendData)
        if(Resp?.success){
            TopCreatorFunc()
            SetFollow(Resp.msg == 'follow' ? 'unfollow' : 'follow')
            toast.update(id,{render:Resp.msg == 'follow' ? 'Your Following '+userProfile?.DisplayName + ' Successfully' :'Your UnFollowing '+userProfile?.DisplayName + ' Successfully',type:'success',isLoading:false,autoClose:1000 })
        }
        else{
            toast.update(id,{render:'Try Again',type:'error',isLoading:false,autoClose:1000,closeButton:true,closeOnClick:true})

        }
    }

    return (
        <>
            <div className="homepage" id="homescreen">
                <Header />
                <div className="modals"><Modals /></div>


                <div className="banner_sec">
                    <div className="tf-container">
                        <div className="row banner_row">
                            <div className="scroll_arrow">
                                <img src={require("../assets/images/down_arrow.png")} alt="down_arrow" onClick={scrollbanner} />
                            </div>
                            <div className="col-lg-6">
                                {/* <p className="mini-head">Discover, Collect</p> */}
                                <h1 className="banner_title">
                                    {cms?.hometop?.description}
                                    {/* Rare NFT’<span>S</span><br /> From Us */}
                                    </h1>
                                <p className="banner_desc">
                                    <div dangerouslySetInnerHTML={{__html:cms?.hometop?.answer}}/>
                                    {/* simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. */}
                                    </p>
                                <div className="btn-slider my-5">
                                    <NavLink to="/explore" className="tf-button style-2">Explore Now </NavLink>
                                </div>
                                <div className="social_icons">
                                    <div className="icons_sec">
                                        <a href={link?.facebook?.link} target="_blank"><i class="fab fa-facebook-f"></i></a>
                                        <a href={link?.twitter?.link} target="_blank"><i aria-hidden="true"><FaXTwitter /></i></a>
                                        <a href={link?.telegram?.link} target="_blank"> <i class="fab fa-telegram-plane" aria-hidden="true"></i></a>
                                        <p>Social Media Links</p>
                                    </div>

                                    <div className="follow_sec">

                                        <div><p className="followcard first">85k</p>
                                            <p className="fl_name">Followers</p>   </div>
                                        <div><p className="followcard second">50k</p>
                                            <p className="fl_name">Reviews</p> </div>

                                    </div>

                                </div>


                            </div>
                            <div className="col-lg-6">
                                <img src={bannerimg} className="img-fluid banner_img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="top_sec py-5" ref={ref_banner}>
                    <div className="tf-container">
                        <div className="row">
                            <h2 className="sec_title">Our Top Creator<span>s</span></h2>
                        </div>
                        <div className="d-block d-lg-none mobileview">
                            <div className="creators_card">
                                <div className="row creators_row mt-5">
                                    <div className="col-12 left_sec">
                                        
     <OwlCarousel className='owl-theme' id="owl_services" loop margin={10} nav {...optionstop}
     startPosition={currentSlide}>
     {
      topcreaterstatus1 == true ?
       <>
      <div className="text-centre">
          <h3>Loading ...</h3>
          {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
          {/* <p>Nothing for ReSale at this time</p> */}
                  <div className="load-more">
                  {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                  </div>
          </div>
      </> 
      : 
     TopCreator?.length > 0 ?
         TopCreator?.map((data, index) => {
             return (
                 <div className={fadeIn ? "item fade_effect" : "item"}>
                     <div className="row">
                         <div className="col-4">
                             <img src={
                                 data?.Profile
                                     ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                     : authordetail
                             } className="img-fluid pf_big" />
                         </div>
                         <div className="col-8 content_sec">
                             <p className="create_name"> <NavLink to={"/my-item/" + data.CustomUrl}> {data?.DisplayName
                                 ? Name_showing(data?.DisplayName)
                                 : address_showing(
                                     data?.WalletAddress
                                 )}</NavLink></p>
                             <p className="create_desc">Top Creator Of the Week</p>
                             <p className="create_price">$-{data.value}</p>
                             <div className="btn-slider">

                                 <p>follow</p>
                             </div>
                             <div className="arrow_sec" onClick={handleNextSlide}>
                                 <img src={rightarrow} className="img-fluid" alt="right_arrow" />
                             </div>
                         </div>
                     </div>
                 </div>
             )
         })
         :
         <NoData />}

 </OwlCarousel>
    
                                       

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-block desktopview">
                            <div className="creators_card">
                                <div className="row creators_row mt-5">
                                    <div className="col-lg-6 left_sec">
                                        
       <OwlCarousel className='owl-theme' id="owl_services" loop margin={10} nav {...optionstop}
       startPosition={currentSlide}>
       {topcreaterstatus2 == true ?
        <>
    <div className="text-centre">
        <h3>Loading ...</h3>
        {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
        {/* <p>Nothing for ReSale at this time</p> */}
                <div className="load-more">
                {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                </div>
        </div>
    </> 
    : 
       TopCreator?.length > 0 ?
           TopCreator?.map((data, index) => {
            console.log("DATAAAAAAAAAAAAAAA",data,data.Following)
               return (
                
                   <div className={fadeIn ? "item fade_effect" : "item"}>
                       <div className="row">
                           <div className="col-lg-6 col-xl-4">
                               <img src={
                                   data?.Profile
                                       ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                       : authordetail
                               } className="img-fluid pf_big" />
                           </div>
                           <div className="col-lg-6 col-xl-8 content_sec">
                               <p className="create_name"> <NavLink to={"/my-item/" + data.CustomUrl}> {data?.DisplayName
                                   ? Name_showing(data?.DisplayName)
                                   : address_showing(
                                       data?.WalletAddress
                                   )}</NavLink></p>
                               <p className="create_desc">Top Creator Of the Week</p>
                               <p className="create_price">$-{data.value}</p>
                               <div className="btn-slider">
                                   {
                                       console.log(data._id, localStorage.getItem("accountInfo"), "enna seiyaa", data._id !== localStorage.getItem("accountInfo"), following?.includes(data.WalletAddress), following)
                                   }
                                   {data.WalletAddress !== localStorage.getItem("accountInfo") &&



<Button className="tf-button active btn btn-primary" onClick={()=>FollowUnFollow(data)}>{data.Follower?.includes(localStorage.getItem("accountInfo")) ? <>unfollow</> : <>follow</>}</Button>
                                    //    <NavLink to={"/profile/" + data.CustomUrl}><Button className="button-follow style-2">Follow</Button></NavLink>

                                   }
                                   {/* {data.CustomUrl !== localStorage.getItem("accountInfo") ? <p className="button-follow style-2">{Follow}</p> : ""}  */}

                                   {/* <NavLink to={"/my-item/"+data.CustomUrl} className="button-follow style-2">follow </NavLink> */}
                               </div>
                           </div>
                       </div>
                   </div>
               )
           })
           :
           <NoData />}

   </OwlCarousel>
    
                                     

                                    </div>
                                    <div className="col-lg-6 rightsec">
                                        {TopCreator?.length > 0 ?
                                            <div>
                                                <div className="left_circle"> </div>
                                                <div className="left_line"> </div>
                                            </div> :""
                                        }
                                        <OwlCarousel className='owl-theme' id="owl_services" loop margin={10} nav {...optionstop1}
                                            startPosition={currentSlide1}>
                                            {TopCreator?.length > 0 ?
                                                TopCreator?.map((data, index) => {
                                                    return (
                                                        <div className={fadeIn ? "item fade_effect" : "item"}>

                                                            <div className="top_imf_sec">
                                                                <img src={
                                                                    data?.Profile
                                                                        ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                                                        : creators_img1
                                                                } className="img-fluid img_top" />

                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <NoData />}

                                        </OwlCarousel>
                                        {TopCreator?.length > 0 ?
                                        <div>
                                        <div className="arrow_sec" onClick={handleNextSlide}>
                                            <img src={rightarrow} className="img-fluid" alt="right_arrow" />
                                        </div>
                                        </div>:""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content_sec py-5">
                    <div className="tf-container">
                        <div className="row align_sec">
                            <div className="col-lg-6">
                                <h2 className="sec_title">
                                    {cms?.homemiddle?.description}
                                    {/* Make Your First <br />Artwork NFT’S Now<span>!</span> */}
                                    </h2>
                                {/* <p className="sec_desc mt-4">simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                                <p className="sec_desc mt-4">
                                    <div dangerouslySetInnerHTML={{__html:cms?.homemiddle?.answer}}/>
                                    {/* simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. */}
                                    </p>
                                <div className="btn-slider my-5">
                                    <NavLink to="/explore" className="tf-button style-2">Learn Now </NavLink>
                                </div>
                            </div>
                            <div className="col-lg-6">
                            {/* <img src={`${config.IMG_URL}/cmsimg/${cms?.homemiddle?.img}`} className="img-fluid" /> */}
                                <img src={content_sec} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hotsec">
                    <div className="tf-container">
                        <div className="row">
                            <h2 className="sec_title">Hot Auction<span>s</span></h2>
                        </div>
                        <div className="row">
                            <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
                                {hotcard?.map((e, i) => {
                                    if (i == 0) {
                                        return (
                                            <>
                                                <div class='item'>
                                                    <div className="row mt-5">
                                                        {hotactionstate== true ? <>
    <div className="text-centre">
        <h3 className="load_datas">Loading...</h3>
        {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
        {/* <p>Nothing for ReSale at this time</p> */}
                <div className="load-more">
                {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                </div>
        </div>
    </>: 
     HotAuctionData[HotAuctionData.tab]?.length > 0 ? HotAuctionData[HotAuctionData.tab]?.slice(0, 8).map((item, index) => (
                                                            <div className="col-md-6 col-xl-3">
                                                                <Card
                                                                    product={item}
                                                                    index={index}
                                                                    LikeList={LikeList}
                                                                    LikedTokenList={LikedTokenList}
                                                                    setLikedTokenList={setLikedTokenList}
                                                                    LikeForwardRef={LikeForwardRef}
                                                                />
                                                            </div>
                                                        ))
                                                            :
                                                            <NoData />
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                    if (i == 1) {
                                        return (
                                            <>
                                                <div class='item'>
                                                    <div className="row mt-5">
                                                        {HotAuctionData[HotAuctionData.tab]?.length > 0 ? HotAuctionData[HotAuctionData.tab]?.slice(0, 8).map((item, index) => (
                                                            <div className="col-md-6 col-xl-3">
                                                                <Card
                                                                    product={item}
                                                                    index={index}
                                                                    LikeList={LikeList}
                                                                    LikedTokenList={LikedTokenList}
                                                                    setLikedTokenList={setLikedTokenList}
                                                                    LikeForwardRef={LikeForwardRef}
                                                                />
                                                            </div>
                                                        ))
                                                            :
                                                            <NoData />
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                    if (i == 2) {
                                        return (
                                            <>
                                                <div class='item'>
                                                    <div className="row mt-5">
                                                        {HotAuctionData[HotAuctionData.tab]?.length > 0 ? HotAuctionData[HotAuctionData.tab]?.slice(0, 8).map((item, index) => (
                                                            <div className="col-md-6 col-xl-3">
                                                                <Card
                                                                    product={item}
                                                                    index={index}
                                                                    LikeList={LikeList}
                                                                    LikedTokenList={LikedTokenList}
                                                                    setLikedTokenList={setLikedTokenList}
                                                                    LikeForwardRef={LikeForwardRef}
                                                                />
                                                            </div>
                                                        ))
                                                            :
                                                            <NoData />
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                })}


                            </OwlCarousel>

                        </div>
                    </div>
                </div>



                <div className="topnft_sec py-5">
                    <div className="tf-container">
                        <div className="row mb-4">
                            <h2 className="sec_title">Top NFT<span>s</span></h2>
                        </div>
                        {topnftstatus == true ? <>
    <div className="text-centre">
        <h3 className="load_datas">Loading...</h3>
        {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
        {/* <p>Nothing for ReSale at this time</p> */}
                <div className="load-more">
                {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                </div>
        </div>
    </>    
    :
    <>
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col xl={3}>
                                    <div className="scrolldiv_nft">
                                        <Nav variant="pills" className="flex-column nftnavscroll" id="topnftscroll" data-current="0" centered>
                                            {topnfts?.map((e, index) => (
                                                <Nav.Item  >
                                                    {console.log("e?.user?.Profile",e?.user?.Profile)}
                                                    <Nav.Link onClick={() => changeindex(index)} eventKey={e.indexkey}>
                                                        <img src={ isEmpty(e?.user?.Profile) ?  require('../assets/images/nfttab.png') :isEmpty(e?.user?.Profil) ? `${config.IMG_URL}/user/${e.user.WalletAddress}/profile/${e.user.Profile}` : e.user.tab } className="img-fluid" width={50} />
                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}
                                           
                                        </Nav>
                                    </div>
                                </Col>
                                <Col xl={9}>
                                    <Tab.Content>
                                    {topnfts?.map((e, index) => (
                                        <Tab.Pane eventKey={e.indexkey}>

                                            <div className="top_nft_card">
                                                <div className="row align_sec">
                                                    <div className="col-lg-8">
                                                        <p className="title">{  e.user.DisplayName !== "" ? Name_showing(e.user.DisplayName) : address_showing(e.user.CustomUrl)  }</p>
                                                        <p className="desc">{e?.user?.Bio ? e.user.bio != "" ?  Name_showing(e?.user?.bio) :"": <>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</>  }</p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        {console.log('fjdsljfglsdkg',e,e.tokens)}
                                                        {/* <OwlCarousel className='owl-theme owl-carousel-vertical' loop margin={10} nav {...options1}> */}
                                                            <div className="scroll_topnft">
                                                            { (e?.tokens && e?.tokens?.length>0) ?
                                                            <>
                                                             {e.tokens && e.tokens?.map((item,index)=>{
                                                                console.log("TKKDKSDFSDFSFDSDFSDF",item.CompressedFile,e.indexkey)
                                                           return(
                                                                <div class='item'>
                                                                   {(item.CompressedFile.split('.')[1]== "mp4" || 
                                                                   item.CompressedFile.split('.')[1]=="webm" || 
                                                                   item.CompressedFile.split('.')[1]=="WEBM") ?
                                                                <video src={!isEmpty(item?.CompressedFile)  ?   `${config.IMG_URL}/nft/${e.user.WalletAddress}/Compressed/NFT/${item.CompressedFile}` : e.nfttopcard }className="img-fluid nft_carousel" alt="nft"/> 
                                                                :
                                                                <img src={ !isEmpty(item?.CompressedFile)  ?   `${config.IMG_URL}/nft/${e.user.WalletAddress}/Compressed/NFT/${item.CompressedFile}` : e.nfttopcard } className="img-fluid nft_carousel" alt="nft" />
}
                                                            </div>
                                                           )})}
                                                           </>
                                                            :
                                                            <>
                                                            <div class='item'>
                                                            <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                        </div>
                                                        <div class='item'>
                                                            <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                        </div>
                                                        <div class='item'>
                                                            <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                        </div>
                                                        </>
                                                        //     :
                                                        //     <>
                                                        //     <div class='item'>
                                                        //     <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                        // </div>
                                                        // <div class='item'>
                                                        //     <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                        // </div>
                                                        // <div class='item'>
                                                        //     <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                        // </div>
                                                        // </>
                                                            }
                                                           
                                                        {/* </OwlCarousel> */}
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </Tab.Pane>
                                         ))}
                                        {/* <Tab.Pane eventKey="second">
                                            <div className="top_nft_card">
                                                <div className="row align_sec">
                                                    <div className="col-lg-8">
                                                        <p className="title">Prince</p>
                                                        <p className="desc">simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.simply dummy text of the printing and typesetting industry. </p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <OwlCarousel className='owl-theme' loop margin={10} nav {...options1}>
                                                            <div class='item'>
                                                                <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                            </div>
                                                            <div class='item'>
                                                                <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                            </div>
                                                            <div class='item'>
                                                                <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                            </div>
                                                        </OwlCarousel>

                                                    </div>

                                                </div>

                                            </div></Tab.Pane>
                                        <Tab.Pane eventKey="three">
                                            <div className="top_nft_card">
                                                <div className="row align_sec">
                                                    <div className="col-lg-8">
                                                        <p className="title">Princesss</p>
                                                        <p className="desc">simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.simply dummy text of the printing and typesetting industry. </p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <OwlCarousel className='owl-theme' loop margin={10} nav {...options1}>
                                                            <div class='item'>
                                                                <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                            </div>
                                                            <div class='item'>
                                                                <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                            </div>
                                                            <div class='item'>
                                                                <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                            </div>
                                                        </OwlCarousel>

                                                    </div>

                                                </div>

                                            </div></Tab.Pane>
                                        <Tab.Pane eventKey="four">
                                            <div className="top_nft_card">
                                                <div className="row align_sec">
                                                    <div className="col-lg-8">
                                                        <p className="title">Prince111</p>
                                                        <p className="desc">simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.simply dummy text of the printing and typesetting industry. </p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <OwlCarousel className='owl-theme' loop margin={10} nav {...options1}>
                                                            <div class='item'>
                                                                <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                            </div>
                                                            <div class='item'>
                                                                <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                            </div>
                                                            <div class='item'>
                                                                <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                            </div>
                                                        </OwlCarousel>

                                                    </div>

                                                </div>

                                            </div></Tab.Pane>
                                        <Tab.Pane eventKey="five">
                                            <div className="top_nft_card">
                                                <div className="row align_sec">
                                                    <div className="col-lg-8">
                                                        <p className="title">Prince333</p>
                                                        <p className="desc">simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.simply dummy text of the printing and typesetting industry. </p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <OwlCarousel className='owl-theme' loop margin={10} nav {...options1}>
                                                            <div class='item'>
                                                                <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                            </div>
                                                            <div class='item'>
                                                                <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                            </div>
                                                            <div class='item'>
                                                                <img src={nfttopcard} className="img-fluid" alt="nft" />
                                                            </div>
                                                        </OwlCarousel>

                                                    </div>

                                                </div>

                                            </div></Tab.Pane> */}
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                        </>
    
    }
                      

                    </div>
                </div>
                {/* <div className="topnft_sec py-5">
            <div className="tf-container">
                <div className="row">
                <h2 className="sec_title">Top NFT’<span>s</span></h2>
                </div>
            
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col xl={3}>
        <Nav variant="pills" className="flex-column nftnavscroll" id="topnftscroll" centered>
            {topnfts && topnfts.map((data,index)=>(
                <>
                 <Nav.Item>
              <Nav.Link eventKey={`${index}`}>
              <img  src={
                                        data?.NFTOrginalImageIpfs
                                            ? `https://ipfs.io/ipfs/${data.NFTOrginalImageIpfs}`
                                            : authordetail
                                        }  width={50} className="img-fluid"/>
               
              </Nav.Link>
              </Nav.Item>
                </>
            ))}
            </Nav>
    
        </Col>
        <Col xl={9}>
        <Tab.Content>
            {topnfts && topnfts.map((data,index)=>(
                <>
 <Tab.Pane eventKey={`${index}`}>
                      
                      <div className="top_nft_card">
                        <div className="row align_sec">
                            <div className="col-lg-8">
                        <p className="title">{data.NFTName}</p>
                        <p className="desc">{data.NFTDescription} </p>
                        </div>
                        <div className="col-lg-4">
                       
    <div class='item'>
    <img  src={
                                        data?.NFTOrginalImageIpfs
                                            ? `https://ipfs.io/ipfs/${data.NFTOrginalImageIpfs}`
                                            : authordetail
                                        }   className="img-fluid"/>
   
    </div>
  
                       
                        </div>

                        </div>

                      </div>

            </Tab.Pane>
                </>
            ))}
             </Tab.Content>
      
        </Col>
      </Row>
    </Tab.Container>
              
            </div>
        </div> */}
                <div className="recentsec">
                    <div className="tf-container">
                        <div className="row">
                            <h2 className="sec_title">Recently Added NFT<span>s</span></h2>
                        </div>
                        <div className="row">
                            <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
                                {hotcard?.map((e, i) => {

                                    if (i == 0) {
                                        return (
                                            <>
                                                <div class='item'>
                                                    <div className="row mt-5">
                                                        {hotsalestate == true ? <>
    <div className="text-centre">
        <h3 className="load_datas">Loading...</h3>
        {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
        {/* <p>Nothing for ReSale at this time</p> */}
                <div className="load-more">
                {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                </div>
        </div>
    </>:  nfts ? nfts?.slice(0, 8)?.map((item, index) => (
                                                            <div className="col-md-6 col-xl-3">
                                                                <Card
                                                                    product={item}
                                                                    index={index}
                                                                    LikeList={LikeList}
                                                                    LikedTokenList={LikedTokenList}
                                                                    setLikedTokenList={setLikedTokenList}
                                                                    LikeForwardRef={LikeForwardRef}
                                                                />
                                                            </div>
                                                        ))
                                                            :
                                                            <NoData />
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    if (i == 1) {
                                        return (
                                            <>
                                                <div class='item'>
                                                    <div className="row mt-5">
                                                        {nfts ? nfts?.slice(9, 16)?.map((item, index) => (
                                                            <div className="col-md-6 col-xl-3">
                                                                <Card
                                                                    product={item}
                                                                    index={index}
                                                                    LikeList={LikeList}
                                                                    LikedTokenList={LikedTokenList}
                                                                    setLikedTokenList={setLikedTokenList}
                                                                    LikeForwardRef={LikeForwardRef}
                                                                />
                                                            </div>
                                                        ))
                                                            :
                                                            <NoData />
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    if (i == 2) {
                                        return (
                                            <>
                                                <div class='item'>
                                                    <div className="row mt-5">
                                                        {nfts ? nfts?.slice(17, 24).map((item, index) => (
                                                            <div className="col-md-6 col-xl-3">
                                                                <Card
                                                                    product={item}
                                                                    index={index}
                                                                    LikeList={LikeList}
                                                                    LikedTokenList={LikedTokenList}
                                                                    setLikedTokenList={setLikedTokenList}
                                                                    LikeForwardRef={LikeForwardRef}
                                                                />
                                                            </div>
                                                        ))
                                                            :
                                                            <NoData />
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }



                                })}


                            </OwlCarousel>

                        </div>
                    </div>
                </div>



                <Footer />
            </div>
        </>
    )
}