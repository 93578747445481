import React,{useEffect,useState} from "react";
import { Box, Button, Fade, Grid,  } from "@mui/material";
import Modal from 'react-bootstrap/Modal';
import { getnfttaglist } from "../../actions/axioss/nft.axios";

import config from '../config/config'
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share';

export function Share({title,url,from,closePop,quote}){
    const [tag,setTag]=useState()
    useEffect(()=>{
        getnfttaglists()
    },[])

    const getnfttaglists=async()=>{
        var resp=await getnfttaglist();
        console.log("nft tage list",resp.data)
        var hastag=resp.data.filter(item=> item.details == "Hashtag")
        console.log(hastag,"hasxxxh");
        setTag(hastag[0]?.nfttag)
    }
    console.log("urlklllklkurl",tag);
    return(
    <Modal
    // aria-labelledby="transition-modal_title"
    // aria-describedby="transition-modal-description"
    // open={true}
    // className={"customized_modal modal fade show"}
    size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true}
  >
    <Modal.Header>
      <button type="button" class="btn-close" aria-label="Close" onClick={closePop}></button>
      <Modal.Title id="contained-modal-title-vcenter">
        Share
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className='placeabid'>

                            <div className="modal_body">
                                <Grid container>
                                    <Grid item sm={4} xs={6}>
                                        <div className="text-center icon_div">
    
                                            <TwitterShareButton
                                                title={title}
                                                url={url+tag}
                                              
                                            >
                                                <i className="fab fa-twitter"></i>
                                                <p>Twitter</p>
    
    
                                            </TwitterShareButton>
    
                                        </div>
                                    </Grid>
                                    
                                    <Grid item sm={4} xs={6}>
                                        <div className="text-center icon_div">
                                            <FacebookShareButton
                                                quote={quote}
                                                // title={`${productdetail.TokenId}  NFT`}
                                                url={url+tag}
                                            
                                            //     via={`${config.FRONT_URL}`}
                                            >
                                                <i className="fab fa-facebook-f"></i>
                                                <p>Facebook</p>
                                            </FacebookShareButton>
    
                                        </div>
                                    </Grid>
                                    
                                    <Grid item sm={4} xs={6}>
                                        <div className="text-center icon_div">
    
                                            <TelegramShareButton
                                                title={title}
                                                url={url+tag}
                                            
                                            >
    
                                                <i className="fab fa-telegram-plane"></i>
                                                <p>Telegram</p>
    
                                            </TelegramShareButton>
    
                                        </div>
                                    </Grid>
                                    
                                    <Grid item sm={4} xs={6}>
                                        <div className="text-center icon_div">
                                            <WhatsappShareButton
                                               title={title}
                                               url={url+tag}
                                             >
                                                <i className="fab fa-whatsapp"></i>
                                                <p>Whatsapp</p>
                                            </WhatsappShareButton>
    
                                        </div>
                                    </Grid>
                                    
                                    {/* <Grid item sm={4} xs={6}>
                                        <div className="text-center icon_div">
    
                                           <WhatsappShareButton
                                               title={title}
                                               url={url}
                                             > 
                                                <i className="fab fa-instagram"></i>
                                                <p>Instagram</p>
                                             </WhatsappShareButton>
                                        </div>
                                    </Grid>  */}
                                   {/* <Grid item sm={4} xs={6}>
                                        <div className="text-center icon_div" >
                                            <button
                                               title={title}
    
                                               url={url}
                                             > 
                                                <i className="fab fa-tiktok"></i>
                                                <p>TikTok</p>
                                            {/* </WhatsappShareButton> 
    
                                        </div>
                                    </Grid>  */}
                                </Grid>
                                </div>
    </div>
    </Modal.Body>
            </Modal>
    )

}