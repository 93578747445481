import React,{useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Image from "../assets/images/banner.png";
import Select from "react-select";
import "../assets/css/modal.css";
export default function Modals(){
    const[show1,setShow1]=useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const[show2,setShow2]=useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const[show3,setShow3]=useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    const[show4,setShow4]=useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);
    const[show5,setShow5]=useState(false);
    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);
    const[show6,setShow6]=useState(false);
    const handleClose6 = () => setShow6(false);
    const handleShow6 = () => setShow6(true);
    const[show7,setShow7]=useState(false);
    const handleClose7 = () => setShow7(false);
    const handleShow7 = () => setShow7(true);
    const[show8,setShow8]=useState(false);
    const handleClose8 = () => setShow8(false);
    const handleShow8 = () => setShow8(true);
    const[show9,setShow9]=useState(false);
    const handleClose9 = () => setShow9(false);
    const handleShow9 = () => setShow9(true);
    const[show10,setShow10]=useState(false);
    const handleClose10 = () => setShow10(false);
    const handleShow10 = () => setShow10(true);
    const[show11,setShow11]=useState(false);
    const handleClose11 = () => setShow11(false);
    const handleShow11 = () => setShow11(true);
    const[theme,setTheme]=useState(false);
    const [proceedtopayment, setProceedtopayment] = React.useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
      const options = [
        { value: 'value1', label: 'value1' },
        { value: 'value2', label: 'value2' },
        { value: 'value3', label: 'value3' },
      ]; 


    return(
        <>
{/* Transfer */}
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show1}
      className="transfer_token"
    >
      <Modal.Header>
      <button type="button" class="btn-close" aria-label="Close" onClick={() => handleClose1()}></button>
        <Modal.Title id="contained-modal-title-vcenter">
         Transfer Token
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
     <div className='approvetransaction centeralign'>
        <div className='item_imgs'>
            <img src={Image} alt="images" className='img-fluid'/>
        </div>
        <div className="content my-3">
        <p className="my-4">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution </p>
        <input className="" type="text" placeholder="Enter the Token E.G: 0x0hf"  autoComplete="off" />
        </div>
        <div className='connecwalletbtns my-4'>
            <button className="connectwallet tf-button active btn btn-primary secondary">Start</button>
            </div> 
     </div>
      </Modal.Body>
    </Modal>

{/* share */}
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show2}
    >
      <Modal.Header>
      <button type="button" class="btn-close" aria-label="Close" onClick={handleClose2}></button>
        <Modal.Title id="contained-modal-title-vcenter">
         Share this NFT
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <div className='walletBody d-flex justify-content-between align-items-center'>
       <div className='wallet'>
       <i className="fab fa-twitter"></i>
       <p>Twitter</p>
            </div>
            <div className='wallet'>
        <i className="fab fa-whatsapp"></i>
            <p>Whatsapp</p>
            </div>
            <div className='wallet'>
        <i className="fab fa-facebook"></i>
            <p>Facebook</p>
            </div>
            <div className='wallet'>
        <i className="fab fa-telegram"></i>
            <p>Telegram</p>
            </div>
       </div>
      </Modal.Body>
    </Modal>

{/* Report */}
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show3}
    >
      <Modal.Header>
      <button type="button" class="btn-close" aria-label="Close" onClick={handleClose3}></button>
        <Modal.Title id="contained-modal-title-vcenter">
      Report this NFT ?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
     <div className='approvetransaction mb-5'>
        <p className='mt-4'>It is a long established fact that a reader will be distracted by the readable content</p>
        <p className="pt-5 pb-2 text-left">Message</p>
        <textarea rows="4" cols="42" placeholder="Tell us some details" />
        </div>
        <div className='connecwalletbtns d-flex justify-content-between mt-3'>
            <button className="connectwallet tf-button active btn btn-primary primary" onClick={handleClose3}>Cancel Bid</button>
            <button className="cancelpayment tf-button active btn btn-primary secondary" onClick={handleClose3}>Cancel</button>
            </div>
      </Modal.Body> 
    </Modal>

{/* Put on sale */}
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show4}
      className="putonsale"
    >
      <Modal.Header>
      <button type="button" class="btn-close" aria-label="Close" onClick={handleClose4}></button>
        <Modal.Title id="contained-modal-title-vcenter">
     Put On Sale
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
     <div className='approvetransaction'>
     <div className='item_imgs'>
            <img src={Image} alt="imgae" className='img-fluid'/>
        </div>
        <p className='putonsalede mt-4'>It is a long established <span>fact</span> that a reader will be<span>0x02d54f...</span> distracted by the readable content</p>
        <div className="putonsale">
                                    <div>
                                        <h2 className='putonsasle'>Put on Sale</h2>
                                        <p>You’ll receive bids on this item</p>
                                    </div>
                                    {/* <div className="toggleswitch">
                                    <button className="switch toggle_custom" onClick={() =>setShow4(!show4)}><input type="checkbox" id="putonsale" name="putonsale"  /><span  className={show4?"slider":"overleft"} ></span></button>
                                    </div> */}
                                </div>   
                                <div  className={show4?"putonsalefixed":"tabbable-line"}>
					<ul className="nav nav-tabs ">
						<li className="active">
							<a href="#fixedprice" data-toggle="tab">
                            <div className="fixedprice">
                                        <i className="fal fa-dollar-sign"></i>
                                        <p>Fixed Price</p>
                                    </div></a>
						</li>
						<li>
							<a href="#timeauction" data-toggle="tab">
                                <div className="fixedprice">
                                        <i className="fas fa-clock"></i>
                                        <p>Timed Auction</p>
                                    </div></a>
						</li>
					</ul>
					<div class="tab-content mt-5">
						<div class="tab-pane active" id="fixedprice">
                            <div className="fixedpricedropdown mb-4">
                            <div className="formgroupsec text-left" >
                                        <label className="text-left mb-3">Enter your Price</label>
                                       <div className="fixedpricedropdownflex">
                                       <input className="" type="text" placeholder="0"  autoComplete="off" />
                                       <div>
                                       </div>
                                       <div className="mt-5">
                                       <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            classNamePrefix="react-select"
                                        />
                                       </div>
                                       </div>
                                       <p className="pt-4">Service fee1% You will receive 0XDC $( 0)</p>
                                       </div>

                            </div>
						</div>
						<div class="tab-pane" id="timeauction">
                        <div className="minimumbiddropdown mb-4">
                            <div className="formgroupsec" >
                                        <label>Minimum bid</label>
                                       <div className="fixedpricedropdownflex">
                                       <div>
                                       <input className="" type="text" placeholder="Enter minimum bid"/>
                                       </div>
                                       <div>
                                      <p>BLKZ</p>
                                       </div>
                                       </div>
                                       <p>Bids below this amount won't be allowed. If you not enter any amount we will consider as 0</p>
                                       </div>
                                      

                            </div>

						</div>
						
          
					</div>
		
                                </div>
                <div className='servicefee'>
                        <p>Service Fee 1% Undefined</p>
                </div>
                <div className='servicefee'>
                        <p>You will Get 0 Undefined</p>
                </div>  
        <div className='connecwalletbtns mt-5 d-flex justify-content-between'>
            <button className="connectwallet primary tf-button active btn btn-primary">Start</button>
            <button className="cancelpayment tf-button active btn btn-primary secondary" onClick={handleClose4}>Cancel</button>
            </div> 
     </div>
      </Modal.Body>
    </Modal>

    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show5}
    >
      <Modal.Header>
      <button type="button" class="btn-close" aria-label="Close" onClick={handleClose5}></button>
        <Modal.Title id="contained-modal-title-vcenter">
         Follow Steps
        </Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
     <div className='approvetransaction'>
        <h2>Approve the Transactions</h2>
        <div className='connectwallet'>
          <button className="secondary tf-button active btn btn-primary">Approve
          </button>
          </div>
     </div>
     <div className='approvetransaction mb-4'>
        <h2>Send Transactions with your Wallet</h2>
        <div className='connectwallet'>
          <button className="secondary tf-button active btn btn-primary">Purchase
          </button>
          </div>
     </div>
      </Modal.Body>
    </Modal>

{/* Accept */}
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show6}
    >
      <Modal.Header>
      <button type="button" class="btn-close" aria-label="Close" onClick={handleClose6}></button>
        <Modal.Title id="contained-modal-title-vcenter">
       Accept Bid
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='acceptbid'>
            <p className="pb-3">You are to acceptbid for <span>DEVLOP</span> from <span>0x792dfc5f4ggsd5 0x792dfc5f4ggsd5</span></p>
            <h6 className="pb-4">0.1 BLKZ for Edition(s)</h6>
            <p className="text-left pb-4">Quantity</p>
        <input className="" type="text" placeholder="Enter your quantity"  autoComplete="off" />
            <div className='servicefee mt-4 d-flex justify-content-between'>
                        <p><b>Service Fee in %:</b></p>
                        <p> 1%</p>
                </div>
                <div className='servicefee mt-3 d-flex justify-content-between'>
                        <p><b>Royalty fee in %:</b></p>
                        <p> 10%</p>
                </div>
                <div className='servicefee mt-3 d-flex justify-content-between'>
                        <p><b>You will get :</b></p>
                        <p>0 BLKZ</p>
                </div> 
                <div className='connecwalletbtns d-flex justify-content-between mt-4'>
            <button className="connectwallet primary tf-button active btn btn-primary">Accept Bid</button>
            <button className="cancelpayment secondary tf-button active btn btn-primary" onClick={handleClose6}>Cancel</button>
            </div>    
        </div>
      </Modal.Body>
    </Modal>

{/* Bid */}
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show7}
    >
      <Modal.Header>
      <button type="button" class="btn-close" aria-label="Close" onClick={handleClose7}></button>
        <Modal.Title id="contained-modal-title-vcenter">
       Place a Bid
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='placeabid'>
        <p>You are about to place a bid for</p>
            <p>Name Network Trade Finance bronze <b>#1</b> by <b>0x5d1g5f4g5f4</b></p>
            <div className="minimumbiddropdown mb-4">
                            <div className="formgroupsec" >
                                        <p className="text-left mt-4">Enter your bid</p>
                                       <div className="fixedpricedropdownflex">
                                       <div>
                                       <input className="" type="text" placeholder="Enter minimum bid"  autoComplete="off" />
                                       </div>
                                       <div>
                                      <p><b>BLKZ</b></p>
                                       </div>
                                       </div>
                                       <p className="pt-3">Bids below this amount won't be allowed. If you not enter any amount we will consider as 0</p>
                                       </div>
                                      

                            </div>
            <p className="text-left pb-2">Enter  <span>(40 available)</span></p>
        <input className="" type="text" placeholder="1"  autoComplete="off" />
            <div className="servicefee mt-5 d-flex justify-content-between">
                        <p><b>Your Balance:</b> </p>
                        <p>9.325910 Name</p>
                </div>
                <div className="servicefee  d-flex justify-content-between">
                        <p><b>Your Bidding balance:</b></p>
                        <p>1.325910 BLKZ</p>
                </div>
                <div className="servicefee  d-flex justify-content-between">
                        <p><b>Service fee:</b></p>
                        <p>1% BLKZ</p>
                </div> 
                <div className="servicefee  d-flex justify-content-between">
                        <p><b>You will Pay:</b></p>
                        <p>1.01 T</p>
                </div> 
                <div className='connecwalletbtns mt-4'>
            <button className="connectwallet tf-button active btn btn-primary secondary">Place a Bid</button>
            </div>    
        </div>
      </Modal.Body>
    </Modal>

{/* mint */}
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show8}
    >
      <Modal.Header>
      <button type="button" class="btn-close" aria-label="Close" onClick={handleClose8}></button>
        <Modal.Title id="contained-modal-title-vcenter">
         Follow Steps
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
   <div className='ifpsmetadata'>
        <div className='ifpsmetadatasec  d-flex justify-content-start align-items-center'>
        <i className="fa fa-check"></i>
        <div className="text-left ml-5">
            <h6>IPFS Metadata</h6>
            <p>Generates IPFS Metadata for your NFT</p>
        </div>
        </div>
        <button className="connectwallet tf-button active btn btn-primary primary my-3" onClick={()=> setTheme(!theme)}>Done</button>
   </div>
   <div className="ifpsmetadata mt-4">
        <div className="ifpsmetadatasec d-flex justify-content-start align-items-center">
        <i className="fa fa-check"></i>
        <div className="text-left ml-5">
            <h6>Upload files & Mint Token</h6>
            <p>Call contract method</p>
        </div>
        </div>
        <button className="connectwallet tf-button active btn btn-primary  primary my-3" onClick={()=> setTheme(!theme)}>Done</button>
   </div>
   <div className='ifpsmetadata mt-4'>
        <div className='ifpsmetadatasec d-flex justify-content-start align-items-center'>
        <i className="fa fa-check"></i>
        <div className="text-left ml-5">
            <h6>Sign Sell Order</h6>
            <p>Sign sell order using tour wallet</p>
        </div>
        </div>
        <button className="connectwallet tf-button active btn btn-primary primary my-3" onClick={()=> setTheme(!theme)}>Done</button>
   </div> 
   <div className='ifpsmetadata mt-4'>
        <div className='ifpsmetadatasec d-flex justify-content-start align-items-center'>
        <i className="fa fa-check"></i>
        <div className="text-left ml-5">
            <h6>Sign lock order</h6>
            <p>Sign lock order using tour wallet</p>
        </div>
        </div>
        <button className="connectwallet tf-button active btn btn-primary primary my-3" onClick={()=> setTheme(!theme)}>Done</button>
   </div> 
      </Modal.Body>
    </Modal>

{/* cancel Bid */}
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show9}
    >
      <Modal.Header>
      <button type="button" class="btn-close" aria-label="Close" onClick={handleClose9}></button>
        <Modal.Title id="contained-modal-title-vcenter">
        Cancel Bid
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
     <div className='approvetransaction'>
        <p className='mt-4'>It is a long established fact that a reader will be distracted by the readable content</p>
        <p className='nstlx'><b>0.01 BLKZ for 1 Edition</b></p>
        <div className='connecwalletbtns d-flex justify-content-between mt-5'>
            <button className="connectwallet tf-button active btn btn-primary primary">Cancel Bid</button>
            <button className="cancelpayment tf-button active btn btn-primary secondary" onClick={handleClose9}>Cancel</button>
            </div> 
     </div>
      </Modal.Body>
    </Modal>

    {/* Checkout */}
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show10}
      className={proceedtopayment?"fixedmodel":""}
    >
      <Modal.Header>
      <button type="button" class="btn-close" aria-label="Close" onClick={handleClose10}></button>
        <Modal.Title id="contained-modal-title-vcenter">
         Checkout
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className='buynowsec'>
        <div className='buynowseller d-flex justify-content-between'><p><b>Seller:</b></p><p>0xd0f12df5df4...</p></div>
        <div className='buynowseller d-flex justify-content-between'><p><b>Buyer:</b></p><p>0xd0f12df5df4...</p></div>
      </div>
      <div className='buynowbalance mt-5'>
      <div className='buynowseller  d-flex justify-content-between'><p><b>Your Balance:</b></p><p>0.42 ETH</p></div>
      <div className='buynowseller d-flex justify-content-between'><p><b>Your Token Balance:</b></p><p>99999.8 T CAKE</p></div>
      <div className='buynowseller  d-flex justify-content-between'><p><b>Price:</b></p><p>1 CAKE</p></div>
      <div className='buynowseller  d-flex justify-content-between'><p><b>Service Fee:</b></p><p>2% CAKE</p></div>
      <div className='buynowseller d-flex justify-content-between'><p><b>Royalty Fee:</b></p><p>10% CAKE</p></div>
      <div className='buynowseller d-flex justify-content-between'><p><b>You Will Pay:</b></p><p>1.02 CAKE</p></div>
      </div>
           <div className='connecwalletbtns d-flex justify-content-between mt-5'>
            <button className={proceedtopayment?"connectwallet primary tf-button active btn btn-primary":"connectwallet hides primary tf-button active btn btn-primary"} tabIndex="-1" aria-disabled="true" onClick={() => setProceedtopayment(true)}>Proceed</button>
            <button className='cancelpayment secondary tf-button active btn btn-primary' onClick={handleClose10}>Cancel</button>
            </div> 
      </Modal.Body>
    </Modal>

{/* Burn */}
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show11}
    >
      <Modal.Header>
      <button type="button" class="btn-close" aria-label="Close" onClick={handleClose11}></button>
        <Modal.Title id="contained-modal-title-vcenter">
         Burn Token
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
     <div className='approvetransaction'>
        <h2>1 Tokens Available</h2>
        <p className="py-5">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution </p>
        <input className="" type="text" placeholder="Enter Name"  autoComplete="off" />
        <p>QTY Can't be Empty</p>
      </div>
        <div className='connecwalletbtns mt-4'>
            <button className='cancelpayment tf-button active btn btn-primary secondary' onClick={handleClose11}>Cancel</button>
            </div> 
     
      </Modal.Body>
    </Modal>

        </>
        
    )
}