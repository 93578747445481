import React, { useEffect,useState,useRef } from "react";
// import { Link } from "react-router-dom";
import Activity from "./Activity";
import History from "./History";
import Following from "./Following";
import Favourites from "./Favourites";
import EditProfile from "./EditProfile";
import Inventory from "../assets/images/activity-gold.png";
import HistoryCon from "../assets/images/followers-gold.png";
import FollowingCon from "../assets/images/following-gold.png";
import Favcon from "../assets/images/favorites-gold.png";
import Edit from "../assets/images/account-gold.png";
import Inventory1 from "../assets/images/account-white.png";
import HistoryCon1 from "../assets/images/followers-white.png";
import FollowingCon1 from "../assets/images/following-white.png";
import Favcon1 from "../assets/images/favorites-white.png";
import Edit1 from "../assets/images/account-white.png";
import { NavLink, useParams , useNavigate,useLocation} from "react-router-dom";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import action_config from './config/config';
import { address_showing,Name_showing } from "../actions/common";
import { FollowUnFollowFunc, Token_MyList_Func, userRegister } from "../actions/axioss/user.axios";
import {toast} from 'react-toastify'
import Button from "react-bootstrap/esm/Button";
import Card from "./Card";
import NoData from "./seperatemodals/nodata";
import config from "./config/config"
import ImportCollection from './seperatemodals/importcollection';
import item from '../assets/images/myitem-gold.png'
import Myitem from "./my_item";

export default function Board(){
    const { payload, token } = useSelector(state => state.LoginReducer.User)
    const { accountAddress } = useSelector(state => state.LoginReducer.AccountDetails)
    const [filter,setFilter] = useState('activity');
    const [Follow, SetFollow] = useState('follow');
    const { customurl }  =   useParams()
    const [userProfile, setUserProfile] = useState({})
    const [Tokens, SetTokens] = useState({ 'activity': { 'loader': true, page: 1, list: [] } })
    var LikeForwardRef = useRef();
    const [LikedTokenList, setLikedTokenList] = useState([]);
    var [loader,setLoader] = useState(true);
    var navigate = useNavigate();
    var [openpopup,setOpenpopup] = useState('');
    const[status,setStatus]=useState(true)
    const location=useLocation();
    const dispatch=useDispatch()
    const { liked } = useSelector(
        (state) => state.LoginReducer.Liked  
      );
      console.log("LIKEDDDDDD",liked)
    useEffect(() => {

        // if(location?.state?.Tab){
        //     console.log("locatioj ",location,location.state,location.state.Tab);
        //     setFilter(location.state.Tab)
        //     // SetTabName(location.state.Tab)
          
        // }
        if(location?.state?.Tab){
            console.log("locatioj ",location,location.state,location.state.Tab);
            setFilter(location.state.Tab)
            // SetTabName(location.state.Tab)
          
        }
        if (typeof Tokens[filter] == 'undefined' && filter !== "importcollection") {
            Tokens[filter] = { page: 1, list: [], loader: false };
            SetTokens(Tokens);
            Explore(1, filter);
        }
       
    }, [filter])
    
    
    useEffect(()=>{
        console.log('====================================');
        console.log('====================================');
        if(filter=="favourite" || filter=="myitem"){
            console.log("ULLAVA");
        Tokens[filter] = { page: 1, list: [], loader: false };
        SetTokens(Tokens);
        Explore(1, filter);
        }
    },[liked])
    useEffect(() => {
        getProfileDetails()
        Explore();
       
        // if(accountAddress!==userProfile?.WalletAddress){
        // FollowUnFollow()
        // }
    }, [customurl,accountAddress,userProfile?.WalletAddress])


   

    const getProfileDetails = async () => {
        var SendDATA = {
            CustomUrl: customurl,
            Type: 'getProfile',
            User : payload?.WalletAddress
        }
        var profileInfo = await userRegister(SendDATA)
        console.log('senndddd',profileInfo)
        // const id = toast.loading("Searching User");

        if (profileInfo?.success == "success" ) {
            console.log("dffkjgfg");
            setUserProfile(profileInfo.data)
    SetFollow(profileInfo.follow == "follow" ? "unfollow" : "follow")
        }
        else{
            // toast.error("User Not found",{isLoading:false,autoClose:700,closeButton:true,closeOnClick:true})
            // setTimeout(function(){ 
            //     navigate('/')
            //   }, 2000);
        }
    }

    const FollowUnFollow = async()  =>  {
        const id = toast.loading(Follow == "follow" ? "Your Following "+ userProfile?.DisplayName : "Your UnFollowing "+ userProfile?.DisplayName)
        let SendData = {
            MyItemAddr  :   userProfile?.WalletAddress,
            ClickAddr   :   accountAddress,
            From        :   'myitem',
            MyItemCustomUrl    :    userProfile?.CustomUrl,
            ClickCustomUrl    :    payload?.CustomUrl,
        }
        // console.log('senddataaa',SendData,userProfile,payload)
        let Resp    =   await   FollowUnFollowFunc(SendData)
        if(Resp?.success){
            SetFollow(Resp.msg == 'follow' ? 'unfollow' : 'follow')
            toast.update(id,{render:Resp.msg == 'follow' ? 'Your Following '+userProfile?.DisplayName + ' Successfully' :'Your UnFollowing '+userProfile?.DisplayName + ' Successfully',type:'success',isLoading:false,autoClose:1000 })
        }
        else{
            toast.update(id,{render:'Try Again',type:'error',isLoading:false,autoClose:1000,closeButton:true,closeOnClick:true})

        }
    }

    const Explore = async (data, tab) => {
        setStatus(true)
        var page = data ? data : (Tokens[filter]?.page)
        var SendDATA = {
            TabName: tab ? tab : filter,
            limit: 12,
            CustomUrl: userProfile?.CustomUrl,
            NFTOwner: userProfile?.WalletAddress,
            page: page ?? 1,
            from: 'myItem',
        }
        let Resp = await Token_MyList_Func(SendDATA)
        console.log('====================================');
        console.log("RESSSPPP",Resp?.data);
        console.log('====================================');
        setLoader(( Resp?.data?.length == 0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true)
        if (Resp?.success == 'success' && Resp.data.length > 0) {
            SetTokens({
                ...Tokens, ...{
                    [filter]: {
                        list: [...Tokens[filter].list, ...Resp.data],
                        loader: ( Resp.data.length == 0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
                        page: Tokens[filter].page
                    }
                }
            })
            setStatus(false)
        }
        setStatus(false)
    }

    const LoadMore = () => {
        Tokens[filter].page = Tokens[filter].page + 1;
        SetTokens(Tokens);
        Explore(Tokens[filter].page);

    }

    const CoverImg = async(event) =>{
        const toastupd = toast.loading("You Updated Image")
        var reader = new FileReader()
        const { id, files } = event.target;
        var fileNameExt = files[0].name.substr(files[0].name.lastIndexOf(".") + 1);
        if (event.target.files && event.target.files[0]) {
            if(files,id,files[0].type.includes("image")){
            var file = event.target.files[0];
            var Resp;
            if(id=='coverupload'){
                Resp = await userRegister({Type:'cover',WalletAddress:payload.WalletAddress,Cover:files[0]})
                // console.log('coverrr',Resp)
            }
            else{
                Resp = await userRegister({Type:'profileimage',WalletAddress:payload.WalletAddress,Profile:files[0]})
                // console.log('coverrr',Resp)
            }
            if(Resp?.success=='success'){
                getProfileDetails();
                dispatch({
                    type: 'Register_Section',
                    Register_Section: {
                        User: {
                            payload	: 	Resp.data,
                            token	:	Resp.token ? Resp.token : token
                        }
                    }
                })
                toast.update(toastupd,{render:Resp.msg,type:'success',isLoading:false,autoClose:700,closeButton:true,closeOnClick:true})
            }
            // let Resp = await userRegister({Type:'cover',CustomUrl:payload.CustomUrl,Cover:files[0]})
            }
            else{
                toast.update(toastupd,{render:"Profile or Cover Image Must be a Image",type:'error',isLoading:false,autoClose:700,closeButton:true,closeOnClick:true})
            }    
        }

        // let formData = { ...formValue, ...{ [id]: files[0] } };
    }

    function LikeList(data){
        setLikedTokenList(data)
    }

    return(
        <>
        <Header />
     
        <div className="board">
            <section className="tf-page-title">    
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="breadcrumbs" data-aos="fade-right">
                                <li><NavLink to="/">Home</NavLink></li>
                                <li>Profile</li>
                            </ul>
                        </div>
                    </div>
                </div>  
                <div className="container-fluid">
                    <div className="row">
                        <div className="thumb-pagetitle">
                            
                            <img src={userProfile?.Cover ? action_config.IMG_URL+"/user/"+userProfile?.WalletAddress+'/cover/'+userProfile?.Cover  : require("../assets/images/thumb-pagetitle.jpg")} alt="images" />
                            {userProfile?.WalletAddress === payload?.WalletAddress && 
                            <div className="edit_profile">
                                <input type="file" id="coverupload" name="upload" onChange={(e) => CoverImg(e)}/>
                                <i className="fa fa-pencil"></i>    
                            </div>}
                        </div>
                    </div>
                </div>                  
            </section>
            <section className="tf-dashboard tf-tab">
                <div className="tf-container">  
                    <div className="row ">
                        <div className="col-xl-3 col-lg-12 col-md-12" data-aos="fade-up">
                            <div className="dashboard-user">
                                <div className="dashboard-infor">
                                    <div className="avatar">
                                        <img src={userProfile?.Profile ? action_config.IMG_URL+"/user/"+userProfile?.WalletAddress+'/profile/'+userProfile?.Profile  : require("../assets/images/banner.png")} alt="images" />
                                        {userProfile?.WalletAddress === payload?.WalletAddress && 
                                        <div className="edit_profile">
                                           <input type="file" id="profileupload" name="upload" onChange={(e) => CoverImg(e)}/>
                                           <i className="fa fa-pencil" style={{color:"#000"}}></i> 
                                        </div>}
                                    </div>
                                    <div className="name mt-3">{Name_showing(userProfile?.DisplayName)}</div>
                                    <div className="pax"><i className="fab fa-ethereum"></i>{address_showing(userProfile?.WalletAddress)}</div>
                                    <div className="description">
                                        {userProfile?.Bio}
                                    </div>
                                  
                                    <ul className="social-item">
                                    {userProfile?.Facebook &&<li><a href={userProfile?.Facebook} target="_blank"><i className="fab fa-facebook-f"></i></a></li> }
                                    {userProfile?.Twitter &&<li><a href={userProfile?.Twitter} target="_blank"><i className="fab fa-twitter"></i></a></li> }
                                    {userProfile?.Instagram &&<li><a href={userProfile?.Instagram} target="_blank"><i className="fab fa-instagram"></i></a></li> }
                                    {userProfile?.Youtube &&<li><a href={userProfile?.Youtube} target="_blank"><i className="fab fa-youtube"></i></a></li> }
                                    </ul>
                                
                                   {userProfile?.WalletAddress != payload?.WalletAddress &&
                                   <div className="text-center follow_btn">
                                   <Button className="tf-button active btn btn-primary" onClick={FollowUnFollow}>{Follow}</Button>
                               </div>}
                                    
                                </div>
                                <div className="dashboard-filter">
                                    <ul className="filter-menuu menu-tab">
                                        <li className={filter == "activity" ? "active dashboard" : "dashboard"} onClick={() => setFilter("activity")}><Link to="#"> 
                                            <img src={Inventory}  className="showimage"/> <img src={Inventory1} className="hoverimage" />&emsp;Activity</Link></li>
                                        <li className={filter == 'follower' ? "active dashboard" : "dashboard"}onClick={() => setFilter('follower')}><Link to="#">
                                        <img src={HistoryCon} className="showimage" /><img src={HistoryCon1} className="hoverimage" />
                                        &emsp;Followers</Link></li>
                                        <li className={filter == 'following' ? "active dashboard" : "dashboard"}onClick={() => setFilter('following')}><Link to="#">
                                        <img src={FollowingCon} className="showimage"/> <img src={FollowingCon1} className="hoverimage" />
                                        &emsp;Following </Link></li>
                                        {/* <li className={filter == 'collection' ? "active dashboard" : "dashboard"}onClick={() => setFilter('collection')}><Link to="#">
                                        <img src={require("../assets/images/collection.png")} />
                                        &emsp;Collection </Link></li> */}
                                        {/* {userProfile?.WalletAddress === payload?.WalletAddress && 
                                        <li className={filter == 'importcollection' ? "active dashboard" : "dashboard"}onClick={() => {setFilter('importcollection');setOpenpopup('collection')}}><Link to="#">
                                        <img src={require("../assets/images/collection.png")} />
                                        &emsp;Import Collection </Link></li>} */}
                                        <li className={filter == 'favourite' ? "active dashboard" : "dashboard"}onClick={() => setFilter('favourite')}><Link to="#">
                                            <img src={Favcon} className="showimage"/> <img src={Favcon1} className="hoverimage" /> &emsp;Favourites</Link></li>
                                        {userProfile?.WalletAddress === payload?.WalletAddress && 
                                        <li className={filter == 'account' ? "active dashboard" : "dashboard"}onClick={() => setFilter('account')}><Link to="#"> 
                                        <img src={Edit}  className="showimage" /><img src={Edit1} className="hoverimage" /> 
                                        &emsp;Account Settings</Link></li>}
                                        <li className={filter == 'myitem' ? "active dashboard" : "dashboard"} onClick={() => setFilter('myitem')}>
                                        <Link to="#">
                                        <img src={item} className="showimage"/> <img src={require("../assets/images/myitem-white.png")} className="hoverimage"/>
                                        &emsp;My Item</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-12 col-md-12 overflow-table">
                            <div className="dashboard-content inventory content-tab" data-aos="fade-right">
                            {filter == 'account' 
                            ? (<EditProfile 
                                GetProfile={getProfileDetails} />) 
                            :filter == "activity" 
                            ?<Activity  
                                datas={Tokens[filter]} 
                                Loadmore = {LoadMore} 
                                List ={Tokens[filter]}
                                filter = {filter} 
                                Explore = {Explore}
                                SetTokens = {SetTokens}
                                Tokens = {Tokens}
                                userProfile = {userProfile}
                                Loader = {loader} />
                            :(filter == 'following'||filter == 'follower' || filter == "collection" ) 
                            ? (<Following datas={Tokens[filter]?.list} type={filter} user={userProfile?.WalletAddress === payload?.WalletAddress}/>)
                            : filter=="favourite" ?

                                status==true?
                                <>
                                <h4 class="title-dashboard aos-init aos-animate" data-aos="fade-right">Favourites</h4>
    <div className="text-centre">
        <h3 className="load_datas" >Loading...</h3>
        {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
        {/* <p>Nothing for ReSale at this time</p> */}
                <div className="load-more">
                {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                </div>
        </div>
    </>:
                                <>
                                <h4 class="title-dashboard aos-init aos-animate" data-aos="fade-right">Favourites</h4>
                                <div className="row">
                            

                                {  Tokens[filter]?.list.length>0 ? Tokens[filter]?.list.map((val,index)=>
                                <div
                                className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                               
                            >                                           <Card 
                                            product={val}
                                            index={index}
                                            LikeList={LikeList}
                                            LikedTokenList={LikedTokenList}
                                            setLikedTokenList={setLikedTokenList}
                                            LikeForwardRef={LikeForwardRef}
                                        />
                                        </div>
                                )
                                :
                                <NoData/>
                            }
                                        </div>
                                        </>
                                        :
                                        filter == "myitem" && <> <h4 class="title-dashboard aos-init aos-animate" data-aos="fade-right">My Items</h4>
                                        <Myitem profile='true'/>
                                        </>
                                        
                           }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer />    
        {/* {openpopup == 'collection' && 
        <ImportCollection
            SetOpenPopup={setOpenpopup}
            Creator={accountAddress}
            Network={config.COIN_NAME}
            SetFilter={setFilter}
            // GetCollection = {()=>CollectionByCreate({Type: collection == 1 ? 721 : 1155,
            //     Creator: accountAddress})}
            // collection = {collection}
            AccountAddress = {accountAddress}
        />} */}
        </>
    )
}