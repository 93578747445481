import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BidApprove } from "../../actions/axioss/nft.axios";
import useContractProviderHook from "./../../actions/contractProviderHook";
import ImgAudVideo from "../../separate/ImgAudVideo";
import config from '../config/config'
import { Name_showing } from '../../actions/common';
export function CancelBids({ OpenPopup, closePop, owner, bidder, item }) {

    const push = useNavigate()
    const [Btn, SetBtn] = useState('start')
    const ContractCall = useContractProviderHook()
    const { web3, accountAddress } = useSelector(state => state.LoginReducer.AccountDetails);
    const { payload } = useSelector(state => state.LoginReducer.User)
    const [show9, setShow9] = useState(true);
    const handleClose9 = () => setShow9(false);

    const FormSubmit = async () => {
        SetBtn('process')
        const id = toast.loading('Cancel Your order')
        var error = await ContractCall.Contract_Base_Validation()
        console.log("adasdasdasdsadasdasdasd",error)
        if (error) {
            toast.update(id, { render: error, type: 'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
            SetBtn('error')
        }
        else {
            console.log('biiddd',bidder,item);
            var FormValue = {
                TokenBidderAddress: accountAddress,
                NFTQuantity: bidder.NFTQuantity,
                NFTId: item.NFTId,
                ContractAddress: item.ContractAddress,
                ContractType: item.ContractType,
                CollectionNetwork: item.CollectionNetwork,
                from: 'Cancel',
                activity: 'CancelBid',
                Category: item.Category,
                EmailId: payload.EmailId,
                click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`

            }
            console.log('gsfgsfg',FormValue,bidder)
            let Resp = await BidApprove(FormValue)
            console.log('dksfgsdhkg',Resp)
            if (Resp.success == 'success') {
                toast.update(id, { render: 'Cancelled Bid Successfully', type: 'success', isLoading: false, autoClose: 1000 ,closeButton:true,closeOnClick:true})
                SetBtn('done')
                closePop();
                setTimeout(() => {
                    window.location.reload();
                  }, 1500);
                // push(`/my-item/${payload?.CustomUrl}`)
            }
            else {
                toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
                SetBtn('try')
            }
        }

    }

    useEffect(()=>{
        async function BalanceCheck(){
          var Nftbalance = await ContractCall.Current_NFT_Balance(owner,item)
          console.log('ownneerrr ittemmm',owner,item,Nftbalance);
          if(Nftbalance !== owner.NFTBalance){
            toast.warning("You won't buy at this moment please refresh you data");
            setTimeout(() => {
              push("/")
            }, 1000);
          }
        }
        BalanceCheck();
      },[item,owner])

    return (<Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show9}
    >
        <Modal.Header>
            <button type="button" class="btn-close" aria-label="Close" onClick={closePop}></button>
            <Modal.Title id="contained-modal-title-vcenter">
                Cancel Bid
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='approvetransaction'>
                <p className='mt-4'>You are about to cancel the bid for</p>
                <p className='nstlx'><b>{Name_showing(item.NFTName)}</b></p>
                <div className='connecwalletbtns d-flex justify-content-between mt-5'>
                    <Button 
                        type="button"
                        className="connectwallet primary tf-button active btn btn-primary"
                        disabled={Btn == 'error' || Btn === "process" || Btn === "done" ? true : false}
                        onClick={Btn == 'start' || Btn === "try" ? FormSubmit : null}
                    >
                        {Btn == 'start' && 'Start'
                            || Btn == 'try' && 'Try-Again'
                            || Btn == 'error' && 'Error'
                            || Btn == 'done' && 'Done'
                            || Btn == 'process' && 'In-Progress'
                        }</Button>
                    <Button 
                    disabled    =   {Btn === "process"  ||  Btn ==="done" ? true : false} 
                    className="cancelpayment tf-button active btn btn-primary secondary" onClick={closePop}>Cancel</Button>
                </div>
            </div>
        </Modal.Body>
    </Modal>)
}