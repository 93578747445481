import React, { useState, useEffect, useRef } from "react";
import AOS from 'aos';
import Card from "./Card.js";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { NavLink, Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Token_List_Func } from "../actions/axioss/nft.axios"
import Button from "react-bootstrap/esm/Button.js";
import NoData from "./seperatemodals/nodata.jsx";
export default function Explore(){
  // useEffect(() => {
  //   AOS.init({
  //     duration: 1000
  //   });
  //   AOS.refresh();
  // }, []);
    const [category,setCategory] = React.useState(1);
    const [show,setShow] = React.useState(false);
    const [filter,setFilter] = useState("recentcreated");
    var [filtershow,setFiltershow] = useState("Recently Created")
const[status,setStatus]=useState(true)
    const { Categorys } = useSelector((state) => state.LoginReducer);
    const { payload } = useSelector((state) => state.LoginReducer.User);
    const {Category} = useParams()

    // console.log("category",payload)

    const [TabName, SetTabName] = useState("All");
    var LikeForwardRef = useRef();
    const [LikedTokenList, setLikedTokenList] = useState([]);
    const [Tokens, SetTokens] = useState({
    All: { loader: true, page: 1, list: [] },
  });
  const Tabname = (newValue) => {
    setStatus(true)
    SetTabName(newValue);
  };
  useEffect(() => {
    Explore();
  }, []);
  useEffect(() => {
    // if (typeof Tokens[TabName] == "undefined") {
    if (typeof Tokens[TabName] == "undefined" || Tokens[TabName].filter !== filter) {
      Tokens[TabName] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      Explore(1, TabName);
    } 
  }, [TabName,filter]);

  const Explore = async (data, tab) => {
    var page = data ? data : Tokens[TabName]?.page;
    var SendDATA = {
      TabName: tab ? tab : TabName,
      limit: 12,
      ProfileUrl: payload?.ProfileUrl ? payload.ProfileUrl : "",
      page: page ?? 1,
      from: "Explore",
      filter : filter
    };
    let Resp = await Token_List_Func(SendDATA);
    console.log("Check datad",Resp)
    if (Resp.data) {
      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: [...Tokens[TabName].list, ...Resp.data],
            // loader:
            //   Resp.Count <= Tokens[TabName].list.length + Resp.data.length ||
            //   Resp.data.length === 0
            //     ? false
            //     : true,
            loader: ( Resp.data.length ==0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
            page: Tokens[TabName].page,
            filter: filter
          },
        },
      });
      setStatus(false)
    } else
      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: Tokens[TabName].list,
            loader: false,
            page: Tokens[TabName].page,
            filter: filter
          },
        },
      });
      setStatus(false)
  };
  const LoadMore = () => {
    Tokens[TabName].page = Tokens[TabName].page + 1;
    SetTokens(Tokens);
    Explore(Tokens[TabName].page);
  };
  function LikeList(data){
    setLikedTokenList(data)
  }

  useEffect(() => {
    if(Category){ 
    // console.log("VALUE",Category);
    SetTabName(Category)
    Explore(1,Category)
  }
  else{
    Explore();
  }
  },[Category]);

    return(
        <>
        <Header />
        <div className="explore">
            <section className="tf-page-title" data-aos="fade-right">    
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">

                            <ul className="breadcrumbs">
                                <li><NavLink to ="/">Home</NavLink></li>
                                <li>Explore</li>
                            </ul>
                            <h4 className="page-title-heading">Explore</h4>
                
                        </div>
                    </div>
                </div>                    
            </section>      
            <section className="tf-live-auction explore tf-filter" data-aos="fade-left">
                <div className="tf-container">
                    <div className="row ">
                        <div className="col-md-12">
                        <div className="top-menu wow fadeInUp"  data-aos="fade-up">
                                <ul className="filter-menu">
                                <li className={TabName == "All" ? "active" : ""} onClick={() => Tabname("All")}>All</li>
                                {Categorys.map((item) => {
                                  return(
                                     <li className={TabName == item.label ? "active" : ""} onClick={() => Tabname(item.label)}><a>{item.label}</a></li>
                                  )
                                })}
                                    {/* <li className={category == 1 ? "active" : ""} onClick={() => setCategory(1)}><a>3D MODEL</a></li>
                                    <li className={category == 2 ? "active" : ""} onClick={() => setCategory(2)}><a>ANIME/MANGA</a></li>
                                    <li className={category == 3 ? "active" : ""} onClick={() => setCategory(3)}><a>CYBER PUNK</a></li>
                                    <li className={category == 4 ? "active" : ""} onClick={() => setCategory(4)}><a>PIXEL ART</a></li>
                                    <li className={category == 5 ? "active" : ""} onClick={() => setCategory(5)}><a>MUSIC</a></li>
                                    <li className={category == 6 ? "active" : ""} onClick={() => setCategory(6)}><a>ABSTRACT</a></li>
                                    <li className={category == 7 ? "active" : ""} onClick={() => setCategory(7)}><a>2D ARTS</a></li> */}
                                </ul>
                                <div id="item_category2" className="dropdown">
                                    <Link to="#" className="btn-selector nolink ">{filtershow}</Link>
                                    <ul className="show">

                                        {/* <li onClick={()=>{setFilter("recentlisted");setFiltershow("Recently Listed")}}><span>Recently Listed</span></li> */}

                                        <li onClick={()=>{setFilter("recentcreated");setFiltershow("Recently Created")}}><span>Recently Created</span></li>
                                        <li onClick={()=>{setFilter("recentsold");setFiltershow("Recently Sold")}}><span>Recently Sold</span></li>
                                        {/* <li onClick={()=>Tabname("recentlisted")}><span>Recently Received</span></li> */}
                                        <li onClick={()=>{setFilter("PriceHighToLow");setFiltershow("High to Low")}}><span>High to Low</span></li>
                                        <li onClick={()=>{setFilter("PriceLowToHigh");setFiltershow("Low to High")}}><span>Low to High</span></li>
                                        {/* <li onClick={()=>Tabname("recentlisted")}><span>Recently Last Sale</span></li> */}
                                        <li onClick={()=>{setFilter("oldest");setFiltershow("Oldest")}}><span>Oldest</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tf-filter-container row"  data-aos="fade-up">
                        {/* {category == 1 ? */}
                        {/* {console.log('likrreeeeexple',LikeForwardRef,setLikedTokenList,LikedTokenList)} */}
                        {status == true ? 
                        <>
                        <div className="text-centre">
                            <h3 className="load_datas">Loading...</h3>
                            {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
                            {/* <p>Nothing for ReSale at this time</p> */}
                                    <div className="load-more">
                                    {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                                    </div>
                            </div>
                        </>:
                        Tokens[TabName]?.list.length>0 ?
                        (
                            Tokens[TabName]?.list.map((item,index) => (
                              <div  
        className="col-xl-3 col-lg-4 col-md-6 tf-loadmore 3d cyber " 
        // data-aos="zoom-in-up"
        
      >
                        <Card product={item} 
                              index={index}
                              LikeList={LikeList}
                              LikedTokenList={LikedTokenList}
                              setLikedTokenList={setLikedTokenList}
                              LikeForwardRef={LikeForwardRef}
                        />
                        </div>
                        )))
                       :<NoData/>
                        }
                    </div>
                    {/* {console.log('gfbvfbfb',Tokens,Tokens[TabName]?.loader)} */}
                    {Tokens[TabName]?.loader && (
                    <div className="col-md-12">
                        <div className="btn-loadmore mt17">
                            <Button className="tf-button loadmore" onClick={LoadMore}>Load More</Button>
                        </div>
                    </div>
                    )}
                </div>
            </section>
        </div>
        <Footer />    
        </>
    )
}