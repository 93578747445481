import React, { useEffect, useState } from "react";
import Ethereum from "../assets/images/svg/ethereum.svg";
import Stellite from "../assets/images/svg/stellite.svg";
import Tezos from "../assets/images/svg/tezos.svg";
import Flow from "../assets/images/svg/flow.svg";
import IO from "../assets/images/svg/io.svg";
import Author from "../assets/images/banner.png";
import { Link, useNavigate } from "react-router-dom";
import ImgAudVideo from "./../separate/ImgAudVideo";
import config from "./config/config";
import NoData from "./seperatemodals/nodata";
import { Token_MyList_Func } from "../actions/axioss/user.axios";
import { Name_showing } from "../actions/common";
export default function Activity({ datas , Loadmore , List , filter , SetTokens , Tokens , userProfile , Loader }) {
  const push = useNavigate();
  {console.log('activityyyy',datas,List)}
  const[status,setStatus]=useState(true);
  // var [Sort,SetSort] = ({image:-1,name:-1,category:-1,blockchain:-1,quantity:-1,price:-1})
  const Explore = async (sort) => {
    // SetSort({...sort,...{[]:}})
    // var page = (Tokens[filter]?.page)
    // var SendDATA = {
    //     TabName: filter,
    //     limit: 12,
    //     CustomUrl: userProfile?.CustomUrl,
    //     NFTOwner: userProfile?.WalletAddress,
    //     page: page ?? 1,
    //     from: 'myItem',
    //     sort : {}
    // }
    // let Resp = await Token_MyList_Func(SendDATA)
    // if (Resp?.success == 'success' && Resp.data.length > 0) {
    //     SetTokens({
    //         ...Tokens, ...{
    //             [filter]: {
    //                 list: [...Tokens[filter].list, ...Resp.data],
    //                 loader: ( Resp.data.length ==0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
    //                 page: Tokens[filter].page
    //             }
    //         }
    //     })
    // }
  }
  useEffect(()=>{
    if(datas.length >=0){
      setStatus(false)
    }
  },[])
  return (
    <>
      <div className="inner-content inventory">
        <h4 className="title-dashboard" data-aos="fade-right">
          Activity
        </h4>
        <div className="table-ranking top">
          <div className="title-ranking">
            <div className="col-rankingg">
            {/* onClick={Explore('price')} */}
              <Link to="#">Image</Link>
            </div>
            <div className="col-rankingg">
              <Link to="#">Name</Link>
            </div>
            <div className="col-rankingg">
              <Link to="#">Category</Link>
            </div>
            <div className="col-rankingg blockchain">
              <Link to="#">Blockchain</Link>
            </div>
            <div className="col-rankingg">
              <Link to="#">Quantity</Link>
            </div>
            <div className="col-rankingg">
              <Link to="#">Price</Link>
            </div>

            <div className="col-rankingg">
              <Link to="#">Action</Link>
            </div>
          </div>
        </div>
        <div className="table-ranking ">
          {datas?.list?.length==0 ? 
           <>
           <div className="text-centre">
               <h3 className="load_datas">Loading...</h3>
               {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
               {/* <p>Nothing for ReSale at this time</p> */}
                       <div className="load-more">
                       {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                       </div>
               </div>
           </>: 
           datas?.list?.length>0 ?
          datas?.list?.map((item, key) => {
            return (
              <div className="content-ranking">
                <div className="col-rankingg">
                  <div
                    className="image"
                    onClick={() => {
                      push(
                        item.Activity == "Bid" ||
                          item.Activity == "Edit" ||
                          item.Activity == "Cancel"
                          ? ""
                          : `/info/${item.CollectionNetwork}/${item.ContractAddress}/${item.To}/${item.TokenId}`
                      );
                    }}
                  >
                                 <ImgAudVideo
                file={`${config.IMG_URL}/nft/${item?.Creator}/Compressed/NFT/${item?.CompressedFile}`}
                origFile={`${config.IMG_URL}/nft/${item?.Creator}/Original/NFT/${item?.OriginalFile}`}
                thumb={`${config.IMG_URL}/nft/${item.Creator}/Compressed/NFT_THUMB/${item?.CompressedThumbFile}`}
                type={
                  item?.CompressedFile
                    ? item?.CompressedFile?.includes(".webp")
                      ? "image"
                      : item?.CompressedFile.includes(".webm")
                      ? "video"
                      : "audio"
                    : item?.CompressedFile
                }
              />
                    {/* <img src={data.authorImg} alt="Image" /> */}
                  </div>
                </div>
                <div className="col-rankingg">{Name_showing(item.TokenName)}</div>
                <div className="col-rankingg">{item.Category}</div>
                <div className="col-rankingg coin">
                  <img src={Ethereum} alt="Image" />
                  &emsp;{item.CollectionNetwork}
                </div>
                <div className="col-rankingg">{item.NFTQuantity}</div>
                <div className="col-rankingg">
                  {item.NFTPrice}
                  {item.CoinName}
                </div>
                <div className="col-rankingg nofication">
                  {" "}
                  <i className="fas fa-circle"></i>
                  {item.Activity}
                </div>
                <div className="col-rankingg dot">
                  <Link
                    to={
                      item.Activity == "Bid" ||
                      item.Activity == "Edit" ||
                      item.Activity == "Cancel"
                        ? ""
                        : item.Activity == "Mint"
                        ? `/info/${item.CollectionNetwork}/${item.ContractAddress}/${item.To}/${item.TokenId}`
                        : `/info/${item.CollectionNetwork}/${item.ContractAddress}/${item.From}/${item.TokenId}`
                    }
                  >
                    <i className="far fa-ellipsis-h"></i>
                  </Link>
                </div>
              </div>
            );
          }):
          <><NoData/></>}
          { datas?.list?.length>0 && Loader && <div className="table-btn">
            <Link to="#" className="tf-button active btn btn-primary"  onClick={Loadmore}>Load more</Link>
          </div> }
        </div>
      </div>
    </>
  );
}
