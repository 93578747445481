import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
  } from "react";
  import Card from "./Card";
  import History from "../assets/images/author-history-1.jpg";
  import { NavLink, Link, useLocation } from "react-router-dom";
  import Header from "../separate/Header.js";
  import Footer from "../separate/Footer.js";
  import axios from 'axios'
  
  import config from "./config/config";
  
  import ImgAudVideo from "../separate/ImgAudVideo";
  import { toast } from "react-toastify";
  import { address_showing, isEmpty ,Name_showing} from "../actions/common";
  import { useNavigate, useParams } from "react-router-dom";
  import useContractProviderHook from "../actions/contractProviderHook";
  import { useSelector } from "react-redux";
  import { Token_Info_Func } from "../actions/axioss/nft.axios";
  import { BuyNow } from "./seperatemodals/BuyNow";
  import { Lower } from "./seperatemodals/Lower";
  import { Cancel } from "./seperatemodals/Cancel";
  import { Bid } from "./seperatemodals/Bid";
  import { Accept } from "./seperatemodals/Accept";
  import { CancelBids } from "./seperatemodals/CancelBids";
  import Button from "react-bootstrap/esm/Button";
  import Countdown from "react-countdown";
  
  import moment from "moment";
  import { LikeRef } from "./seperatemodals/LikeRef";
  import { Share } from "./seperatemodals/Share";
  import { Report } from './seperatemodals/Report';
  import { findOwners } from "../actions/axioss/user.axios";
  
  export default function Details() {
    const { state } = useLocation();
    const [head,setHead]=useState('')
    const ContractCall = useContractProviderHook();
    const [TabName, SetTabName] = useState("All");
    const [Tokens, SetTokens] = useState({
      All: {
        loader: true,
        page: 1,
        list: [],
        owner: {},
        myBid: {},
        highbid: {},
        myowner: {},
      },
    });
    const [Tokens_Detail, SetTokens_Detail] = useState({});
    const [Explores, SetExplore] = useState([]);
    const { payload, isAdmin } = useSelector((state) => state.LoginReducer.User);
    const [InfoDetail, SetInfoDetail] = useState({});
    var [seemore,setSeemore] = useState ({});

      const { network, Contract, Owner, Id } = useParams()
      const [text,setText]=useState("")
      const [Loader, setLoader] = useState(false);
      const [LoaderTab, setLoaderTab] = useState(false);
      const [OpenPopup, SetOpenPopup] = useState('')
      const [SendDet, SetSendDet] = useState({});
      const [BtnData,SetBtnData] = useState('start')
      const { accountAddress, web3 } = useSelector(state => state.LoginReducer.AccountDetails);
      var [moreprops,setMoreprops] = useState('');
      const push  = useNavigate()
      var LikeForwardRef = useRef();
        const [LikedTokenList, setLikedTokenList] = useState([]);
        useEffect(() => {
            setLoader(true);
            findOwner();
          }, [accountAddress, state, Contract, Owner, Id]);
      useEffect(() => {
          if (typeof Tokens[TabName] == 'undefined') {
              Tokens[TabName] = { page: 1, list: [], loader: false };
              SetTokens(Tokens);
              Explore(1, TabName);
          } else setLoaderTab(false)
      }, [TabName, Contract, Owner, Id, accountAddress])
      const findOwner = async () => {
        var Resp = await findOwners({
          NFTCreator: Owner,
          ContractAddress: Contract,
          NFTId: Id,
        });
        // console.log("Resp", Resp);
        console.log('stttaaatee',state)
        if (Resp.success == "success") {
          // console.log("come ah");
          Explore();
        } else if (state?.data && Resp.success == "error") {
          console.log('elllseeee iffff')
          if(state?.data?.metadata?.animation_url){
            var Response = await axios.get(state.data.metadata.animation_url)
            state.data.type = (Response.headers['content-type']).includes('image') ? "image" : (Response.headers['content-type']).includes('video') ? "video" : "audio"
            console.log("res.headers['content-type'];",Response,Response.headers['content-type'])
          }
          SetInfoDetail(state.data);
          setLoader(false);
        } else {
          console.log('elsssseeee')
          Explore();
        }
      };
      let renderer = ({days, hours, minutes, seconds, completed }) => {
        if (completed) {
          return <span>Auction Completed!</span>;
        } else {
          return (
            <span>
              {" "}
              <span className="hourds">{formatTime(days)} d</span>{" "}
              <span className="semicolan">:</span>{" "}
              <span className="hourds">{formatTime(hours)} h</span>{" "}
              <span className="semicolan">:</span>{" "}
              <span className="hourds">{formatTime(minutes)} m</span>{" "}
              <span className="semicolan">:</span>{" "}
              <span className="hourds">{formatTime(seconds)} s</span>{" "}
            </span>
          );
        }
      };
    
      let formatTime = (time) => {
        return String(time).padStart(2, "0");
      };
    
     
      const formatter = Intl.NumberFormat('en', { notation: 'compact' }); 
  
    const POPUPACTION = useCallback(
      (text,data, item) => {
       
        console.log("Buy is Calling", text,data, item);
        if (accountAddress) {
          if (data == "Accept") {
            // console.log("ACCEPT")
            (async () => {
              let Statu = await ContractCall.GetApproveStatus(
                Tokens_Detail.ContractType == 721 ||
                  Tokens_Detail.ContractType == "721"
                  ? "Single"
                  : "Multiple",
                Tokens_Detail.ContractAddress
              );
              if (Statu == false || Statu == "error") {
                toast.warn("Need To Approve");
                SetBtnData("open");
                SetOpenPopup(data);
              } else {
                SetBtnData("error");
                SetOpenPopup(data);
                SetSendDet(item)
              }
            })();
          } else{ console.log("ELSE",data);setText(text); SetOpenPopup(data);
          SetSendDet(item);}
        } else {
          if (data === "Share") {
            setText(text)
            SetOpenPopup(data);
           
            SetSendDet(item);
          } else
            toast.error(" log in to connect to the wallet ", { autoClose: 1000,closeButton:true,closeOnClick:true });
        }
      },
      [OpenPopup, accountAddress, Tokens_Detail.ContractAddress]
    );
  console.log("DETAILSVARUTHA",TabName,Owner,Contract,Id)
    const Explore = async (data, tab) => {
      var page = data ? data : Tokens[TabName]?.page;
      var SendDATA = {
        TabName: tab ? tab : TabName,
        limit: 4,
        Owner: Owner,
        page: page ?? 1,
        from: "info",
        Contract: Contract,
        Id: Id.toString(),
        MyAdd: accountAddress,
      };
      console.log("Account Address Checing",SendDATA,accountAddress);
      let Resp = await Token_Info_Func(SendDATA);
      console.log("Owners List", Resp.token);
      if (
        Resp.token?.success == "success" &&
        Resp.token?.data[0]?.Current_Owner.length > 0
      ) {
        if (TabName == "All") {
          SetTokens_Detail(Resp.token.data[0]);
          SetExplore(Resp.Explore.data);
          setMoreprops(Resp.token.data[0].NFTProperties?.length)
        }
        SetTokens({
          ...Tokens,
          ...{
            [TabName]: {
              list: [
                ...Tokens[TabName].list,
                ...(TabName == "owner"
                  ? Resp.token.data[0].tokenowners_list
                  : TabName == "bid"
                  ? Resp.Bid.data
                  : []),
              ],
              loader:
                Resp.token.Count ==
                Tokens[TabName]?.list?.length + Resp.token.data.length
                  ? false
                  : true,
              page: Tokens[TabName].page,
              owner:
                TabName == "All"
                  ? Resp.token.data[0].Current_Owner.pop()
                  : Tokens["All"].owner,
              myowner:
                TabName == "All"
                  ? Resp.token.data[0].myowner.pop()
                  : Tokens["All"].myowner,
              myBid: Resp?.myBid?.data.pop(),
              highbid: Resp?.highBid?.data[0],
            },
          },
        });

        var test = await ContractCall.GetContractOwner('Single',"0x9A9EBc3A48D9ddc54A2D6A3599642aBA4363E7e1")

        console.log("kdgfdfad1111", Resp,test);
      } else {
        SetTokens({ [TabName]: { loader: true, page: 1, list: [] } });
      }
      setTimeout(() => {
        setLoaderTab(false);
        setLoader(false);
      }, 2000);
    };
    const tabChange = (newValue) => {
      setLoaderTab(true);
      SetTabName(newValue);
    };
  
    const closePop = () => {
      SetOpenPopup("");
    };
    const LikeAction = async () => {
      if (accountAddress) {
        var check = await LikeForwardRef.current.hitLike(Tokens_Detail);
        toast.success("you " + check + "d this token", { autoClose: 500,closeButton:true,closeOnClick:true });
        if(check == 'like'){
          // Tokens_Detail?.likecount
          SetTokens_Detail({...Tokens_Detail,...{likecount:Tokens_Detail.likecount+1}})
        }
        else if(check == 'unlike'){
          SetTokens_Detail({...Tokens_Detail,...{likecount:Tokens_Detail.likecount-1}})
        }
      } else toast.error("Connect Wallet", { autoClose: 1000,closeButton:true,closeOnClick:true });
    };
  
    function LikeList(data) {
      setLikedTokenList(data);
    }

    var Seemore = () => {
      setMoreprops(Tokens_Detail?.NFTProperties?.length)
    }

    

    return (
      <>
        {
          <LikeRef
            ref={LikeForwardRef}
            setLikedTokenList={setLikedTokenList}
            LikeList={LikeList}
          />
        }
      
        {OpenPopup === "Buy" && (
          <BuyNow
            owner={SendDet}
            OpenPopup={OpenPopup}
            closePop={closePop}
            item={{
              NFTId: Tokens_Detail.NFTId,
              NFTName: Tokens_Detail.NFTName,
              ContractAddress: Tokens_Detail.ContractAddress,
              ContractType: Tokens_Detail.ContractType,
              NFTRoyalty: Tokens_Detail.NFTRoyalty,
              NFTCreator: Tokens_Detail.NFTCreator,
              CollectionNetwork: Tokens_Detail.CollectionNetwork,
              Category: Tokens_Detail.Category,
            }}
          />
        )}
      
        {OpenPopup == "Cancel" && (
          <Cancel
            owner={SendDet}
            types="Cancel"
            OpenPopup={OpenPopup}
            closePop={closePop}
            file={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Compressed/NFT/${Tokens_Detail.CompressedFile}`}
            type={
              Tokens_Detail.CompressedFile
                ? Tokens_Detail.CompressedFile?.includes(".webp")
                  ? "image"
                  : Tokens_Detail.CompressedFile.includes(".webm")
                  ? "video"
                  : "audio"
                : Tokens_Detail.CompressedFile
            }
            thumb={Tokens_Detail.CompressedThumbFile}
            // noimg={require("../assets/images/No_image.jpg")}
            item={{
              TokenName: Tokens_Detail.NFTName,
              ContractAddress: Tokens_Detail.ContractAddress,
              ContractType: Tokens_Detail.ContractType,
              CollectionNetwork: Tokens_Detail.CollectionNetwork,
              Category: Tokens_Detail.Category,
              NFTPrice: Tokens[TabName]?.myowner?.NFTPrice,
              CoinName: Tokens[TabName]?.myowner?.CoinName,
            }}
          />
        )}
        {OpenPopup === "createorder" && (
          <Lower
            text ={text}
            owner={SendDet}
            OpenPopup={OpenPopup}
            closePop={closePop}
            file={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Compressed/NFT/${Tokens_Detail.CompressedFile}`}
            type={
              Tokens_Detail.CompressedFile
                ? Tokens_Detail.CompressedFile?.includes(".webp")
                  ? "image"
                  : Tokens_Detail.CompressedFile.includes(".webm")
                  ? "video"
                  : "audio"
                : Tokens_Detail.CompressedFile
            }
            thumb={Tokens_Detail.CompressedThumbFile}
            item={{
              NFTName: Tokens_Detail.NFTName,
              ContractAddress: Tokens_Detail.ContractAddress,
              ContractType: Tokens_Detail.ContractType,
              CollectionNetwork: Tokens_Detail.CollectionNetwork,
  
              CompressedFile: Tokens_Detail.CompressedFile,
              CompressedThumbFile: Tokens_Detail.CompressedThumbFile,
              OriginalFile: Tokens_Detail.OriginalFile,
              NFTCreator: Tokens_Detail.NFTCreator,
              NFTRoyalty: Tokens_Detail.RoNFTRoyaltyyalty,
  
              Category: Tokens_Detail.Category,
              NFTPrice: Tokens[TabName]?.myowner?.NFTPrice,
              CoinName: Tokens[TabName]?.myowner?.CoinName,
              PutOnSaleType: "FixedPrice",
              PutOnSale: true,
              ClockTime: null,
            }}
          />
        )}
        {OpenPopup === "Bid" && (
          <Bid
            owner={Tokens[TabName]?.owner}
            bidder={!isEmpty(SendDet) ? SendDet : Tokens[TabName]?.myBid}
            OpenPopup={OpenPopup}
            bid={Tokens[TabName]?.highbid}
            closePop={closePop}
            item={{
              NFTId: Tokens_Detail.NFTId,
              NFTName: Tokens_Detail.NFTName,
              ContractAddress: Tokens_Detail.ContractAddress,
              ContractType: Tokens_Detail.ContractType,
              NFTRoyalty: Tokens_Detail.NFTRoyalty,
              NFTCreator: Tokens_Detail.NFTCreator,
              CollectionNetwork: Tokens_Detail.CollectionNetwork,
              Category: Tokens_Detail.Category,
            }}
          />
        )}
        {console.log('sdgsgsdfgsg',SendDet)}
        {OpenPopup === "Accept" && (
          <Accept
            owner={Tokens[TabName]?.myowner}
            bidder={SendDet}
            OpenPopup={OpenPopup}
            bid={SendDet}
            closePop={closePop}
            approvestatus={BtnData}
            item={{
              NFTId: Tokens_Detail.NFTId,
              NFTName: Tokens_Detail.NFTName,
              ContractAddress: Tokens_Detail.ContractAddress,
              ContractType: Tokens_Detail.ContractType,
              NFTRoyalty: Tokens_Detail.NFTRoyalty,
              NFTCreator: Tokens_Detail.NFTCreator,
              CollectionNetwork: Tokens_Detail.CollectionNetwork,
              Category: Tokens_Detail.Category,
              // CompressedFile:Tokens_Detail.CompressedFile,
              // OriginalFile:Tokens_Detail.OriginalFile,
              // CompressedThumbFile:Tokens_Detail.CompressedThumbFile,
              // OriginalThumbFile:Tokens_Detail.OriginalThumbFile,
            }}
            // file={`${config.IMG_URL}/token/${Tokens_Detail.Creator}/Compressed/NFT/${Tokens_Detail.CompressedFile}`}
            // type={Tokens_Detail.CompressedFile ? (Tokens_Detail.CompressedFile?.includes('.webp') ? 'image' : Tokens_Detail.CompressedFile.includes('.webm') ? 'video' : 'audio') : Tokens_Detail.CompressedFile}
            // thumb={Tokens_Detail.CompressedThumbFile}
            // noimg={require("../assets/images/No_image.jpg")}
          />
        )}
        {OpenPopup == "CancelBid" && (
          <CancelBids
            bidder={SendDet}
            OpenPopup={OpenPopup}
            owner={Tokens[TabName]?.owner}
            closePop={closePop}
            item={{
              NFTId: Tokens_Detail.NFTId,
              NFTName: Tokens_Detail.NFTName,
              ContractAddress: Tokens_Detail.ContractAddress,
              ContractType: Tokens_Detail.ContractType,
              NFTRoyalty: Tokens_Detail.NFTRoyalty,
              NFTCreator: Tokens_Detail.NFTCreator,
              CollectionNetwork: Tokens_Detail.CollectionNetwork,
              Category: Tokens_Detail.Category,
            }}
          />
        )}
        {OpenPopup === "Share" && (
          <Share
            closePop={closePop}
            title={`${Tokens_Detail.NFTName}  NFT`}
            url={`${config.FRONT_URL}/info/${Tokens_Detail.CollectionNetwork}/${Tokens_Detail.ContractAddress}/${SendDet.NFTOwner}/${Tokens_Detail.NFTId}`}
            quote={`${Tokens_Detail.NFTName} NFT`}
          />
        )}
  
      
        {
          OpenPopup === "Report" &&
          <Report
            OpenPopup={OpenPopup}
            closePop={closePop}
            item={{
              NFTId: Tokens_Detail.NFTId,
              NFTName: Tokens_Detail.NFTName,
              ContractAddress: Tokens_Detail.ContractAddress,
              ContractType: Tokens_Detail.ContractType,
              NFTRoyalty: Tokens_Detail.NFTRoyalty,
              NFTCreator: Tokens_Detail.NFTCreator,
              CollectionNetwork: Tokens_Detail.CollectionNetwork,
              Category: Tokens_Detail.Category
            }}
          />
        }
  
              <Header />
              {Loader ?
                  // <div className='text-center'><div class="lds-hourglass"></div></div>
                  <div class="preload preload-container">
                    <div class="preload-logo"></div>
                  </div>
                  : <>
                      <section className="tf-page-title">
                          <div className="tf-container">
                              <div className="row">
                                  <div className="col-md-12">

                                  <ul className="breadcrumbs" data-aos="fade-right">
                                        <li><NavLink to="/">Home</NavLink></li>
                                        <li><NavLink to="/explore">Explore</NavLink></li>
                                        <li>Product Details</li>
                                    </ul>
                                    <h4 className="page-title-heading" data-aos="fade-left">Product Details</h4>
                                </div>
                            </div>
                        </div>
                    </section>
            <section className="tf-item-detail info">
              <div className="tf-container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="tf-item-detail-inner">
                      <div className="image">
                        {console.log('sfhgdfhdf',InfoDetail)}
                        {!isEmpty(InfoDetail) ? (
                          InfoDetail.type == "audio" ?
                          <ImgAudVideo
                          file={InfoDetail.metadata.animation_url}
                          type={"audio"}
                          thumb={InfoDetail.Image}
                          from="info"
                          origFile={InfoDetail.metadata.animation_url}
                        />
                        :
                         <iframe style={{ background: 'url(' + InfoDetail?.Image + ')',backgroundRepeat: "no-repeat",
                          backgroundSize: "100% 100%",maxHeight: 288,minHeight: 288,minWidth: "100%",maxWidth: "100%",borderRadius:15}} height="288" width="288" title="Iframe Example" id="myiFrame"></iframe>
                        ) : (
                          <ImgAudVideo
                            file={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Original/NFT/${Tokens_Detail.NFTOrginalImage}`}
                            type={
                              Tokens_Detail.CompressedFile
                                ? Tokens_Detail.CompressedFile?.includes(".webp")
                                  ? "image"
                                  : Tokens_Detail.CompressedFile.includes(".webm")
                                  ? "video"
                                  : "audio"
                                : Tokens_Detail.CompressedFile
                            }
                            thumb={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Compressed/NFT_THUMB/${Tokens_Detail.CompressedThumbFile}`}
                            from="info"
                            origFile={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Original/NFT/${Tokens_Detail.OriginalFile}`}
                          />
                        )}
                        {/* <img src={require("../assets/images/banner-new.jpg")} alt="Image" /> */}
                      </div>
                      <div className="content">
                        <div className="content-top">
                          {!isEmpty(InfoDetail) ? (
                            <div className="author">
                              <img
                                src={
                                  Tokens_Detail?.Profile
                                    ? `${config.IMG_URL}/user/${Tokens_Detail?.WalletAddress}/profile/${payload?.Profile}`
                                    : require("../assets/images/author-detail-3.png")
                                }
                                alt="Image"
                              />
                              <Link to={"/profile"+"/"+Tokens[TabName]?.owner?.CustomUrl}>
                              <h6 className="title">
                                {payload?.DisplayName
                                  ? Name_showing(payload?.DisplayName)
                                  : address_showing(payload?.WalletAddress)}
                              </h6>
                              </Link>
                            </div>
                          ) : (
                            <div className="author">
                              <img
                                src={
                                  Tokens[TabName]?.owner?.Profile
                                    ? `${config.IMG_URL}/user/${Tokens[TabName]?.owner?.WalletAddress}/profile/${Tokens[TabName]?.owner?.Profile}`
                                    : require("../assets/images/author-detail-3.png")
                                }
                                alt="Image"
                              />
                              <Link to={Tokens[TabName]?.owner?.CustomUrl ? "/profile"+"/"+Tokens[TabName]?.owner?.CustomUrl : "#"}>
                              <h6 className="title">
                                {Tokens[TabName]?.owner?.DisplayName
                                  ? Name_showing(Tokens[TabName]?.owner?.DisplayName)
                                  : address_showing(
                                      Tokens[TabName]?.owner?.NFTOwner
                                    )}
                              </h6>
                              </Link>
                            </div>
                          )}
                          {isEmpty(InfoDetail) && (
                            <div className="wishlish">
                              <div
                                className="number-wishlish"
                                onClick={() => LikeAction()}
                              >
                                {(LikedTokenList?.some(
                (value) => value.NFTId === Tokens_Detail.NFTId
              ) ? (
                                <i className="fa fa-heart active"></i>):<i className="fa fa-heart"></i>)}{Tokens_Detail?.likecount}
                              </div>
  
                              <div className="option btn-option">
                                <i className="far fa-ellipsis-h"></i>
                                <div className="option_popup">
                                {accountAddress !=  Tokens[TabName]?.owner?.WalletAddress && 
                            <a  onClick={()=>POPUPACTION("dummy",'Report', Tokens[TabName]?.owner)}>Report</a>
                             }
                                {/* <a href="#" onClick={()=>POPUPACTION('Report', Tokens[TabName]?.owner)}>Report</a> */}
                                <a href="#" onClick={()=>POPUPACTION("dummy",'Share', Tokens[TabName]?.owner)}>Share</a>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        
                        <h2 className="title-detail">
                          {!isEmpty(InfoDetail)
                            ? InfoDetail.NFTName
                            : Name_showing(Tokens_Detail?.NFTName)}
                        </h2>
                        {/* {console.log("sgfhfdhghth",Tokens[TabName])} */}
                        <h6 className="available_tokens"><span>Total available tokens:</span>&nbsp;{formatter.format
(InfoDetail?.amount ? InfoDetail?.amount : Tokens[TabName]?.owner?.NFTBalance)}</h6> 
                        {Tokens[TabName]?.owner?.PutOnSaleType === "FixedPrice" && <p className="available_tokens"><span>Price:</span>&nbsp;{Tokens[TabName]?.owner?.NFTPrice}&nbsp;{Tokens[TabName]?.owner?.CoinName}</p>}
                        <p className="except">
                          {isEmpty(InfoDetail) && Tokens_Detail?.NFTDescription}
                        </p>
                            {console.log('dskfhdsgsd',Tokens["All"]?.owner)}
                        
                        {Tokens["All"]?.owner?.PutOnSaleType ===
                          "TimedAuction" &&
                          <div className="featured-countdown mb-5 d-flex justify-content-start align-items-center"> 
                          {new Date(Tokens["All"]?.owner?.ClockTime) > Date.now() ? (
                            <span>Auction Yet to Start {Math.ceil((new Date(Tokens["All"]?.owner.ClockTime).getTime() - new Date(Date.now()).getTime()) / (1000 * 3600 * 24))} Days..!</span>
                        )
                        :
                        ((new Date(Tokens["All"]?.owner?.EndClockTime) > Date.now()) ?
                          <>
                            <span>Timed Auction:</span>
                            <span className="ml-4 js-countdown">
                              {new Date(
                                Tokens["All"]?.owner?.EndClockTime
                              ) >= Date.now() && (
                                <Countdown
                                  date={Tokens["All"]?.owner?.EndClockTime}
                                  // autoStart={true}
                                  renderer={renderer}
                                />
                              )}
                            </span>
                            </>
                            :
                          <span>Auction Ended</span>
                        )}
                        </div>
                      }
                        <div className="tf-tab">

                          <ul className="menu-tab ">
                            <li
                              className={
                                TabName == "All"
                                  ? "tab-title active"
                                  : "tab-title"
                              }
                              onClick={() => tabChange("All")}
                            >
                              <a>Details</a>
                            </li>
                            <li
                              className={
                                TabName == "owner"
                                  ? "tab-title active"
                                  : "tab-title"
                              }
                              onClick={() => tabChange("owner")}
                            >
                              <a>Owners</a>
                            </li>
                            {isEmpty(InfoDetail) && (
                              <li
                                className={
                                  TabName == "bid"
                                    ? "tab-title active"
                                    : "tab-title"
                                }
                                onClick={() => tabChange("bid")}
                              >
                                <a>Bids</a>
                              </li>
                            )}
                            {/* <li className={TabName == 'history' ? "tab-title active" : "tab-title"}  onClick={() => tabChange('bid')}>
                                                  <a>History</a>
                                              </li> */}
                          </ul>
                          <div className="content-tab">
                            <div
                              className={
                                TabName == "All"
                                  ? "content-inner active d-block"
                                  : "content-inner d-none"
                              }
                            >
                              <div className="tab-details">
                                <div className="top">
                                  <div className="author">
                                    <div className="heading">Current Owner</div>
                                    {!isEmpty(InfoDetail) ? (
                                      <div className="infor">
                                        <img
                                          src={
                                            Tokens[TabName]?.owner?.Profile
                                              ? `${config.IMG_URL}/user/${Tokens[TabName]?.owner?.WalletAddress}/profile/${Tokens[TabName]?.owner?.Profile}`
                                              : require("../assets/images/author-detail-3.png")
                                          }
                                          alt="Image"
                                        />
                                        <Link to={"/profile"+"/"+Tokens[TabName]?.owner?.CustomUrl}>
                                        <h6 className="name">
                                          {payload?.DisplayName
                                            ? Name_showing(payload?.DisplayName)
                                            : address_showing(
                                                payload?.WalletAddress
                                              )}
                                        </h6>
                                        </Link>
                                      </div>
                                    ) : (
                                      <div className="infor">
                                        <img
                                          src={
                                            Tokens[TabName]?.owner?.Profile
                                              ? `${config.IMG_URL}/user/${Tokens[TabName]?.owner?.WalletAddress}/profile/${Tokens[TabName]?.owner?.Profile}`
                                              : require("../assets/images/author-detail-3.png")
                                          }
                                          alt="Image"
                                        />
                                        <Link to={Tokens[TabName]?.owner?.CustomUrl ? "/profile"+"/"+Tokens[TabName]?.owner?.CustomUrl : "#"}>
                                        <h6 className="name">
                                          {Tokens[TabName]?.owner?.DisplayName
                                            ? Name_showing(Tokens[TabName]?.owner?.DisplayName)
                                            : address_showing(
                                                Tokens[TabName]?.owner?.NFTOwner
                                              )}
                                        </h6>
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                  <div className="author">
                                    <div className="heading">Creator</div>
                                    {!isEmpty(InfoDetail) ? (
                                      <div className="infor">
                                        <img
                                          src={
                                            Tokens_Detail?.Creator_Profile
                                              ? `${config.IMG_URL}/user/${Tokens_Detail?.Creator_WalletAddress}/profile/${Tokens_Detail?.Profile}`
                                              : require("../assets/images/author-detail-3.png")
                                          }
                                          // Tokens_Detail
                                          alt="Image"
                                        />
                                        {/* <img src={require("../assets/images/author-detail-2.png")} alt="Image" /> */}
                                        <Link to={"/profile"+"/"+Tokens_Detail?.Creator_CustomUrl}>
                                          <h6 className="name">
                                          {Tokens_Detail?.Creator_DisplayName
                                            ? Name_showing(Tokens_Detail?.Creator_DisplayName)
                                            : address_showing(
                                                Tokens_Detail?.Creator_WalletAddress
                                              )}
                                        </h6></Link>
                                      </div>
                                    ) 
                                    : 
                                    (
                                      <div className="infor">
                                        <img
                                          src={
                                            Tokens_Detail?.Creator_Profile
                                              ? `${config.IMG_URL}/user/${Tokens_Detail?.Creator_WalletAddress}/profile/${Tokens_Detail?.Creator_Profile}`
                                              : require("../assets/images/author-detail-3.png")
                                          }
                                          // Tokens_Detail
                                          alt="Image"
                                        />
                                        {/* <img src={require("../assets/images/author-detail-2.png")} alt="Image" /> */}
                                        <Link to={"/profile"+"/"+Tokens_Detail?.Creator_CustomUrl}>
                                        <h6 className="name">
                                          {Tokens_Detail?.Creator_DisplayName
                                            ? Name_showing(Tokens_Detail?.Creator_DisplayName)
                                            : address_showing(
                                                Tokens_Detail?.NFTCreator
                                              )}
                                        </h6>
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {console.log('proppperrrtiiiesss,',Tokens_Detail?.NFTProperties,Tokens_Detail?.NFTProperties?.length)}
                                { Tokens_Detail?.NFTProperties?.length >0 &&
                                  !isEmpty(Tokens_Detail?.NFTProperties[0]) &&
                                <>
                                <div className="title-propepties">Properties</div>
                                <ul className="properties"> 
                                {Tokens_Detail?.NFTProperties?.map((val,ind) =>
                                    <li>
                                    <svg
                                      width="12"
                                      height="14"
                                      viewBox="0 0 12 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6 11.375L1.09375 7.53125L0 8.375L6 13.0312L12 8.375L10.9062 7.53125L6 11.375ZM6 9.65625L10.9062 5.84375L12 5L6 0.34375L0 5L1.09375 5.84375L6 9.65625ZM6 2.03125L9.8125 5L6 7.96875L2.1875 5L6 2.03125Z"
                                        fill="white"
                                      />
                                    </svg>
                                    {Object.keys(val)[0]} : {Object.values(val)[0]}
                                  </li>
                                
                                 )}
                                 { (moreprops > 6 && moreprops <= 9) && <li className="active" onClick={Seemore()}>See more</li>}
                                </ul>
                                 </>}
                                  
                              </div>
                            </div>
                            <div
                              className={
                                TabName == "owner"
                                  ? "content-inner active d-block"
                                  : "content-inner d-none"
                              }
                            >
                              <ul className="tab-bid">
                                {!isEmpty(InfoDetail) ? (
                                  <li>
                                    <div className="box-bid">
                                      <div className="image-bid">
                                        <img
                                          src={
                                            Tokens[TabName]?.owner?.Profile
                                              ? `${config.IMG_URL}/user/${Tokens[TabName]?.owner?.WalletAddress}/profile/${Tokens[TabName]?.owner?.Profile}`
                                              : require("../assets/images/author-detail-3.png")
                                          }
                                          alt="Image"
                                        />
                                      </div>
                                      <div className="infor">
                                        <div className="history">
                                          <span className="name">
                                            {payload?.DisplayName
                                              ? Name_showing(payload?.DisplayName)
                                              : address_showing(
                                                  payload?.WalletAddress
                                                )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ) : (
                                  <>{console.log("TOKENLIST",Tokens["owner"]?.list)}
                                 {Tokens["owner"]?.list?.map((data, key) => {
                                    return (
                                      <li>
                                        <div className="box-bid">
                                          <div className="image-bid">
                                            <img
                                              src={
                                                data?.Profile
                                                  ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                                  : require("../assets/images/author-detail-3.png")
                                              }
                                              alt="Image"
                                            />
                                          </div>
                                          <div className="infor">
                                            <div className="history">
                                              {
                                              // (data.PutOnSaleType ==
                                              //   "FixedPrice" ||
                                              //   data.PutOnSaleType ==
                                              //     "TimedAuction") &&
                                                   (
                                                <span className="price">
                                                {Tokens_Detail.NFTCreator === data.NFTOwner ? 
                                                  <>
                                                  {/* {data.NFTPrice} {data.CoinName} */}
                                                  Owned 
                                                  </> :
                                                  <>{data?.buyprice?.NFTPrice} {data?.buyprice?.CoinName}</>
                                                }
                                                </span>
                                              )}{" "}
                                              by{" "}
                                              <span className="name">
                                                {data?.DisplayName
                                                  ? Name_showing(data?.DisplayName)
                                                  : address_showing(
                                                      data?.NFTOwner
                                                    )}
                                              </span>
                                            </div>
                                            <div className="time">
                                              Available Token : {data.NFTBalance}
                                            </div>
                                            <div className="time">
                                              {moment(data.updatedAt).fromNow()}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                  </>
                                )}
                              </ul>
                            </div>
                            <div
                              className={
                                TabName == "bid"
                                  ? "content-inner active d-block"
                                  : "content-inner d-none"
                              }
                            >
                              <ul className="tab-bid">
                                { Tokens["bid"]?.list?.length>0 ?
                                Tokens["bid"]?.list?.map((data, key) => {
                                  { console.log("BIDDDDDDDDDD",Tokens["bid"]?.myowner,data.NFTQuantity,data.TokenBidAmt)}
                                  console.log("DATAAADATAADATAAA",data)
                                  return (
                                    <li>
                                      <div className="box-bid">
                                        <div className="image-bid">
                                          <img
                                            src={
                                              data?.Profile
                                                ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                                : require("../assets/images/author-detail-1.png")
                                            }
                                            alt="Image"
                                          />
                                        </div>
                                        <div className="infor">
                                          <div className="history">
                                            {
                                              <span className="price">
                                                {data.TokenBidAmt} {data.CoinName}
                                              </span>
                                            }{" "}
                                            by{" "}
                                            <span className="name">
                                              {data?.DisplayName
                                                ? Name_showing(data?.DisplayName)
                                                : address_showing(
                                                    data?.TokenBidderAddress
                                                  )}
                                            </span>
                                          </div>
                                          <div className="time">
                                              Bid for : { data?.Pending} Token
                                            </div>
                                          <div className="time">
                                            {moment(data.updatedAt).fromNow()}
                                          </div>
                                        </div>
                                      </div>
                                      {data.WalletAddress == accountAddress && (
                                        <>
                                          <Button
                                            className="tf-button edit_offer primary"
                                            disableRipple
                                            onClick={() =>
                                              POPUPACTION("dummy","Bid", data)
                                            }
                                          >
                                            Edit Offer
                                          </Button>
                                          <Button
                                            className="tf-button edit_offer secondary"
                                            disableRipple
                                            onClick={() =>
                                              POPUPACTION("dummy","CancelBid", data)
                                            }
                                          >
                                            Cancel Offer
                                          </Button>
                                        </>
                                      )}
                                      {data.WalletAddress !=
                                          

                                        Tokens["bid"]?.myowner?.WalletAddress &&
                                        Tokens["bid"]?.myowner?.WalletAddress ==
                                          accountAddress &&
                                         
                                          new Date(
                                            Tokens[TabName]?.myowner?.EndClockTime
                                          ).getTime() < Date.now() &&
                                           (
                                          <Button
                                            className="tf-button"
                                            disableRipple
                                            onClick={() =>
                                              POPUPACTION("dummy","Accept", data)
                                            }
                                          >
                                            Accept Offer
                                          </Button>
                                        )}
                                    </li>
                                  );
                                })
                                :
                                <h6 className="available_tokens"><span>No Bids Yet</span></h6>
                              }
                             
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="content-bottom">
                          {!isEmpty(Tokens[TabName]?.highbid) && (
                            <div className="heading">
                              <h6>Top bid</h6>
                              <div className="price">
                                <div className="icon">
                                  <i className="fab fa-ethereum"></i>
                                </div>
                                <span>
                                  {Tokens[TabName]?.highbid?.TokenBidAmt}{" "}
                                  {Tokens[TabName]?.highbid?.CoinName}
                                </span>
                              </div>
                              {Tokens[TabName]?.highbid?.WalletAddress !=
                                accountAddress &&
                                Tokens[TabName]?.owner?.WalletAddress ==
                                  accountAddress &&
                                Tokens[TabName]?.myowner?.PutOnSaleType ==
                                  "TimedAuction" &&
                                new Date(
                                  Tokens[TabName]?.myowner?.EndClockTime
                                ).getTime() < Date.now() && (
                                  <Button
                                    className="tf-button"
                                    onClick={() =>
                                      POPUPACTION("dummy",
                                        "Accept",
                                        Tokens[TabName]?.highbid
                                      )
                                    }
                                  >
                                    Accept
                                  </Button>
                                )}
                            </div>
                          )}
                          <div className="button">
                            {!isEmpty(InfoDetail) && (
                              <Button
                                className="tf-button"
                                onClick={() =>
                                  push(`/list/${payload?.WalletAddress}/${Id}`, {
                                    state: InfoDetail,
                                  })
                                }
                              >
                                List on Marketplace
                              </Button>
                            )}
                            {isEmpty(InfoDetail) &&
                              (Tokens_Detail?.ContractType?.toString() ===
                              "721" ? (
                                Tokens[TabName]?.myowner?.WalletAddress ==
                                accountAddress ? (
                                  Tokens[TabName]?.myowner?.PutOnSaleType ==
                                  "FixedPrice" ? (
                                    <Button
                                      className="tf-button"
                                      onClick={() =>
                                        POPUPACTION("dummy",
                                          "Cancel",
                                          Tokens[TabName]?.myowner
                                        )
                                      }
                                    >
                                      Cancel Now
                                    </Button>
                                  ) : Tokens[TabName]?.myowner?.PutOnSaleType ==
                                      "NotForSale" ||
                                    Tokens[TabName]?.myowner?.PutOnSaleType ==
                                      "UnlimitedAuction" ||
                                    (Tokens[TabName]?.myowner?.PutOnSaleType ==
                                      "TimedAuction" &&
                                      new Date(
                                        Tokens[TabName]?.myowner.EndClockTime
                                      ).getTime() < Date.now()) ? (
                                    <Button
                                      className="tf-button"
                                      onClick={() =>
                                        POPUPACTION("dummy", 
                                          "createorder",
                                          Tokens[TabName]?.myowner
                                        )
                                      }
                                    >
                                      Put on Sale
                                    </Button>
                                  ) : (
                                    Tokens[TabName]?.myowner?.PutOnSaleType ==
                                      "TimedAuction" &&
                                      new Date(Tokens[TabName]?.myowner?.ClockTime) > Date.now() ? (
                                      <a href="#" className="tf-button">
                                        {/* new Date(Tokens[TabName]?.myowner?.EndClockTime).getTime() > Date.now() */}
                                        Auction Not Started Yet
                                      </a>
                                    ):
                                    (new Date(Tokens[TabName]?.myowner?.EndClockTime).getTime() > Date.now() 
                                    &&  <a href="#" className="tf-button">
                                          Auction is Live Now
                                        </a>)
                                  )
                                ) : (
                                  Tokens[TabName]?.owner &&
                                  Tokens[TabName]?.owner?.WalletAddress !=
                                    accountAddress &&
                                  (Tokens[TabName]?.owner?.PutOnSaleType ==
                                  "FixedPrice" ? (
                                    <Button
                                      className="tf-button"
                                      onClick={() =>
                                        POPUPACTION("dummy","Buy", Tokens[TabName]?.owner)
                                      }
                                    >
                                      Buy Now
                                    </Button>
                                  ) : (
                                    Tokens[TabName]?.myBid?.WalletAddress ==
                                      accountAddress && (
                                      <Button
                                        className="tf-button"
                                        onClick={() =>
                                          POPUPACTION("dummy","CancelBid", Tokens[TabName]?.myBid)
                                        }
                                      >{console.log('biiiddds',Tokens[TabName],Tokens[TabName]?.myBid)}
                                        Cancel Bid
                                      </Button>
                                    )
                                  ))
                                )
                              ) : Tokens[TabName]?.myowner?.WalletAddress ==
                                Tokens[TabName]?.owner?.WalletAddress ? (
                                <>
                                  {Tokens[TabName]?.myowner?.PutOnSaleType ==
                                    "FixedPrice" && (
                                    <Button
                                      className="tf-button"
                                      onClick={() =>
                                        POPUPACTION("dummy",
                                          "Cancel",
                                          Tokens[TabName]?.myowner
                                        )
                                      }
                                    >
                                      Cancel Now
                                    </Button>
                                  )}
                                  {Tokens[TabName]?.myBid?.WalletAddress ==
                                  accountAddress ? (
                                    <Button
                                      className="tf-button"
                                      onClick={() =>
                                        POPUPACTION("dummy","Bid", Tokens[TabName]?.myBid)
                                      }
                                    >
                                      Edit Bid
                                    </Button>
                                  ) : (
                                    Tokens[TabName]?.myowner?.WalletAddress !=
                                      Tokens[TabName]?.owner?.WalletAddress && (
                                      <Button
                                        className="tf-button"
                                        onClick={() => POPUPACTION("dummy","Bid", {})}
                                      >
                                        Bid Now
                                      </Button>
                                    )
                                  )}
                                </>
                              ) : Tokens[TabName]?.owner?.PutOnSaleType ===
                                "FixedPrice" ? (
                                <Button
                                  className="tf-button"
                                  onClick={() =>
                                    POPUPACTION("dummy","Buy", Tokens[TabName].owner)
                                  }
                                >
                                  Buy Now
                                </Button>
                              ) : (
                                Tokens[TabName]?.myBid?.WalletAddress ==
                                  accountAddress && (
                                  <Button
                                    className="tf-button"
                                    onClick={() => POPUPACTION("dummy","CancelBid", Tokens[TabName]?.myBid)}
                                  >
                                    Cancel Bid
                                  </Button>
                                )
                              ))}
  
                            {isEmpty(InfoDetail) &&
                              (Tokens_Detail?.ContractType?.toString() ===
                              "721" ? (
                                Tokens[TabName]?.myowner?.WalletAddress ==
                                accountAddress ? (
                                  Tokens[TabName]?.myowner?.PutOnSaleType ==
                                    "FixedPrice" && (
                                    <Button
                                      className="tf-button"
                                      onClick={() =>
                                        POPUPACTION( "Change Price",
                                          "createorder",
                                          Tokens[TabName]?.myowner
                                        )
                                      }
                                    >
                                      Change Price
                                    </Button>
                                  )
                                ) : (
                                  Tokens[TabName]?.owner?.WalletAddress !=
                                    accountAddress &&
                                  (Tokens[TabName]?.owner?.PutOnSaleType ==
                                    "TimedAuction" &&
                                  new Date(Tokens[TabName].owner.EndClockTime)?.getTime() < Date.now() ? (
                                    <a href="#" className="tf-button">
                                      Auction End
                                    </a>
                                  ) : Tokens[TabName]?.highbid?.WalletAddress !=
                                      accountAddress &&
                                    Tokens[TabName]?.owner?.WalletAddress ==
                                      accountAddress ? (
                                    <Button
                                      className="tf-button"
                                      onClick={() =>
                                        POPUPACTION("dummy",
                                          "Accept",
                                          Tokens[TabName]?.highbid
                                        )
                                      }
                                    >
                                      Accept
                                    </Button>
                                  ) : Tokens[TabName]?.myBid?.WalletAddress ==
                                    accountAddress ? (
                                    <Button
                                      className="tf-button"
                                      onClick={() =>
                                        POPUPACTION("dummy","Bid", Tokens[TabName]?.myBid)
                                      }
                                    >
                                      Edit Bid
                                    </Button>
                                  ) : ((new Date(Tokens["All"]?.owner?.EndClockTime) > Date.now() &&
                                        new Date(Tokens["All"]?.owner?.ClockTime) > Date.now()) ?
                                        <Button
                                        className="tf-button"
                                      >
                                        Not Started Yet
                                      </Button>
                                    :<Button
                                      className="tf-button"
                                      onClick={() => POPUPACTION("dummy ","Bid", {})}
                                    >
                                      Bid now
                                    </Button>
                                  ))
                                )
                              ) : Tokens[TabName]?.myowner?.WalletAddress ==
                                Tokens[TabName]?.owner?.WalletAddress ? (
                                Tokens[TabName]?.owner?.PutOnSaleType ==
                                "FixedPrice" ? (
                                  <Button
                                    className="tf-button"
                                    onClick={() =>
                                      POPUPACTION("Change Price",
                                        "createorder",
                                        Tokens[TabName]?.myowner
                                      )
                                    }
                                  >
                                    Change Price
                                  </Button>
                                ) : (
                                  <Button
                                    className="tf-button"
                                    onClick={() =>
                                      POPUPACTION("dummy",
                                        "createorder",
                                        Tokens[TabName]?.myowner
                                      )
                                    }
                                  >
                                    Put on Sale
                                  </Button>
                                )
                              ) : Tokens[TabName]?.owner?.WalletAddress !=
                                  accountAddress &&
                                Tokens[TabName]?.highbid?.WalletAddress !=
                                  accountAddress &&
                                Tokens[TabName]?.owner?.WalletAddress ==
                                  accountAddress ? (
                                <Button
                                  className="tf-button"
                                  onClick={() =>
                                    POPUPACTION("dummy",
                                      "Accept",
                                      Tokens[TabName]?.highbid
                                    )
                                  }
                                >
                                  Accept
                                </Button>
                              ) : Tokens[TabName]?.myBid?.WalletAddress ==
                                accountAddress ? (
                                <Button
                                  className="tf-button"
                                  onClick={() =>
                                    POPUPACTION("dummy","Bid", Tokens[TabName]?.myBid)
                                  }
                                >
                                  Edit Bid
                                </Button>
                              ) : (
                                <Button
                                  className="tf-button"
                                  onClick={() => POPUPACTION(" dummy","Bid", {})}
                                >
                                  Bid Now{" "}
                                </Button>
                              ))}
  
                            {/*                                                                                                         
                                                      <a href="#" className="tf-button" data-toggle="modal" data-target="#popup_bid">Place your bid</a>
                                                      
                                                      <a href="#" className="tf-button">Buy Now</a> */}
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {isEmpty(InfoDetail) && (
              <section className="tf-explore-more">
                <div className="tf-container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="tf-heading">
                        <h4 className="heading mb-4">Explore More</h4>
                      </div>
                    </div>
                    {Explores.map((item, index) => (
                      <div
                      className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                   
                    >
                      <Card
                        product={item}
                        index={index}
                        LikeList={LikeList}
                        LikedTokenList={LikedTokenList}
                        setLikedTokenList={setLikedTokenList}
                        LikeForwardRef={LikeForwardRef}
                      />
                      </div>
                    ))}
                    <div className="col-md-12">
                        <div className="btn-loadmore wow fadeInUp" data-aos="fade-up">
                            <NavLink to="/explore" className="tf-button style-8 loadmore">Explore More <i className="far fa-long-arrow-right"></i></NavLink>
                        </div>
                    </div>
                  </div>
                  
                </div>
              </section>
            )}
          </>
        }
        <Footer />
      </>
    )
  }