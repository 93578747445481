import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';
import {  useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useContractProviderHook from "./../../actions/contractProviderHook";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { address_showing, isEmpty, Name_showing } from "../../actions/common";
import { BidApprove } from '../../actions/axioss/nft.axios';
import ImgAudVideo from "../../separate/ImgAudVideo";
import config from '../config/config'

export function Accept({ OpenPopup, bidder,closePop,bid, owner,  item,file,type,thumb,approvestatus }) {
    const { currency } = useSelector(state => state.LoginReducer)
    const { web3, accountAddress ,coinBalance } = useSelector(state => state.LoginReducer.AccountDetails);
    const { sellerFees } = useSelector(state => state.LoginReducer.ServiceFees);
    const ContractCall = useContractProviderHook()
    const  push  = useNavigate()
    // console.log('accepppttt',item)
    const { payload } = useSelector(state => state.LoginReducer.User)
    const [Btn, SetBtn] = useState('start')
    const [Error, SetError] = useState({})
    const [TokenQuantity, SetTokenQuantity] = useState('1')
    const [TokenBal, SetTokenBal] = useState(0)
    const[show6,setShow6]=useState(true);
    const [TokenBtn, SetTokenBtn] = useState("start");
    const handleClose6 = () => setShow6(false);
    const token_address  = (currency)?.filter(item=>item.label == bidder?.CoinName)?.pop()?.address ?? '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'
    
      console.log('rshfrstdhtdasdfasdfasdfasdfassdfasdfasd',item,bidder)
      useEffect(()=>{
        BalCal(token_address)
      },[])

      const BalCal  = async (data)  =>  {
        console.log("checkBalala",token_address,bidder?.TokenBidderAddress,data);
       let  TokenBal =  await ContractCall.Token_Balance_Calculation(data??token_address,bidder?.TokenBidderAddress)
       console.log("TOKENBALLL",TokenBal)
       SetTokenBal(TokenBal)
      }
  
     const YouWillGet = useMemo(() => { return ContractCall.price_calculation((bidder?.TokenBidAmt*TokenQuantity).toString()) }, [bidder?.TokenBidAmt,TokenQuantity])

    const Validation    =   async()  =>   {
      console.log("VALIDTOKENBID",(TokenQuantity * bidder?.TokenBidAmt) > TokenBal ,TokenQuantity, bidder?.TokenBidAmt,TokenBal)
      var Error = {}
      if(isEmpty(TokenQuantity)) Error.TokenQuantity  = "Must Select Atleast One Token"
      else if(Number(TokenQuantity) % 1 !== 0) Error.TokenQuantity  = "Token Quantity Must Be Valid"
      if(await ContractCall.Contract_Base_Validation())  Error.Wal  = await ContractCall.Contract_Base_Validation()
      if(!isEmpty(TokenQuantity)) {
      if((TokenQuantity * bidder?.TokenBidAmt) > web3.utils.fromWei(String(await ContractCall.allowance_721_1155(token_address,bidder?.TokenBidderAddress)?await ContractCall.allowance_721_1155(token_address,bidder?.TokenBidderAddress):0))) Error.Wal  = "Bidder Doesn't have enough Allowance"
      if((TokenQuantity * bidder?.TokenBidAmt) > TokenBal ) Error.Wal  = "Bidder Doesn't have enough Balance"
      if((TokenQuantity > bidder?.Pending)) Error.Wal  = `Token Quantity Must Be less Than ${bidder?.Pending}`
      }
      return Error
    }
    const TokenApproveCall = async () => {
      SetTokenBtn("process");
      const id = toast.loading("Approve Processing");
      const cont = await ContractCall.SetApproveStatus((item.ContractType == 721 || item.ContractType == "721") ? 'Sinle' : 'Multiple', item.ContractAddress);
      toast.update(id, {
          render: cont ? "Approved Successfully" : "Approved Failed",
          type: cont ? "success" : "error",
          isLoading: false,
          autoClose: 1000,
          closeButton:true,
          closeOnClick:true
      });
      if (cont.status) {
          SetTokenBtn("done");
          SetBtn('start')
      } else SetTokenBtn("try");
  };

    const FormSubmit = async () => {
        const id = toast.loading('Accepting Token on processing')
        SetError({})
        SetBtn('process')
       var error = await Validation()
        if (!isEmpty(error)) {
            toast.update(id, { render: Object.values(error)[0], type: 'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
            SetBtn('error')
            SetError(error)
        }
        else {
        toast.update(id, { render: 'Ready To Place Order', type: 'success', isLoading: false,autoClose:1000,closeButton:true,closeOnClick:true})    
          let cont = await ContractCall.accept_721_1155(bidder?.CoinName,
            bidder?.TokenBidderAddress,
            [item.NFTId,web3.utils.toWei(String(bidder?.TokenBidAmt*TokenQuantity)),TokenQuantity,bidder?.ContractType],
              bidder?.ContractAddress)
            if(cont){
              var FormValue = {
                TokenBidderAddress   : bidder?.TokenBidderAddress,
                NFTQuantity        : TokenQuantity,
                NFTId              : item.NFTId ,
                ContractAddress      : item.ContractAddress ,
                CollectionNetwork      : item.CollectionNetwork ,
                ContractType         : item.ContractType ,
                from                  : 'accept',
                item                 :  item,
                newOwner             :  {
                  HashValue : cont.HashValue,
                  NewTokenOwner : bidder?.TokenBidderAddress,
                  NFTQuantity    :   TokenQuantity,
                  NFTId : item.NFTId,
                  NFTOwner : owner?.NFTOwner,
                  PutOnSale   :   owner?.PutOnSale,
                  PutOnSaleType   : owner?.PutOnSaleType,
                  TP: owner.PutOnSaleType == "FixedPrice" ? owner?.NFTPrice : bidder?.NFTPrice,
                  CN: owner.PutOnSaleType == "FixedPrice" ? owner?.CoinName : bidder?.CoinName,
                  activity                  : 'Accept',
				          Category	:	item.Category,
                  New_EmailId : bidder?.EmailId,
                  Old_EmailId : payload?.EmailId,
                click : `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${bidder?.TokenBidderAddress}/${owner?.NFTId}`
                }
              }
              let Resp = await BidApprove(FormValue)
                if (Resp.success == 'success') {
                toast.update(id, { render: 'Accepting Token Successfully', type: 'success', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
                SetBtn('done')
                push(`/profile/${payload?.CustomUrl}`,{state:{Tab:'myitem'}});
              }
              else {
                toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
                SetBtn('try')
              }
            }
            else { 
              toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
              SetBtn('try')
         }
        
       
        }
    }

 
   const onChange = async(e,data)  => {
    SetError({})
    SetBtn('start')
    SetTokenQuantity(e.target.value)
   }

   useEffect(()=>{
    async function BalanceCheck(){
      var Nftbalance = await ContractCall.Current_NFT_Balance(owner,item)
      console.log('ownneerrr ittemmm',owner,item,Nftbalance);
      if(Nftbalance !== owner.NFTBalance){
        toast.warning("You won't buy at this moment please refresh you data");
        setTimeout(() => {
          push("/")
        }, 1000);
      }
    }
    BalanceCheck();
  },[item,owner])

    return <Modal
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    show={show6}
  >
    <Modal.Header>
    <button type="button" class="btn-close" aria-label="Close" onClick={closePop}></button>
      <Modal.Title id="contained-modal-title-vcenter">
     Accept Bid
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className='acceptbid'>
          <p className="pb-3">You are to accept bid for  <span>{Name_showing(item.NFTName)}</span> from <span> {address_showing(bidder?.TokenBidderAddress)}</span><br/> Bid for : <span>{Number(bidder.NFTQuantity)} Token</span></p>
          <h6 className="pb-4">{bidder?.TokenBidAmt + ' '+ bidder?.CoinName} for {Number(TokenQuantity)} Edition(s)</h6>
          <p className="text-left pb-4">Quantity</p>
          <input
                type="text"
                name="TokenQuantity"
                id="TokenQuantity"
                value={TokenQuantity}
                onChange={(e)=>onChange(e,'inp')}
                className="form-control"
                disabled={owner?.NFTBalance  ==  '1' || owner?.NFTBalance  ==  1? true : false }
                placeholder="Enter your quantity"
              />
          <div className='servicefee mt-4 d-flex justify-content-between'>
                      <p><b>Service Fee in %:</b></p>
                      <p> {web3.utils.fromWei(String(sellerFees))}% {bidder?.CoinName} </p>
              </div>
              <div className='servicefee mt-3 d-flex justify-content-between'>
                      <p><b>Royalty fee in %:</b></p>
                      <p> {item.NFTRoyalty}%</p>
              </div>
              <div className='servicefee mt-3 d-flex justify-content-between'>
                      <p><b>You will get :</b></p>
                      <p>{YouWillGet}{bidder?.CoinName}</p>
              </div> 
              <div className='connecwalletbtns d-flex justify-content-between mt-4'>
          {approvestatus === 'open' && <Button className="cancelpayment tf-button active btn btn-primary primary" disabled={(TokenBtn == 'process' || TokenBtn == 'done') ? true : false}
                                onClick={(TokenBtn == 'start' || TokenBtn == 'try') ? TokenApproveCall : null}
                                disableRipple>
                                {TokenBtn == 'start' && 'Approve'}
                                {TokenBtn == 'process' && 'In-Progress'}
                                {TokenBtn == 'try' && 'Try-Again'}
                                {TokenBtn == 'done' && 'Done'}</Button>}
        <Button className="connectwallet secondary tf-button active btn btn-primary"
          disabled={Btn == 'error' || Btn === "process" || Btn === "done" ? true : false}
          onClick={(Btn == 'start' || Btn === "try") ? FormSubmit : null}
      
         >
         {Btn == 'start' && 'Accept Bid'
                                   || Btn == 'try' && 'Try-Again'
                                   || Btn == 'error' && 'Error'
                                   || Btn == 'done' && 'Done'
                                   || Btn == 'process' && 'In-Progress'
                                   || Btn == "putonsale" && "List"
                               }</Button>
          </div>    
      </div>
    </Modal.Body>
  </Modal>
}