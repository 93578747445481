import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {CreateOrder} from "../../actions/axioss/nft.axios";
import useContractProviderHook from "./../../actions/contractProviderHook";
import ImgAudVideo from "../../separate/ImgAudVideo";
import config from '../config/config'

export function Cancel({OpenPopup,closePop,owner,types,file,type,thumb,item}) {
    // const AxiosFile = useAxiosFile()
    const push = useNavigate()
    const [Btn , SetBtn ] = useState('start')
    const[show9,setShow9]=useState(true);
    const handleClose9 = () => setShow9(false);
    const ContractCall = useContractProviderHook()
    const { web3, accountAddress } = useSelector(state => state.LoginReducer.AccountDetails);
    const { payload } = useSelector(state => state.LoginReducer.User)

    const FormSubmit    =   async()  =>  {
        SetBtn('process')
       const id = toast.loading('Cancel Your order')
        var error   = await  ContractCall.Contract_Base_Validation()
        if(error) {  
            toast.update(id, { render:error, type: 'error', isLoading: false,autoClose:1000,closeButton:true,closeOnClick:true })
            SetBtn('error')
     }
        else{
            if(types == "Cancel"){
                let cont = await ContractCall.cancel_order_721_1155(owner.NFTId)
                if(cont)
                {
                await Back_end(id,cont.HashValue)
                handleClose9()
                }
                else { 
                    toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false,autoClose:1000,closeButton:true,closeOnClick:true })
                    SetBtn('try')}
                }
        else{
            await Back_end(id,'')
        }
        
    }
}

    const Back_end = async(id,HashValue)=>{
        owner.HashValue = HashValue
        owner.NFTPrice   =   0
         owner.CoinName     =   ''
         owner.NFTId = owner.NFTId
         owner.PutOnSale    =   'true'
         owner.PutOnSaleType    =   'UnlimitedAuction'
         owner.activity = types == "Cancel"?"CancelOrder":"CancelAuction";
         owner.NFTOwner   =   accountAddress
         owner.Category	=	item.Category
         owner.EmailId	=	payload?.EmailId
                      owner.ContractType   =   item.ContractType
         owner.ContractAddress   =   item.ContractAddress
         owner.CollectionNetwork   =   item.CollectionNetwork 

         let Resp = await CreateOrder(owner)
         if (Resp.success == 'success'){ 
             toast.update(id, { render: "Cancelled Your Order Successfully", type: "success", isLoading: false,autoClose:1000,closeButton:true,closeOnClick:true })
             SetBtn('done')
            //  push(`/my-item/${payload.CustomUrl?payload.CustomUrl:payload.CustomUrl}`)
            closePop();
            window.location.reload();
          }
          else {
            toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false,autoClose:1000,closeButton:true,closeOnClick:true })
            SetBtn('try')
          }
    }

    useEffect(()=>{
      async function BalanceCheck(){
        var Nftbalance = await ContractCall.Current_NFT_Balance(owner,item)
        console.log('ownneerrr ittemmm',owner,item,Nftbalance);
        if(Nftbalance !== owner.NFTBalance){
          toast.warning("You won't buy at this moment please refresh you data");
          setTimeout(() => {
            push("/")
          }, 1000);
        }
      }
      BalanceCheck();
    },[item,owner])

   return  <Modal
   size="lg"
   aria-labelledby="contained-modal-title-vcenter"
   centered
   show={show9}
 >
   <Modal.Header>
   <button type="button" class="btn-close" aria-label="Close" onClick={closePop}></button>
     <Modal.Title id="contained-modal-title-vcenter">
     Cancel Order
     </Modal.Title>
   </Modal.Header>
   <Modal.Body>
  <div className='approvetransaction'>
     <p className='mt-4'>Cancel For Sale</p>
     <p className='nstlx'><b>{item.NFTPrice} {item.CoinName}</b></p>
     <div className='connecwalletbtns d-flex justify-content-between mt-5'>
         {/* <button className="connectwallet primary">Cancel Order</button> */}
         <Button 
         disabled    =   {Btn === "process"  ||  Btn ==="done" ? true : false} 
         className="cancelpayment primary tf-button active btn btn-primary" onClick={closePop}>Cancel</Button>
         <Button
                                    type        =   "button"
                                    className   =   "connectwallet secondary tf-button active btn btn-primary"
                                    disabled    =   {Btn == 'error' || Btn === "process"  ||  Btn ==="done" ? true : false} 
                                    onClick     =   {Btn == 'start' || Btn === "try" ? FormSubmit : null}
                                >   
                                {Btn == 'start' && 'Start' 
                                ||Btn == 'try' && 'Try-Again'
                                ||Btn == 'error' && 'Error' 
                                ||Btn == 'done' && 'Done' 
                                ||Btn == 'process' && 'In-Progress' 
                                 }
                                </Button>
         </div> 
  </div>
   </Modal.Body>
 </Modal>
}