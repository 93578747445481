import React from "react";
import { Link } from "react-router-dom";
import User from "../assets/images/banner.png";
import action_config from './config/config'
import NoData from "./seperatemodals/nodata";

export default function Following({datas,type,user}) {
//  console.log("sari sari",datas)
  return (
    <>
      <div className="inner-content follow">
        <h4 className="title-dashboard" data-aos="fade-right">

        {type == 'follower' ? 'Followers' : type == 'collection' ? 'Collection': 'Following'} 
        </h4>
    <div className="content-follow">
      {/* {console.log('collectionnn',datas,type)} */}
    {datas?.length==0 ? <>
    <div className="text-centre">
        <h3 className="load_datas">
          Loading...
        </h3>
        {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
        {/* <p>Nothing for ReSale at this time</p> */}
                <div className="load-more">
                {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                </div>
        </div>
    </>
    : datas?.length>0 ?
      datas?.map(data=> 
          <>
          {console.log('datttaa',data)}
          {type !== "collection" || user }
          <div className="card-author" data-aos="zoom-in-down">
            <div className="avatar">
            {type == 'collection' && <img src={data?.CollectionProfileImage ? action_config.IMG_URL+"/collection/"+data?.CollectionSymbol+'/'+data?.CollectionProfileImage  : require("../assets/images/banner.png")} alt="images" />}
            {(type == 'follower'||type == 'following') && <img src={data?.Profile ? action_config.IMG_URL+"/user/"+data?.WalletAddress+'/profile/'+data?.Profile  : require("../assets/images/banner.png")} alt="images" />}
            </div>
            <div className="name">
              {" "}
              <Link to={type == 'collection'
              ?`/collection/${data?.CollectionSymbol}`
              :`/my-item/${data?.CustomUrl}`
              } state={{data:data,from:true}}>{(data?.DisplayName)}</Link>{" "}

            </div>
            {/* <div className="details">
              <span>{data.items}</span> Items
            </div> */}
            <Link to={type == 'collection'
              ?`/collection/${data?.CollectionSymbol}`
              :`/my-item/${data?.CustomUrl}`
              } state={{data:data,from:true}} className="btn-follow tf-button active btn btn-primary">
             {type == 'follower' ? 'Follower' :type == 'collection' ? 'Collection': 'Following'} 
            </Link>
            {/* <Link to="#" className="option">
              <i className="far fa-ellipsis-h"></i>
            </Link> */}
          </div></>)
        :
        <NoData/>
        } 
        </div>
      </div>
    </>
  );
}
