import { useSelector } from 'react-redux';
import ERC721 from '../Abi/erc721.json'
import ERC1155 from '../Abi/erc1155.json'
import DETH from '../Abi/erc20.json'
import Market from '../Abi/market.json'
import config from '../views/config/config'
import Web3 from 'web3';
import Web3Utils from 'web3-utils'
import React, { useState } from 'react';
import * as Wagmi from 'wagmi'
// import useContractProvider from "./useContractProvider";

//coinbase
import { writeContract, prepareWriteContract } from '@wagmi/core'
import { createPublicClient, http, webSocket, createWalletClient } from 'viem'
import { mainnet, avalancheFuji } from 'viem/chains'
const publicClientt = createPublicClient({
    chain: config.CHAIN_ID == 1 ? mainnet : avalancheFuji,
    transport: http()
})
// const publicClient = createPublicClient({
//     chain: config.CHAIN_ID == 1 ? mainnet : avalancheFuji,
//     transport: config.CHAIN_ID == 1 ? ethtransport : avaxtransport
// })
const walletClient = createWalletClient({
    chain: config.CHAIN_ID == 1 ? mainnet : avalancheFuji,
    transport: http()
})

var web3s = new Web3(config.RPC_URL)


export default function useContractProviderHook() {
    // const ContractCall = useContractProvider();
    var Tokens = Wagmi.useToken({
        address: '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A',
    })
    const { accountAddress, web3, web3p, coinBalance, type } = useSelector(state => state.LoginReducer.AccountDetails);
    const { sellerFees, buyerFees } = useSelector(state => state.LoginReducer.ServiceFees);
    const [Transaction, setTransaction] = useState({})
    const [hash, setHash] = useState('')
    const avaxtransport = webSocket('wss://api.avax-test.network/ext/bc/C/ws')
    const ethtransport = webSocket("wss://eth-mainnet.ws.alchemyapi.io/ws/5mQV1GTqVFiP7JOdlI1dnhmCV5rmuMzw")
    const publicClient = createPublicClient({
        chain: config.CHAIN_ID == 1 ? mainnet : avalancheFuji,
        transport: http()
    })

    const Contract_Base_Validation = () => {
        if (!web3) return 'Connect Your Wallet'
        if (!accountAddress) return 'Connect Your Wallet'
        // if (!coinBalance) return "You Don't have Enough Balance"
        else return ''
    }

    const ReadContract_creation = async (...data) => {
        if (web3p) {
            var contract_value = await new web3p.eth.Contract(
                ...data
            );
            return contract_value;
        }
    }

    const contrat_connection = async (...data) => {
        console.log('ddddaaattaaaa', ...data, web3, accountAddress, web3p);
        if (web3) {
            var contract_value = await new web3.eth.Contract(
                ...data
            );
            return contract_value;
        }
    }

    const GetApproveStatus = async (data, Addr) => {
        try {
            var ConnectContract = await ReadContract_creation(data == 'Single' ? ERC721 : ERC1155, Addr)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .isApprovedForAll(accountAddress, config.TradeContract)
                    .call()
            return contract_Method_Hash

        }
        catch (e) {
            return 'error'
        }
    }

    const SetApproveStatus = async (data, Addr) => {
        try {
            console.log("type", type)
            if (type == "CoinbaseMobile") {
                const { hash } = await writeContract({
                    address: Addr,
                    abi: data == 'Single' ? ERC721 : ERC1155,
                    functionName: 'setApprovalForAll',
                    args: [config.TradeContract, true],
                    account: accountAddress
                })
                let receipt = await get_receipt(hash);
                console.log("receipt", receipt);
                var need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                }
                console.log("enna varra", need_data);
                return need_data;

            }
            else {
                var ConnectContract = await contrat_connection(data == 'Single' ? ERC721 : ERC1155, Addr)
                console.log("contractmethods", ConnectContract
                    .methods, ConnectContract)

                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .setApprovalForAll(config.TradeContract, true)
                        .send({
                            from: accountAddress
                        }).on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
                const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);

                var need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                }
                console.log("enna varra", need_data);
                return need_data;
            }



        }
        catch (e) {
            console.log("setaproveerr", e)
            return false
        }
    }
    const get_receipt = async (HashValue) => {
        let receipt;
        do {
            receipt = await web3s.eth.getTransactionReceipt(HashValue);
        } while (!receipt)
        // console.log("sdsadshadsadhfsa",receipt?.status,HashValue)
        if (receipt?.status) {
            console.log("return checking", receipt)
            return receipt
        }

    }
    const minting_721_1155 = async (...data) => {
        console.log("Multipledata", data);
        try {
            if (type == "CoinbaseMobile") {

                const { hash } = await writeContract({
                    address: config.TradeContract,
                    abi: Market,
                    functionName: 'minting',
                    args: [...data],
                    account: accountAddress
                })

                var receipt = await get_receipt(hash);
                var TokenCOunts = Web3Utils.hexToNumber(receipt.logs[2].topics[2])
                console.log("receipt", receipt);
                if (receipt) {
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                        tokenCounts: TokenCOunts
                    }
                    console.log("enna varra", need_data);
                    return need_data;
                }

            }
            else {
                const ConnectContract = await contrat_connection(Market, config.TradeContract)
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .minting(...data)
                        .send({ from: accountAddress })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })

                const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                console.log('reciepppttttt', receipt)
                var TokenCOunts = Web3Utils.hexToNumber(receipt.logs[2].topics[2])
                if (TokenCOunts) {
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                        tokenCounts: TokenCOunts
                    }
                    return need_data
                }
            }

        }
        catch (e) {
            console.log("Contract Error", e)
            return false
        }


    }
    // const tokensss = Wagmi.useToken({
    //     address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    //     // address: '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'?.toLowerCase(),

    //   })
    //   console.log("data>>>>>",tokensss)
    const approve_721_1155 = async (token_address, ...data) => {

        const token = token_address
        // console.log("TYPE====",token, type, token_address, ...data);

        try {
            if (type == "CoinbaseMobile") {
                console.log("INSIDE", accountAddress);
                console.log("LLLCHIAN", [...data]);
                try {

                    console.log("simulate Contracy : ", token, token_address)
                    const { request } = await publicClientt.simulateContract({
                        abi: [{
                            "constant": false,
                            "inputs": [
                                {
                                    "internalType": "address",
                                    "name": "spender",
                                    "type": "address"
                                },
                                {
                                    "internalType": "uint256",
                                    "name": "amount",
                                    "type": "uint256"
                                }
                            ],
                            "name": "approve",
                            "outputs": [
                                // {
                                //     "internalType": "bool",
                                //     "name": "",
                                //     "type": "bool"
                                // }
                            ],
                            "payable": false,
                            "stateMutability": "nonpayable",
                            "type": "function"
                        }],
                        address: token,
                        args: [...data],
                        chain: config.CHAIN_ID == 1 ? mainnet : avalancheFuji,
                        functionName: "approve",
                        account: accountAddress
                    })

                    // const { request } = await prepareWriteContract({
                    //         address: token_address,
                    //         abi: DETH,
                    //         functionName: "approve",
                    //         args: [...data],
                    //         account: accountAddress
                    // })

                    console.log("reqqq", request);

                    const { hash } = await writeContract(request)

                    // const { hash } = await writeContract({
                    //     address: token_address,
                    //     abi: DETH,
                    //     functionName: 'approve',
                    //     args: [...data]
                    // })

                    // console.log("HASHFOR", hash)

                    // const { hash } = useContractWrite({
                    //     address: token_address,
                    //     abi: DETH,
                    //     functionName: 'approve',
                    //     args: [...data],
                    //     account: accountAddress
                    //   })
                    // const hash = ContractCall.useContractProvider(token_address, accountAddress ,...data)

                    const receipt = await get_receipt(hash);
                    console.log("receipt", receipt);
                    if (receipt) {
                        var need_data = {
                            status: receipt.status,
                            HashValue: receipt.transactionHash,
                        }
                        console.log("enna varra", need_data);
                        return need_data;
                    }
                } catch (err) {
                    console.log("simulateContract", err);
                }

            }
            else {
                const ConnectContract = await contrat_connection(DETH, token_address)
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .approve(...data)
                        .send({ from: accountAddress })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
                const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                var need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                }
                return need_data
            }

        }
        catch (e) {
            console.log("ERrin approve", e)
            return false
        }
    }
    const Token_Balance_Calculation = async (token_Address, data) => {

        try {
            console.log('adddrreeeessss', token_Address, data)
            const ConnectContract = await ReadContract_creation(DETH, token_Address)
            var bidAMt = await ConnectContract.methods.balanceOf(data).call();

            return Number(web3.utils.fromWei(String(bidAMt)))
        }
        catch (e) {

            return 0
        }
    }
    var buy_bid_price_calculation = (val, decimal) => {
        console.log("val", val, decimal)
        var toMid = val * 1000000
        var serfee = (toMid / 100000000) * (web3p.utils.fromWei(String(buyerFees ? buyerFees : 1)) * 1000000)
        var totfee = serfee + toMid
        var tot2cont = web3.utils.toWei(String(Number(totfee / 1000000)).length > 18 ? String(Number(totfee / 1000000).toFixed(18)) : String(Number(totfee / 1000000)))
        var dec = decimal == 18 ? 18 : 18 - (decimal);
        var aprrove = ((tot2cont) / 10 ** dec)
        return (aprrove)
    }
    const cancel_order_721_1155 = async (data) => {
        try {
            if (type == "CoinbaseMobile") {
                const { hash } = await writeContract({
                    address: config.TradeContract,
                    abi: Market,
                    functionName: 'cancelOrder',
                    args: [data],
                    account: accountAddress
                })
                console.log("HASHFOR", hash)

                const receipt = await get_receipt(hash);
                console.log("receipt", receipt);
                if (receipt) {
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                    }
                    console.log("enna varra", need_data);
                    return need_data;
                }

            }
            else {

                var ConnectContract = await contrat_connection(Market, config.TradeContract)
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .cancelOrder(data)
                        .send({ from: accountAddress })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
                const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                var need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                }
                return need_data;
            }

        }
        catch (e) {
            return false
        }

    }
    var price_calculation = (data, roy) => {
        try {
            var price = web3.utils.toWei(data);
            // var weii = price*1e6
            // var ser_per = (weii/100000000)*((Wallet_Details.sellerfee/config.decimalvalues)*1000000)
            // var roy_per = (weii/100000000)*((roy)*1000000)
            // var mulWei = ((weii) - (ser_per+roy_per));
            // var getVal = Number(mulWei)/1e6;
            //console..log("you will get lower",price,weii,ser_per,roy_per,getVal);
            var service_from_val = ((price * (sellerFees)) / 1e20)
            var royal_from_val = ((price * (roy * 1e18)) / 1e20)
            var my_val = 0, my_val_royal = 0, getVal = 0;
            if (String(service_from_val).includes('.') && String(service_from_val).split('.').pop().length > 18)
                my_val = service_from_val.toFixed(18)
            else
                my_val = service_from_val

            if (String(royal_from_val).includes('.') && String(royal_from_val).split('.').pop().length > 18)
                my_val_royal = royal_from_val.toFixed(18)
            else
                my_val_royal = royal_from_val
            var whole_val = (((price)) - my_val) / 1e18
            if (String(whole_val).includes('.') && String(whole_val).split('.').pop().length > 18)
                getVal = whole_val.toFixed(18)
            else
                getVal = whole_val
            //console(data, getVal, sellerFees, my_val, my_val_royal)
            return getVal

        }
        catch (e) {
            return false
        }
    }
    const place_order_721_1155 = async (...data) => {
        try {
            if (type == "CoinbaseMobile") {
                const { hash } = await writeContract({
                    address: config.TradeContract,
                    abi: Market,
                    functionName: 'orderPlace',
                    args: [...data],
                    account: accountAddress
                })
                console.log("HASHFOR", hash)

                const receipt = await get_receipt(hash);
                console.log("receipt", receipt);
                if (receipt) {
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                    }
                    console.log("enna varra", need_data);
                    return need_data;
                }

            }
            else {
                var ConnectContract = await contrat_connection(Market, config.TradeContract)
                var contract_Method_Hash = await
                    ConnectContract.methods
                        .orderPlace(...data)
                        .send({ from: accountAddress })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
                const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                var need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                }
                return need_data
            }

        }
        catch (e) {
            console.log("enakenemnfsd", e)
            return false
        }

    }
    const buy_721_1155 = async (Send, CoinName, ...data) => {
        try {

            console.log("BUYCONTRACT", CoinName === config.COIN_NAME, config.COIN_NAME, Send, config.CHAIN_ID)
            const ConnectContract = await contrat_connection(Market, config.TradeContract)
            if (CoinName === config.COIN_NAME) {
                if (type == "CoinbaseMobile") {




                    const { request } = await publicClient.simulateContract({
                        address: config.TradeContract,
                        abi: Market,
                        functionName: 'saleToken',
                        args: [...data],
                        account: accountAddress,
                        value: Send,
                    })
                    const { hash } = await writeContract(request)
                    console.log("HASHFOR", hash)

                    const receipt = await get_receipt(hash);
                    console.log("receipt", receipt);
                    if (receipt) {
                        var need_data = {
                            status: receipt.status,
                            HashValue: receipt.transactionHash,
                        }
                        console.log("enna varra", need_data);
                        return need_data;
                    }

                }
                else {

                    var contract_Method_Hash = await
                        ConnectContract
                            .methods
                            .saleToken(...data)
                            .send({
                                from: accountAddress,
                                value: Send
                            })
                            .on('transactionHash', (transactionHash) => {
                                return transactionHash
                            })
                }

            }
            else {
                if (type == "CoinbaseMobile") {
                    console.log("COINNNNNNNNN")
                    const { request } = await publicClient.simulateContract({
                        address: config.TradeContract,
                        abi: Market,
                        functionName: 'saleWithToken',
                        args: [CoinName, ...data],
                        account: accountAddress
                    })
                    const { hash } = await writeContract(request)

                    console.log("HASHFOR", hash)

                    const receipt = await get_receipt(hash);
                    console.log("receipt", receipt);
                    if (receipt) {
                        var need_data = {
                            status: receipt.status,
                            HashValue: receipt.transactionHash,
                        }
                        console.log("enna varra", need_data);
                        return need_data;
                    }

                }
                else {

                    var contract_Method_Hash = await
                        ConnectContract
                            .methods
                            .saleWithToken(CoinName, ...data)
                            .send({ from: accountAddress })
                            .on('transactionHash', (transactionHash) => {
                                return transactionHash
                            })
                }

            }

            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.log("ERinbuy", e)
            return false
        }

    }
    const allowance_721_1155 = async (token_Address, data) => {

        try {
            const ConnectContract = await ReadContract_creation(DETH, token_Address)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .allowance(data, config.TradeContract)
                    .call()
            return contract_Method_Hash

        }

        catch (e) {
            return false
        }

    }
    const accept_721_1155 = async (...data) => {
        try {
            if (web3 != null) {
                if (type == "CoinbaseMobile") {
                    const { hash } = await writeContract({
                        address: config.TradeContract,
                        abi: Market,
                        functionName: 'acceptBId',
                        args: [...data],
                        account: accountAddress
                    })
                    console.log("HASHFOR", hash)

                    const receipt = await get_receipt(hash);
                    console.log("receipt", receipt);
                    if (receipt) {
                        var need_data = {
                            status: receipt.status,
                            HashValue: receipt.transactionHash,
                        }
                        console.log("enna varra", need_data);
                        return need_data;
                    }

                }
                else {
                    const ConnectContract = await contrat_connection(Market, config.TradeContract)
                    var contract_Method_Hash = await
                        ConnectContract
                            .methods
                            .acceptBId(...data)
                            .send({ from: accountAddress })
                            .on('transactionHash', (transactionHash) => {
                                return transactionHash
                            })
                    const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                    }
                    return need_data
                }

            }
        }
        catch (e) {

            return false
        }

    }

    const GetOwner = async (data, Addr, Tokenaddr) => {
        console.log('functioninputtt', data, Addr, Tokenaddr)
        try {
            var ConnectContract = await ReadContract_creation(data == 'Single' ? ERC721 : ERC1155, Addr)
            console.log('coooonnnnn', ConnectContract)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .ownerOf(Tokenaddr)
                    .call()
            return contract_Method_Hash
        }
        catch (e) {
            console.log('errrorrrr', e)
            return 'error'
        }
    }

    const GetContractOwner = async (data, Addr) => {
        console.log('functioninputtt', Addr)
        try {
            var ConnectContract = await ReadContract_creation(data == 'Single' ? ERC721 : ERC1155, Addr)
            console.log('coooonnnnn', ConnectContract)
            var contractowner = await
                ConnectContract
                    .methods
                    .owner()
                    .call()
            return contractowner

        }
        catch (e) {
            console.log('errrorrrr', e, String(e))
            return 'error'
        }
    }

    const Current_NFT_Balance = async (ownerdet, data) => {

        try {
            console.log("data.ContractType", data.ContractType, data.ContractAddress, ownerdet.NFTId)
            var currbalance;
            if ((data.ContractType === "721" || data.ContractType === 721)) {
                const ConnectContract = await ReadContract_creation(ERC721, data.ContractAddress)
                console.log("CONNECTCONT", ConnectContract.methods)
                currbalance = await ConnectContract.methods.ownerOf(ownerdet.NFTId).call();
                console.log('ballllaanneceeee1111', currbalance, ownerdet.NFTOwner)
                if ((String(currbalance).toLowerCase()) === (String(ownerdet.NFTOwner).toLowerCase())) { return '1'; }
                else {
                    let payload = {
                        NFTId: ownerdet.NFTId,
                        NFTOwner: ownerdet.NFTOwner,
                        NFTBalance: "0",
                    }
                    // var balupd = await NftbalanceUpdate(payload);
                }
                console.log('ballllaanneceeee', currbalance)
            }
            else {
                const ConnectContract = await ReadContract_creation(ERC1155, data.ContractAddress)
                currbalance = await ConnectContract.methods.balanceOf(ownerdet.NFTOwner, ownerdet.NFTId).call();
                console.log('ballllaanneceeee', currbalance)
                if (currbalance !== null && currbalance !== undefined) {
                    let payload = {
                        NFTId: ownerdet.NFTId,
                        NFTOwner: ownerdet.NFTOwner,
                        NFTBalance: currbalance,
                    }
                    // var balupd = await NftbalanceUpdate(payload);
                    console.log("check other balan 3", payload)


                }
                console.log('ballllaanneceeee', currbalance)
            }
            return String(currbalance);
        }
        catch (e) {
            console.log('errorrrr,e', e)
            return 0
        }
    }

    const buy_Claim = async (...data) => {
        console.log("Multipledata", data);
        try {
            if (type == "CoinbaseMobile") {

                const { hash } = await writeContract({
                    address: config.TradeContract,
                    abi: Market,
                    functionName: 'airDrop',
                    args: [...data],
                    account: accountAddress
                })

                var receipt = await get_receipt(hash);
                var TokenCOunts = Web3Utils.hexToNumber(receipt.logs[2].topics[2])
                console.log("receipt", receipt);
                if (receipt) {
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                        tokenCounts: TokenCOunts
                    }
                    console.log("enna varra", need_data);
                    return need_data;
                }

            }
            else {
                const ConnectContract = await contrat_connection(Market, config.TradeContract)
                var contractobj = await
                    ConnectContract
                        .methods
                        .airDrop(...data)
                var gasprice = await web3.eth.getGasPrice();
                var gas_estimate = await contractobj.estimateGas({ from: accountAddress })

                console.log("asdasdasdasdas", gas_estimate, gas_estimate, web3.utils.toHex(gas_estimate), web3.utils.toHex(gasprice));
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .airDrop(...data)
                        .send({
                            from: accountAddress, gas: web3.utils.toHex(gas_estimate),
                            gasPrice: web3.utils.toHex(gasprice)
                        })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
                const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                console.log('reciepppttttt', receipt)
                var TokenCOunts = Web3Utils.hexToNumber(receipt.logs[0].topics[3])
                if (TokenCOunts) {
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                        tokenCounts: TokenCOunts
                    }
                    return need_data
                }
            }
        }
        catch (e) {
            console.log("Contract Error", e)
            return false
        }



    }


    return {
        Contract_Base_Validation,
        GetApproveStatus,
        SetApproveStatus,
        minting_721_1155,
        approve_721_1155,
        Token_Balance_Calculation,
        buy_bid_price_calculation,
        cancel_order_721_1155,
        price_calculation,
        place_order_721_1155,
        buy_721_1155,
        allowance_721_1155,
        accept_721_1155,
        GetOwner,
        GetContractOwner,
        Current_NFT_Balance,
        buy_Claim
    };




}
