
import Web3 from "web3";
import Config from "../config/config";
import erc20Abi from '../../Abi/erc20.json'
import marketAbi from '../../Abi/market.json'
import { EthereumProvider } from '@walletconnect/ethereum-provider'

//coinbase
import { mainnet,avalancheFuji} from '@wagmi/core/chains'
import { createPublicClient, http } from 'viem'
import { getAccount ,switchNetwork} from '@wagmi/core'



export const connectWallet = async (type) => {
  console.log("Wallet Type",type)
  var accountDetails = {}
  var web3Obj = {}
  
  if (type == "MetaMask" || type == 'BinanceWallet' || type == 'Coinbase' || 'TrustWallet' ) {
    web3Obj = await MetamaskWallet(type)
    console.log("webobj",web3Obj)
    
  }
  if(type == 'CoinbaseMobile'){
    web3Obj = await CoinBaseWallet(type)
  }
  if( type == 'WalletConnect'  ){
     web3Obj = await WalletConnect(type)
  }
  if (web3Obj) {
    try {
      var web3p = new Web3(Config.RPC_URL)
      console.log("one",type);
      if(type == "CoinbaseMobile"){
        const {address} = getAccount()
        var coinBalance = await web3p.eth.getBalance(address);
        console.log("coinbalance",coinBalance)
        accountDetails.accountAddress=address;
        accountDetails.coinBalance = coinBalance;
        accountDetails.web3 = web3Obj;
        accountDetails.web3p = web3p
        accountDetails.tokenBalance =0
        accountDetails.type="CoinbaseMobile"
        localStorage.setItem("accountInfo", address)
            localStorage.setItem('walletConnectType', type)
        return accountDetails;
      }else{
        const accounts = await web3Obj.eth.getAccounts();
        console.log("two",accounts[0]?.toString()?.toLowerCase(),accounts);
        accountDetails.accountAddress = accounts[0]?.toString()?.toLowerCase();
        console.log("three", accountDetails.accountAddress);
        accountDetails.coinBalance = await web3Obj.eth.getBalance(accountDetails.accountAddress);
        console.log("4");
        accountDetails.web3p = web3p;
        accountDetails.web3 = web3Obj;
        accountDetails.tokenBalance =0
        return accountDetails;
      }
     
    }
    catch (e) {
      console.log("find ee",e)
      return accountDetails;
    }
  }
}

export const MetamaskWallet = async (type) => {
  var web3 
  try {
    if (window.ethereum && type == 'MetaMask') {
      web3 = new Web3(window.ethereum);
      if(window.ethereum.isMetaMask == true){
        const chainId = await web3.eth.getChainId();

        if (parseInt(chainId) != parseInt(Config.CHAIN_ID)) {
            chainIdCheck()
        }
          await window.ethereum.enable().then(async () => {
            // User has allowed account access to DApp...
            const accounts = await web3.eth.getAccounts();
            const account = accounts[0].toString().toLowerCase();
            localStorage.setItem("accountInfo", account)
            localStorage.setItem('walletConnectType', type)
          });
          
      }
      else{
        alert("Please Uninstall CoinbaseWallet or Connect to Coinbase")
        return false;
      }

     
    }


     if(window.BinanceChain && type == 'BinanceWallet'){
      web3 = new Web3(window.BinanceChain);
      const chainId = await web3.eth.getChainId();

      if (parseInt(chainId) != parseInt(Config.CHAIN_ID)) {
        chainIdCheck()
 
      }
        await window.BinanceChain.enable().then(async () => {
          // User has allowed account access to DApp...
          const accounts = await web3.eth.getAccounts();
          const account = accounts[0].toString().toLowerCase();
          localStorage.setItem("accountInfo", account)
          localStorage.setItem('walletConnectType', type)
        });
    }


     if(window.ethereum && type == 'TrustWallet'){
      web3 = new Web3(window.ethereum);
        const chainId = await web3.eth.getChainId();

        if (parseInt(chainId) != parseInt(Config.CHAIN_ID)) {
          chainIdCheck()
        }
          await window.ethereum.enable().then(async () => {
            // User has allowed account access to DApp...
            const accounts = await web3.eth.getAccounts();
            const account = accounts[0].toString().toLowerCase();
            localStorage.setItem("accountInfo", account)
            localStorage.setItem('walletConnectType', type)
          });
      
    }


     if(window.ethereum && type == 'Coinbase'){


      console.log("coinbaseeeeeeeee",window.ethereum);
      web3 = new Web3(window.ethereum);
      // debugger
      const chainId = await web3.eth.getChainId();
      console.log("accountDetails type idcoinbase",chainId)
      if (parseInt(chainId) != parseInt(Config.CHAIN_ID)) {
        chainIdCheck()
        // return true
      }

      // var webb3 = new web3(window.ethereum)
      // setweb(webb3)
      await window.ethereum.enable().then(async (res) => {
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0].toString().toLowerCase();
        localStorage.setItem("accountInfo", account)
        localStorage.setItem('walletConnectType', type)
      })
      // await window.ethereum
      // .enable().then(async (res) => {
      //     // User has allowed account access to DApp...
      //     const accounts = await web3.eth.getAccounts();
      //     const account = accounts[0].toString().toLowerCase();
      //     localStorage.setItem("accountInfo", account)
      //     localStorage.setItem('walletConnectType', type)
      //   });
    }
   
  
    
  } catch (e) {
    console.log("accountDetails type id1 last",e)
  }
  console.log("return web3;",web3);
  return web3;

}


export const WalletConnect = async (type) => {
  
  console.log("wcccc");
  const CHAIN_ID = Config.CHAIN_ID;
  try{
    if(localStorage.getItem("accountInfo")){
 
      const provider = await EthereumProvider.init({
        projectId: 'b8a1daa2dd22335a2fe1d2e139980ae0', // required
        chains: [CHAIN_ID], // required
        showQrModal: true // requires @walletconnect/modal
      })
      await provider.enable()
  
      var web3 = new Web3(provider);
      const accounts = await provider.request({
        method: 'eth_requestAccounts',
      });
        var account = accounts[0].toString();
        localStorage.setItem("accountInfo", account)
          localStorage.setItem('walletConnectType', type)
          return web3; 
    }
    else{
      
      const provider = await EthereumProvider.init({
        projectId: 'b8a1daa2dd22335a2fe1d2e139980ae0', // required
        chains: [CHAIN_ID], // required
        showQrModal: true // requires @walletconnect/modal
      })
      await provider.connect()
      
      var web3 = new Web3(provider);
      const accounts = await provider.request({
        method: 'eth_requestAccounts',
      });
        var account = accounts[0].toString();
        localStorage.setItem("accountInfo", account)
          localStorage.setItem('walletConnectType', type)
         
          return web3;
    }
  }
  catch(err){
    console.log(err)
  }
 
  }


export const CoinBaseWallet = async (type) => {
  try{
    console.log("connect the mobile");
    const publicClient = createPublicClient({
        chain: Config.CHAIN_ID== 1 ? mainnet : avalancheFuji,
        transport: http()
      })
      const chainId = await publicClient.getChainId()
const network=""

      if(Number(chainId) !== Number(Config.CHAIN_ID)){
        network = await switchNetwork({
           chainId:Config.CHAIN_ID,
         })
         console.log("CCCCCHHHHAAIIN",network)
   }
        var web3 = new Web3(publicClient);
    console.log("provider",web3)

 return web3;

}
catch(err){
    console.log('connect error',err)
}


}

const chainIdCheck = async (e) => {
  // Check if MetaMask is installed
  // MetaMask injects the global API into window.ethereum
  const hexString = Config.CHAIN_ID.toString(16);
  // console.log("hexString",hexString);
  if (window.ethereum) {
    try {
      // check if the chain to connect to is installed

      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: "0x" + hexString }], // chainId must be in hexadecimal numbers
      });
      return true;
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: "0x" + hexString,
                rpcUrl: Config.RPC_URL,
              },
            ],
          });
          return true;
        } catch (addError) {
          console.error(addError);
        }
      }
      console.error(error);
    }
  } else {
    // if no window.ethereum then MetaMask is not installed
    //console('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
    return false;

  }
}

export const getServiceFees = async () => {
  var rpcObj = new Web3(Config.RPC_URL)   
  var fees = {}
  if(rpcObj){
    try{
      var marketObj = new rpcObj.eth.Contract(
        marketAbi,
        Config.TradeContract
      );
      var servicefees = await marketObj.methods.getServiceFee().call()
      // console.log("servicefees",servicefees);
      fees.buyerFees = servicefees[0]
      fees.sellerFees = servicefees[1]
      
      return fees;
    }
    catch(e){
      //console("service fees catch blok running",e)
    }
  }
}
