import React from "react";
import authorImg from "../assets/images/banner.png";
import productImg from "../assets/images/product52.jpg";
import ethImg from "../assets/images/ethe.svg";
import Countdown from "react-countdown";
import { NavLink, useNavigate, Link } from "react-router-dom";
import ImgAudVideo from "../separate/ImgAudVideo";
import config from "./config/config";
import { address_showing,Name_showing} from "../actions/common";
import { useSelector,useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { LikeRef } from "./seperatemodals/LikeRef";
import { isEmpty } from "../actions/common";

export default function Card({
  product,
  index,
  LikeList,
  LikedTokenList,
  setLikedTokenList,
  LikeForwardRef,
  from,
}) {
  console.log("likrreeeecardd",  product,
  );
  const { accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails  
  );

  const { liked } = useSelector(
    (state) => state.LoginReducer.Liked  
  );
  

  const dispatch=useDispatch()
  const LikeAction = async () => {
    // console.log("ksgfsdga", LikeForwardRef, accountAddress, product);
    if (accountAddress) {
      var check = await LikeForwardRef.current.hitLike(product);
      toast.success("you " + check + "d token", { autoClose: 500,closeButton:true,closeOnClick:true });
      dispatch({
        type: 'Liked_Service',
        Liked_Service: {
          Liked: {liked : !liked}
        }
    })
      // window.location.reload();
    } else {
      toast.error("Connect Wallet", { autoClose: 1000,closeButton:true,closeOnClick:true });
      dispatch({
        type: 'Liked_Service',
        Liked_Service: {
          Liked: {liked : !liked}
        }
    })
    }
  };

  let renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Auction Completed!</span>;
    } else {
      return (
        <span>
          {" "}
          <span className="hourds">{formatTime(days)} d</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(hours)} h</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(minutes)} m</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(seconds)} s</span>{" "}
        </span>
      );
    }
  };

  let formatTime = (time) => {
    return String(time).padStart(2, "0");
  };
  const push = useNavigate();
  return (
    <div  data-aos="zoom-in-up" >
      {index === 0 && (
        <LikeRef
          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
          LikeList={LikeList}
        />
      )}
      {/* {product.map((data,index) => { return( */}
      <Link
              to={ product.from !== "othercollection" ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : accountAddress === product?.NFTCreator ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : "#"
            }
            state={{data: (from == "usercollection" ? product : null)}}
              className="tag"
            >
        <div className="sc-product style1">
          <div className="card_frame"></div>
          <div className="blur_shadow"></div>
          {product.PutOnSaleType === "TimedAuction" &&
              new Date(product.EndClockTime).getTime() >= Date.now() && (
                <>
                
                <div className="featured-countdown">
                <p className="rem_title">Remaining Time</p>
                    {/* <Countdown
                      date={product.EndClockTime}
                      // autoStart={true}
                      renderer={renderer}
                    /> */}
                    {new Date(product?.ClockTime) > Date.now() ? (
                            <span className="js-countdown">Auction Starts in {Math.ceil((new Date(product.ClockTime).getTime() - new Date(Date.now()).getTime()) / (1000 * 3600 * 24))} Days</span>
                        )
                        :
                        ((new Date(product?.EndClockTime) > Date.now()) ?
                            <span className="js-countdown">
                              {new Date(
                                product?.EndClockTime
                              ).getTime() >= Date.now() && (
                                <Countdown
                                  date={product?.EndClockTime}
                                  // autoStart={true}
                                  renderer={renderer}
                                />
                              )}
                            </span>
                            :
                          <span className="js-countdown">Auction Ended</span>
                        )}
                </div>
                </>
              )}
          <div className="card-label">
            <p><Link to={`/profile/${product?.Creator_CustomUrl}`}>
                      {from === "usercollection"
                        ? address_showing(
                            product.OwnerName
                              ? product.OwnerName
                              : product.NFTOwner
                          )
                        : product &&
                          (!isEmpty(product?.NFT_OWner?.DisplayName)
                            ? Name_showing(product?.NFT_OWner?.DisplayName )
                            : product?.NFTOwner?.substring(0, 5) +
                              "..." +
                              product?.NFTOwner?.substring(38, 42))}
                    </Link></p>
          </div>
          <div className="top">
         
            {/* {console.log('adgvhkdgsk',product?.NFTName?.length)} */}
            {from !== "usercollection" &&
              (LikedTokenList?.some(
                (value) => value.NFTId === product.NFTId
              ) ? (
                <div className="wish-list active">
                  <Link
                    to="#"
                    className="heart-icon"
                    onClick={() => LikeAction()}
                  ></Link>
                </div>
              ) : (
                <div className="wish-list">
                  <Link
                    to="#"
                    className="heart-icon"
                    onClick={() => LikeAction()}
                  ></Link>
                </div>
              ))}
          </div>
          <div className="features">
            <div
              className="product-media  audImgVid"
              onClick={() => {
              !product?.CompressedFile?.includes(".mp3") && 
              push(
                  `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}`,{state:from=='usercollection' ? product : null}
                );
              }}
            >
              {/* <NavLink to="/details"> */}

              {/* {console.log(
                "tokenntypeee",
                product?.CompressedFile,
                product,
                product?.CompressedFile?.includes(".mp3")
              )} */}
              {from === "usercollection" ? (
                 <iframe style={{ background: 'url(' + product?.Image + ')',backgroundRepeat: "no-repeat",
                 backgroundSize: "100% 100%",maxHeight: 288,minHeight: 288,minWidth: 288,maxWidth: 288,borderRadius:15}} height="200" width="300" title="Iframe Example" id="myiFrame"></iframe> 
                // <object data={product?.Image} width="288" height="288"></object>
                //  <iframe style={{ background: 'url(' + product?.Image + ')',backgroundRepeat: "no-repeat",
                //  backgroundSize: "100% 100%",maxHeight: 288,minHeight: 288,minWidth: 288,maxWidth: 288,borderRadius:15}} height="200" width="300" title="Iframe Example" id="myiFrame"></iframe> 
                // <img src={product?.Image} className="img-fluid" />
              ) : (
                <ImgAudVideo
                  file={`${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`}
                  origFile={`${config.IMG_URL}/nft/${product?.NFTCreator}/Original/NFT/${product?.OriginalFile}`}
                  thumb={`${config.IMG_URL}/nft/${product.NFTCreator}/Compressed/NFT_THUMB/${product?.CompressedThumbFile}`}
                  type={
                    product?.CompressedFile
                      ? product?.CompressedFile?.includes(".webp")
                        ? "image"
                        : product?.CompressedFile.includes(".webm")
                        ? "video"
                        : "audio"
                      : product?.CompressedFile
                  }
                />
              )}

              {/* <img src={product.CompressedFile} alt="images" /> */}
              {/* </NavLink> */}
            </div>
            {/* {console.log('timeeeee',product)} */}
          

            {/* <div className="rain-drop1"><img src={require("../assets/images/icon/rain1.svg")} alt="images" /></div>
                                    <div className="rain-drop2"><img src={require("../assets/images/icon/rain2.svg")} alt="images" /></div> */}
          </div>
          <div className="bottom">
            <div className="details-product">
              <div className="author">
                {/* <div className="avatar">
                  <img src={product?.Creator_Profile?`${config.IMG_URL}/user/${product.NFTCreator}/profile/${product.Creator_Profile}`:require("../assets/images/author-detail-3.png")} alt="images" />
                </div> */}
                <div className="content">
                  {/* <div className="position">
                    {from === "usercollection" ? product.from === "othercollection" ? "Creator" : "Owner" : "Creator"}
                  </div> */}
                  <div className="name">
                  <Link
              to={ product.from !== "othercollection" ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : accountAddress === product?.NFTCreator ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : "#"
            }
            state={{data: (from == "usercollection" ? product : null)}}
              className="tag"
            >
              {product?.NFTName?.length>13 ? product?.NFTName?.substring(0,10) + "..." : product?.NFTName}
            </Link>
                    
                  </div>
                </div>
              </div>
              {  (product.PutOnSaleType == "FixedPrice" || product.PutOnSaleType == "TimedAuction" || product?.highbidamount) ?
              <div className="current-bid">

                <div className="subtitle">
                  {product.PutOnSaleType == "FixedPrice"
                    ? "Sale For"
                    : product.PutOnSaleType == "TimedAuction"
                    ? "Bid Start From"
                    : product.PutOnSale == "false" &&
                      product?.highbidamount &&
                      "High bid"}
                </div>
                <div className="price">
                  <span className="cash">
                    {product.PutOnSaleType == "FixedPrice" ? (
                      `${product?.NFTPrice}  ${product?.CoinName}`
                    ) : product.highbidamount ? (
                      `${product?.highbidamount} ${product?.highbidcoin}`
                    ) : product.PutOnSaleType == "TimedAuction" ? (
                      `${product.NFTPrice} ${product?.CoinName}`
                    ) : (
                      <></>
                    )}
                  </span>
                  {/* {((product?.NFTPrice !== "" && product?.NFTPrice !== "0" && product?.NFTPrice !== 0 )|| product?.highbid) && (
                    <span className="icon">
                      <img src={ethImg} alt="images" />
                    </span>
                  )} */}
                </div>
              </div>
              :
              <div className="current-bid">
                
                <div className="subtitle">
                 Auction
                </div>
                <div className="price text-center">
                  <img src={require('../assets/images/endless.png')} style={{width:25,height:25,marginTop:5}} />
                </div>
              </div>}
              {/* <div className="current-bid">

                
                <div className="subtitle">
                 Auction
                </div>
                <div className="price text-center">
                  <img src={require("../assets/images/endless.png")} style={{width:25,height:25,marginTop:5}} />
                </div>
              </div> */}
            </div>
            <div className="product-button">
              <Link
                to={ product.from !== "othercollection" ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : accountAddress === product?.NFTCreator ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : "#"
              }
              state={{data: (from == "usercollection" ? product : null)}}
             
                data-toggle="modal"
                data-target="#popup_bid"
                className="tf-button"
              >
                {" "}
                <span className="icon-btn-product"></span>
                {/* {console.log("tokennnnnn",product,accountAddress)} */}
                {product?.NFTOwner == accountAddress
                  ? "Owned"
                  : product?.PutOnSaleType == "FixedPrice"
                  ? "Buy Now"
                  : "Place Bid"}
              </Link>
            </div>
          </div>
          {console.log("productttt",product)}
        

        </div>
        </Link>

    </div>
  );
}
