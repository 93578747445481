import React, { useEffect, useState } from "react";
import Header from "../separate/Header";
import Footer from "../separate/Footer";
import poster from "../assets/images/airdrop/poster.png";
import { IoIosArrowRoundDown } from "react-icons/io";
import FreeNFT from "../assets/images/airdrop/get_free_NFTs.png";
import ContinuousEarning from "../assets/images/airdrop/Continuous_Earnings.png";
import Exclusive from "../assets/images/airdrop/exclusive_nft.png";
import Seamless from "../assets/images/airdrop/seamless_exp.png";
import Minimal from "../assets/images/airdrop/minimal_gas.png";
import Community from "../assets/images/airdrop/comminity_building.png";
import CommunityAfter from "../assets/images/airdrop/community_after.png";
import config from "./config/config";
import VisitGolden from "../assets/images/airdrop/visit_golden.png";
import TriggerMinting from "../assets/images/airdrop/trigger_minting.png";
import PayGas from "../assets/images/airdrop/pay_gas.png";
import NftMinted from "../assets/images/airdrop/nft_minted.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty } from "../actions/common";
import useContractProviderHook from "../actions/contractProviderHook";
import Web3 from "web3";
import { useDispatch, useSelector } from "react-redux";
import { GetTheClaimNFTS, BuyAcceptNext, GetUserINFO } from "../actions/axioss/nft.axios";
function Airdrop() {
  const [knowMore, setKnowMore] = useState(false);
  const [show, setShow] = useState(false);
  const { sellerFees, buyerFees } = useSelector(
    (state) => state.LoginReducer.ServiceFees
  );
  const { web3p, web3, accountAddress, coinBalance } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );

  const { payload } = useSelector((state) => state.LoginReducer.User);
  const push = useNavigate();
  const [location, SetLocation] = useState("");
  const [ApproveButton, SetApproveButton] = useState("start");
  const [UploadButton, SetUploadButton] = useState("stop");
  const [signature, setSignatureHash] = useState("");
  const [Randomname, setRandomName] = useState("");
  const [_nonce, setNonceHash] = useState("");
  const [sign, SetSignTxt] = useState();
  const [close, setCloseStatus] = useState();
  const [approve, setApproveCallStatus] = useState();
  const [MintButton, setMintButton] = useState("stop");
  const [NFT, SetNFT] = useState(null);
  const [BulkMint, SetBulkMint] = useState(null);
  const [NFTOwner, SetNFTOwner] = useState(null);
  const [App_Btn, SetApp_Btn] = useState("start");
  const [Error, SetError] = useState("");
  const ContractCall = useContractProviderHook();
  const handleKnowmore = () => {
    console.log("called");
    setKnowMore(true);
  };

  console.log("knowMore", knowMore);

  const handleClose = () => setShow(false);

  const handleModal = () => {
    console.log("NFT", NFT);
    if (NFT) {
      setShow(true);
    } else {
      toast.warning("No Drops To Claim");
    }
  };

  const TokenApproveCall = async () => {
    SetApproveButton("process");
    const id = toast.loading("Approve in process");
    console.log("enna panna", location, config.TradeContract);
    const cont = await ContractCall.SetApproveStatus(
      "single",
      config.Bulkmint,
      true
    );
    toast.update(id, {
      render: cont ? "Approved Successfully" : "Approved Failed",
      type: cont ? "success" : "error",
      isLoading: false,
      autoClose: 1000,
      closeButton: true,
      closeOnClick: true,
    });
    if (cont?.status) {
      SetApproveButton("done");
      SetUploadButton("start");
    } else SetApproveButton("try");
  };

  useEffect(() => {
    GetnftsToken();
  }, [accountAddress]);

  const GetnftsToken = async () => {
    var FindNFT = await GetTheClaimNFTS();
    console.log("FindNFT", FindNFT);
    if (FindNFT.success) {
      console.log("isEmpty(FindNFT.Tokens)", isEmpty(FindNFT.Tokens));
      if (!isEmpty(FindNFT.Tokens)) {
        console.log("isEmpty(FindNFT.Tokens)isEmpty(FindNFT.Tokens)");
        SetNFT(FindNFT.Tokens);
        SetNFTOwner(FindNFT.TokenOwner);
        SetBulkMint(FindNFT.BulkData);
      }
    }
  };

  const ClaimTheNFt = async () => {

    var UserInfo = await GetUserINFO({ Address: accountAddress })
    console.log("UserInfo", UserInfo);
    if (UserInfo.UserDetail.EmailId) {
      SetApp_Btn("process");

      if (accountAddress) {
        if (NFT !== null) {
          console.log(
            "new Date(BulkMint.auctionstart).getTime() < Date.now()",
            new Date(BulkMint.AuctionStart).getTime() < Date.now(),
            BulkMint.AuctionStart
          );
          if (new Date(BulkMint.AuctionStart).getTime() < Date.now()) {
            // console.log('ghgdhdg',NFTQugetServiceFeesantity)
            const id = toast.loading("Purchasing Token on processing");
            //if (isEmpty(error)) {
            console.log("buy1", NFT, typeof NFT.NFTRoyalty);
            let cont = await ContractCall.buy_Claim(
              config.IPFS + NFT.MetaData,
              web3p.utils.toWei(String(NFT.NFTRoyalty))
            );
            console.log("buy2", cont);

            if (cont) {
              let newOwner = {
                HashValue: cont.HashValue,
                NewTokenOwner: accountAddress,
                NFTQuantity: 1,
                NFTId: cont?.tokenCounts?.toString(),
                NFTOwner: NFTOwner.NFTOwner,
                PutOnSale: NFTOwner.PutOnSale,
                PutOnSaleType: NFTOwner.PutOnSaleType,
                activity: "Buy",
                TP: NFTOwner.NFTPrice,
                CN: NFTOwner.CoinName,
                click: `${config.FRONT_URL}/info/${NFTOwner.CollectionNetwork}/${NFTOwner.ContractAddress
                  }/${accountAddress}/${cont?.tokenCounts?.toString()}`,
              };
              let Resp = await BuyAcceptNext({ newOwner: newOwner, item: NFT });
              if (Resp.success == "success") {
                toast.update(id, {
                  render: "Token Claimed Successfully",
                  type: "success",
                  isLoading: false,
                  autoClose: 1000,
                  closeButton: true,
                  closeOnClick: true,
                });
                SetApp_Btn("done");
                push(`/profile/${payload?.CustomUrl}`, {
                  state: { Tab: "myitem" },
                });
              } else {
                toast.update(id, {
                  render: "Transaction Failed",
                  type: "error",
                  isLoading: false,
                  autoClose: 1000,
                  closeButton: true,
                  closeOnClick: true,
                });
                SetApp_Btn("try");
              }
            } else {
              toast.update(id, {
                render: "Transaction Failed",
                type: "error",
                isLoading: false,
                autoClose: 1000,
                closeButton: true,
                closeOnClick: true,
              });
              SetApp_Btn("try");
            }
          } else {
            toast.warning("You can't Claim now.Please Try it Later");
          }
        } else {
          toast.warning("There are no Drops present");
        }
      } else {
        toast.error("Connect Your Wallet");
      }
      // }
      // else {
      //   toast.update(id, { render: 'Validation failed', type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
      // }
    } else {
      toast.warning("Complete Your Profile to Claim Free Drops");
      setTimeout(() => {
        push(`/profile/${payload?.CustomUrl}`, {
          state: { Tab: "account" },
        });
      }, 1000);
    }
  };

  const signcall = async () => {
    const id = toast.loading("Signature Call", {
      closeButton: true,
      theme: "light",
    });
    var generator = require("generate-password");
    // console.log("qweqweqwewqeqweqwezxccvc", Wallet_Details, NFTDetails);
    if (web3) {
      // var web3Rpc = new Web3(config.RPC_URL)
      // console.log("qweqwewqewqewqewqqwvbv", web3Rpc);
      var randomNum = Date.now();
      console.log("sdcfsdfsdfsdvsdfdsf", randomNum);
      var password = "GolDenNFTISDinesh" + randomNum;
      //  generator.generate({
      //   length: 10,
      //   numbers: true
      // });
      console.log("asdasdasdas", password);
      if (web3) {
        SetSignTxt("On progress");
        setCloseStatus("On progress");
        setApproveCallStatus("process");
        // console.log("checking window.ethereum", web3.givenProvider,Wallet_Details.providerss)
        var web3RpcPro = web3.providers;
        console.log("asddfsfsdvbvbvcb", web3RpcPro, accountAddress);
        const to = accountAddress;
        // var Price = web3RpcPro.utils.toWei(String(NFTDetails.TokenPrice));
        const _amount = 0;
        // const _message = "Presale NFT";
        console.log("ajhghjas", _amount);
        const _nonce = Date.now();
        // setNonceHash(_nonce);
        console.log("qwewqewqreqwrqrq", password);

        setRandomName(password);
        var tot = _nonce + Number(randomNum);
        setNonceHash(tot);
        console.log("qwewqeqwewqeqweasdas", to, _amount, password, tot);
        const result = web3.utils.soliditySha3(to, _amount, password, tot);
        console.log("asdsadasfdafaf", result);
        const signhash = await web3.eth.personal.sign(result, to);
        console.log("Signature", result, signhash);
        if (signhash) {
          // SetSignTxt('done')
          toast.update(id, {
            render: "Approved Successfully",
            type: "success",
            isLoading: false,
            autoClose: 2500,
            closeButton: true,
            theme: "light",
          });
          setSignatureHash(signhash);
          // lazymint({ _nonce: tot, Randomname: password, signature: signhash })
        }
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="airdrop_page">
        <div className="banner_section">
          <div className="d-flex justify-content-center know_more_btn">
            <button
              type="button"
              className="tf-button style-2"
              onClick={() => handleKnowmore()}
            >
              Know more
              <IoIosArrowRoundDown className="pl-2 down_arrow" />
            </button>
          </div>
        </div>

        {knowMore ? (
          <>
            <div className="exclusive_airdrop text-center container">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-xl-6 col-lg-6">
                  {" "}
                  <div>
                    <h4 className="mb-0">Take Part in Our</h4>
                    <h5 className="mb-0">Exclusive Airdrop</h5>
                  </div>
                  <p className="mb-0 py-5">
                    Welcome to the Golden Idea NFT Airdrop, where creativity
                    meets rewards!  Experience the magic of lazy minting – a
                    seamless process that lets you claim your unique NFT by
                    triggering minting with just a click.
                  </p>
                </div>
              </div>
            </div>

            <div className="claim_your_nfts container custom_claim_your_nfts_container my-5">
              <h4 className="header_txt py-5">How To Claim Your NFTs?</h4>
              <div className="custom_bg_add">
                <div className="card_bg"></div>
                <div className="row row_sec_content">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-5 mb-sm-5 mb-md-4 mb-lg-4 mb-xl-4">
                    <div className="visit_golden_sec">
                      <div className="d-flex align-items-center justify-content-center">
                        <img
                          src={VisitGolden}
                          alrt="VisitGolden"
                          className="common_img img-fluid"
                        />
                      </div>
                      <div className="my-4">
                        <h5 className="mb-0 heading_txt">
                          {/* Visit Golden Idea Platform */}
                          Visit Golden Idea NFT Platform
                        </h5>
                        <p className="my-4 content">
                          Head to our platform to explore the exclusive NFTs
                          awaiting you.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-5 mb-sm-5 mb-md-4 mb-lg-4 mb-xl-4">
                    <div className="visit_golden_sec">
                      <div className="d-flex align-items-center justify-content-center">
                        <img
                          src={TriggerMinting}
                          alrt="TriggerMinting"
                          className="common_img img-fluid"
                        />
                      </div>
                      <div className="my-4">
                        <h5 className="mb-0 heading_txt">Trigger Minting</h5>
                        <p className="my-4 content">
                          Click the "Claim" button to initiate lazy minting.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-5 mb-sm-5 mb-md-4 mb-lg-4 mb-xl-4">
                    <div className="visit_golden_sec">
                      <div className="d-flex align-items-center justify-content-center">
                        <img
                          src={PayGas}
                          alrt="PayGas"
                          className="common_img img-fluid"
                        />
                      </div>
                      <div className="my-4">
                        <h5 className="mb-0 heading_txt">Pay Gas Fees</h5>
                        <p className="my-4 content">
                          Complete the process by paying the minimal gas fees –
                          your only investment for this exclusive NFT!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-5 mb-sm-5 mb-md-4 mb-lg-4 mb-xl-4">
                    <div className="visit_golden_sec nft_mint">
                      <div className="d-flex align-items-center justify-content-center">
                        <img
                          src={NftMinted}
                          alrt="NftMinted"
                          className="common_img nft_minted_cmn_bg img-fluid"
                        />
                      </div>
                      <div className="my-4">
                        <h5 className="mb-0 heading_txt">
                          NFT Minted on Blockchain
                        </h5>
                        <p className="my-4 content">
                          Voila! Your NFT is minted on the blockchain, ready to
                          be part of your digital collection.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="participate container custom_participate_container">
              <h4 className="mb-0 header_txt py-5">Why Participate?</h4>
              <div className="container inner_cus_container">
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 exclusive_nft get_free_nft">
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        src={FreeNFT}
                        alt="exclusive nft"
                        className="exclusive cmn_bg img-fluid free_nft"
                      />
                    </div>
                    <h5 className="heading mb-0">Get Free NFTs</h5>
                    <p className="content mb-0 py-3">
                      By Participating in our exclusive Airdrop <br />
                      you will receive a free NFT.
                    </p>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 exclusive_nft continuous_earning">
                    <div className="d-flex align-items-center justify-content-center">
                      <div>
                        <img
                          src={ContinuousEarning}
                          alt="exclusive nft"
                          className="exclusive cmn_bg img-fluid"
                        />
                      </div>
                    </div>
                    <h5 className="heading mb-0">Continuous Earnings</h5>
                    <p className="content mb-0 py-3">
                      Receive a free NFT with automatic royalties,
                      <br /> allowing you to continuously earn money from your
                      NFTs.
                    </p>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 exclusive_nft">
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        src={Exclusive}
                        alt="exclusive nft"
                        className="exclusive cmn_bg img-fluid"
                      />
                    </div>
                    <h5 className="heading mb-0">Exclusive NFTs</h5>
                    <p className="content mb-0 py-3">
                      Unlock unique digital assets created by talented artists.
                    </p>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 seamless seamless_before">
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        src={Seamless}
                        alt="Seamless"
                        className="Seamless cmn_bg img-fluid"
                      />
                    </div>
                    <h5 className="heading mb-0">Seamless Experience</h5>
                    <p className="content mb-0 py-3">
                      Lazy minting makes claiming your NFT hassle-free.
                    </p>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 minimal_gas">
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        src={Minimal}
                        alt="Minimal"
                        className="Minimal cmn_bg img-fluid"
                      />
                    </div>
                    <h5 className="heading mb-0">Minimal Gas Fees</h5>
                    <p className="content mb-0 py-3">
                      A small investment for a
                      <br />
                      valuable addition to your collection.
                    </p>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 community_building">
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        src={Community}
                        alt="Community"
                        className="Community cmn_bg img-fluid"
                      />
                    </div>
                    <h5 className="heading mb-0">Community Building</h5>
                    <p className="content mb-0 py-3">
                      Join a thriving community of NFT enthusiasts and creators.
                    </p>
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        src={CommunityAfter}
                        alt="Community"
                        className="Community_before img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container custom_claim_nft_container">
              <div className="claim_nft">
                <button
                  className=""
                  onClick={ClaimTheNFt}
                //onClick={handleModal}
                >
                  Claim your NFT now
                </button>
              </div>
            </div>

            <div className="join_conversation mt-md-0 mt-lg-5 mt-xl-5 py-5 text-center">
              <div className="container">
                <h3 className="mb-0 heading">Join the conversation</h3>
                <div className="content row d-flex aling-items-center justify-content-center">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col_datas">
                    {" "}
                    <p className="mb-5 mb-sm-5 mb-md-5 mb-lg-0 mb-xl-0 cnt_txt">
                      Connect with fellow participants, Share your NFTs and be
                      part of the Golden Idea NFT community
                    </p>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="icons_sec">
                        <a href="#" target="_blank">
                          <i class="icon-fl-vt"></i>
                        </a>
                        <a href="#" target="_blank">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                          <i class="fab fa-telegram-plane"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <Footer />

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
      >
        <Modal.Header>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          <Modal.Title id="contained-modal-title-vcenter">
            Follow Steps
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {ApproveButton != "stop" && ( */}
          <div className="ifpsmetadata mt-4">
            <div className="ifpsmetadatasec d-flex justify-content-start align-items-center">
              <i className="fa fa-check"></i>
              <div className="text-left ml-5">
                <h6>Sign Call</h6>
                <p>Signature Verification</p>
              </div>
            </div>
            <Button
              className="connectwallet  tf-button active btn btn-primary primary my-3"
              disabled={
                ApproveButton == "process" || ApproveButton == "done"
                  ? true
                  : false
              }
              onClick={
                ApproveButton == "start" || ApproveButton == "try"
                  ? signcall
                  : null
              }
              disableRipple
            >
              {ApproveButton == "start" && "Start"}
              {ApproveButton == "process" && "In-Progress"}
              {ApproveButton == "try" && "Try-Again"}
              {ApproveButton == "done" && "Done"}
            </Button>
          </div>
          {/* // )} */}

          <div className="ifpsmetadata mt-4">
            <div className="ifpsmetadatasec d-flex justify-content-start align-items-center">
              <i className="fa fa-check"></i>
              <div className="text-left ml-5">
                <h6>Mint NFT</h6>
                <p>Call contract method</p>
              </div>
            </div>
            <Button
              className="connectwallet tf-button active btn btn-primary primary my-3"
              // disabled={
              //   MintButton === "stop" ||
              //     MintButton == "process" ||
              //     MintButton == "done"
              //     ? true
              //     : false
              // }
              onClick={ClaimTheNFt}
            // disableRipple
            >
              {MintButton == "stop" && "Start"}
              {MintButton == "start" && "Start"}
              {MintButton == "process" && "In-Progress"}
              {MintButton == "try" && "Try-Again"}
              {MintButton == "done" && "Minted"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Airdrop;
