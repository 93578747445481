import { Route, Routes, Redirect } from "react-router-dom";
import Home from "./views/Home";
import Homepage from "./views/Homepage";
import Explore from "./views/Explore";
import FAQ from "./views/FAQ";
import Create from "./views/Create";
// import Collection from "./views/Collection";
import ConnectWallet from "./views/ConnectWallet";
import ContactUs from "./views/Contactus";
import Details from "./views/Details";
import Board from "./views/Board";
import PrivacyPolicy from "./views/PrivacyPolicy";
import MyItem from "./views/my_item";
// import CollectionList from "./views/CollectionList";
import Search from "./views/Search";

import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Activity from "./views/Activity";

import List from "./views/List";

import AboutUs from "./views/Aboutus";
import HelpandCentre from "./views/helpandcentre";
import { watchNetwork } from "@wagmi/core";
import { useEffect, useState } from "react";
import Config from "./views/config/config";
import { useSwitchNetwork } from "wagmi";
import { useSelector } from "react-redux";
import Airdrop from "./views/Airdrop";

function App() {
  // console.log = () => {};
  // console.info = () => {};
  // console.error = () => {};

  const { accountAddress, web3, web3p, coinBalance, type } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork();
  console.log("chainschainschains", chains, isLoading, pendingChainId);
  useEffect(() => {
    if (type == "CoinbaseMobile") {
      // pendingChainId

      const unwatch = watchNetwork((network) => {
        console.log(network?.chain?.id, "networknetwork", Config.CHAIN_ID);
        if (Config.CHAIN_ID != network?.chain?.id) {
          if (!isLoading) switchNetwork?.(Config.CHAIN_ID);
        }
      });
    }
  }, []);
  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/explore" element={<Explore />} />
          <Route path="/explore/:Category" element={<Explore />} />
          <Route path="/Activity" element={<Activity />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/create" element={<Create />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* <Route path='/collection/:CollectionSymbol' element={<Collection/>} /> */}
          <Route path="/connect" element={<ConnectWallet />} />
          <Route path="/search" element={<Search />} />
          {/* <Route path='/collectionlist' element={<CollectionList />} /> */}
          {/* <Route path='/details' element={<Details/>} /> */}
          <Route path="/myitem" element={<MyItem />} />
          <Route
            path="/info/:network/:Contract/:Owner/:Id"
            element={<Details />}
          />
          <Route path="/list/:address/:id" element={<List />} />
          <Route path="/details" element={<Details />} />
          <Route path="/my-item/:customurl" element={<MyItem />} />
          <Route path="/profile/:customurl" element={<Board />} />
          <Route path="/search/:key" element={<Search />} />

          <Route path="/" element={<Home />} />
          {/* <Route path='/home' element={<Homepage/>} /> */}
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/helpandcentre" element={<HelpandCentre />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/aboutus" element={<AboutUs />} />

          <Route path="/airdrop" element={<Airdrop />} />
        </Routes>
      </BrowserRouter>

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        rtl={true}
        closeOnClick={true}
        closeButton={true}
        style={{
          zIndex: "999999",
          width: "400px",
          wordBreak: "break-word",
          "text-align": "center",
          fontWeight: "500",
          marginTop: "70px",
        }}
      />
    </>
  );
}

export default App;
