import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {store} from './redux/store'
import { Provider } from 'react-redux';
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from '@web3-react/core'



import { WagmiConfig, createConfig, configureChains } from 'wagmi'
// import web3 from "web3";
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { createClient } from "wagmi";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { data } from "jquery";
import { mainnet, optimism, polygon,avalanche ,avalancheFuji} from '@wagmi/core/chains'

const { chains, publicClient, webSocketPublicClient } = configureChains(
    [mainnet, optimism, polygon,avalanche,avalancheFuji],
    [alchemyProvider({ apiKey: '5mQV1GTqVFiP7JOdlI1dnhmCV5rmuMzw' }), publicProvider()],
)


 
// Set up wagmi config
const config = createConfig({
    autoConnect: true,
    connectors: [
        new CoinbaseWalletConnector({
            chains:[mainnet, optimism, polygon,avalanche],
            options: {
                appName: 'Goldenidea',
            },
        }),
    ],
    publicClient,
    webSocketPublicClient,
})


function getLibrary(provider) {
  console.log("providerprovider");
  return new Web3Provider(provider);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store} >
      <WagmiConfig config={config}>
        <Web3ReactProvider getLibrary={(getLibrary)}>
          <App/>
        </Web3ReactProvider>
        </WagmiConfig>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
